import { Platform } from "react-native"
import constant from "../../constant"
import MyStorage from "../../services/storage"
import { decryptAES } from "../../utils"

export default class LevelModel {
    constructor(
        id = '',
        vimeoVideoUri = '',
        sequence = '',
        title = '',
        tierId = '',
        thumbImageURL = '',
        experienceVideoUrl = '',
        videoUrl = "",
        description = ""
    ) {
        this.id = id
        this.vimeoVideoUri = vimeoVideoUri
        this.sequence = sequence
        this.title = title
        this.tierId = tierId
        this.thumbImageURL = thumbImageURL
        this.experienceVideoUrl = experienceVideoUrl
        this.videoUrl = videoUrl
        this.description = description

    }

    initWithRes(res) {
        this.id = res['id']
        this.vimeoVideoUri = res['vimeo_video_uri'] || ""
        this.title = res['title'] || ""
        this.tierId = res['tier_id'] || ""
        this.thumbImageURL = res['experience_video_thumb_url'] || ""
        this.experienceVideoUrl = res['experience_video_url'] || ""
        this.description = res['description'] || ""
        this.sequence = res['sequence'] || ""

        if (Platform.OS == 'web') {
            this.videoUrl = res['encrypted_url'] || ""
        } else {
            let encryptedBase64 = res['encrypted_url'] || ""
            let decryptedURL = decryptAES(encryptedBase64, MyStorage.getItem(constant.appKey.kDecodeKey))
            this.videoUrl = decryptedURL
        }

        return this
    }
}