import { Image } from "@rneui/themed";
import { View, Modal, ActivityIndicator, StyleSheet, ImageBackground, Text } from "react-native";
import AppStrings from "../../constant/appStrings";
import AppColor from "../../constant/colors";
import AppFonts from "../../constant/fonts";
import { _scale } from "../../utils";
import { localImages } from "../../assets/images/index";
import { useEffect, useState } from "react";
import { Asset } from 'expo-asset';

export default function AppLoader(props) {

    const [isGifLoaded, setGifLoaded] = useState(false)

    useEffect(() => {
        let image = require('../../assets/images/thewaygif.gif')
        Asset.fromModule(image).downloadAsync().then((status) => {
            console.log('status', status);
            setGifLoaded(true)
        })

    }, [])


    return (
        <View>
            <Modal
                animationType='fade'
                visible={true}
                transparent={true}>
                <ImageBackground blurRadius={90} style={styles.modalView}>
                    <View style={styles.gifContainer}>
                        {/* <ActivityIndicator size={'large'} color={'red'} /> */}
                        <Image style={{ width: 50, height: 50 }} source={isGifLoaded ? require('../../assets/images/thewaygif.gif') : require('../../assets/images/appicon.png')} />
                    </View>
                    {props.paymentMessage &&
                        <View style={styles.paymentContainer} key={'PaymentContainer'}>
                            <Text style={styles.paymentMessage}>{AppStrings.paymentMessage}</Text>
                            <Text style={styles.paymentMessage}>{AppStrings.refreshMessage}</Text>
                        </View>
                    }
                </ImageBackground>
            </Modal>
        </View>
    )
}

const styles = StyleSheet.create({
    modalView: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        padding: _scale(35),
    },
    gifContainer: {
        height: _scale(65),
        width: _scale(65),
        borderRadius: _scale(8),
        backgroundColor: 'black',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paymentContainer: {
        backgroundColor: AppColor.white,
        borderRadius: _scale(10),
        padding: _scale(10),
        marginTop: _scale(10)
    },
    paymentMessage: {
        fontSize: _scale(18),
        fontFamily: AppFonts.robotoBold,
        color: AppColor.black,
        textAlign: 'center',
        marginTop: _scale(5),
        lineHeight: 20,
        letterSpacing: 0.5
    }
})