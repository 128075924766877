import { StyleSheet, Dimensions } from "react-native"
import AppColor from "../../../constant/colors"
import AppFonts from "../../../constant/fonts"
import { _scale } from "../../../utils"

export default StyleSheet.create({
    crossIconStyle: {
        paddingVertical: _scale(15),
        position: 'absolute',
        paddingHorizontal: _scale(10),

    },
    logoStyle: {
        height: _scale(120),
        width: _scale(120),
        alignSelf: 'center',
        marginTop: _scale(15)
    },
    filedContainerStyle: {
        marginTop: _scale(50),
        marginHorizontal: _scale(80)
    },
    buttonStyle: {
        borderRadius: _scale(25),
        marginTop: _scale(10),
        padding: _scale(15),
        backgroundColor: AppColor.lightRed,
    },
    checkBoxContainerStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: -15
    },
    checkBoxStyle: {
        backgroundColor: 'none',
        marginLeft: -_scale(7)
    },
    signInTextStyle: {
        fontFamily: AppFonts.robotoRegular,
        color: AppColor.lightRed,
        fontSize:_scale(14)
    },
    footerContainerStyle: {
        flexDirection: 'row',
        alignSelf: 'center',
        marginTop: 30,
    },
    hintTextStyle: {
        fontFamily: AppFonts.robotoRegular,
        color: AppColor.white,
        marginLeft: -_scale(10),
        fontSize:_scale(14)
    }
})