import { View, Text, Image, useWindowDimensions, ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import { useEffect, useState } from 'react'
import AppColor from "../../../constant/colors";
import AppFonts from "../../../constant/fonts";
import { convertStringToObject, gradientBackground, _scale } from "../../../utils";
import { commonStyles } from "../../../utils/commonStyles";
import AppLoader from "../../../components/appLoader/index.web";
import { FontAwesome } from '@expo/vector-icons'
import PagingDots from "../../../components/pagingDots";
import constant from "../../../constant";
import WebHeader from "../../../components/WebHeader.js";
import MyStorage from "../../../services/storage";
import AppStrings from '../../../constant/appStrings';
import { Alert } from "react-native-web";
import { onGetSubscriptionUrl } from "../../../controllers/PaymentUrlController";


export default function VideoDetailsView({ navigation, route }) {
    const [isLoading, setIsLoading] = useState(false)
    const { styles } = useStyle();

    const [videoInfo, setVideoInfo] = useState(route.params.videoInfo) // item
    const [extraInfo, setExtraInfo] = useState(route.params.infoData)

    useEffect(() => {

        if (route.params.videoInfo != undefined) {
            setVideoInfo(convertStringToObject(route.params.videoInfo))
        }

        if (route.params.infoData != undefined) {
            setExtraInfo(convertStringToObject(route.params.infoData))
        }
    }, [])

    if (videoInfo.title == undefined) {
        let url = window.location.pathname
        let path = url.split('/');
        path.pop();
        path = path.join('/');
        window.location.href = window.location.origin + path
    }

    const updateUserModel = (userModel) => {
        let temp = extraInfo
        temp.userModel = userModel
        setExtraInfo(temp)
    }

    const showingWarningAlertForPlayVideo = (message) => {
        if (confirm(message) == true) {
            onLogout().then(() => {

            }).catch((error) => {

            })
        }
    }

    const onClickSubscribe = () => {
        onGetSubscriptionUrl().then((response) => {
            setIsLoading(false)
            //console.log("-----url success", response.json.data[0].url);
            window.location.href = response.json.data[0].url
        }).catch((error) => {
        })
    }

    const onOk = () => {
        setIsLoading(true)
        onClickSubscribe()
    }

    const showingWarningAlertSubscribePlayVideo = (message) => {
        if (confirm(message) == true) {
            onOk().then(() => {
            }).catch((error) => {

            })
        }
    }

    const onClickVideo = () => {
        if (!isVideoPlayable()) {
            if (MyStorage.getItem(constant.paymentType.subscription) == '0')
                showingWarningAlertSubscribePlayVideo(AppStrings.videoPlaySubscribeMessageWeb)
            else
                showingWarningAlertForPlayVideo(AppStrings.videoPlayMessage)

            return
        }
        if (extraInfo != undefined) {
            let index = extraInfo.levelModel.findIndex((x) => x.id == videoInfo.id)
            let currentLevel = extraInfo.userModel.currentLevel - 1
            if (index > -1) {
                if (route.params.isCurrentSequence == extraInfo.userModel.currentTier && index == currentLevel) {

                    let currentAttunement = extraInfo.userModel.currentAttunement
                    if (currentAttunement < extraInfo.tierVideoModel.length) {
                        let videoModel = extraInfo.tierVideoModel[currentAttunement]
                        navigation.navigate('VideoPlayer', { q: videoModel.videoUrl, infoData: extraInfo, currentIndex: index, currentPlayingVideoInfo: videoModel, updateUserModel: updateUserModel, title: videoModel.title, isCurrentSequence: route.params.isCurrentSequence })
                    } else {
                        let expVideoUrl = extraInfo.levelModel[index].videoUrl
                        navigation.navigate('VideoPlayer', { q: expVideoUrl, infoData: extraInfo, currentIndex: index, updateUserModel: updateUserModel, title: extraInfo.levelModel[index].title, isCurrentSequence: route.params.isCurrentSequence })
                    }
                } else {
                    let videoModel = extraInfo.tierVideoModel[0]
                    navigation.navigate('VideoPlayer', { q: videoModel.videoUrl, infoData: extraInfo, currentIndex: index, currentPlayingVideoInfo: videoModel, updateUserModel: updateUserModel, title: videoModel.title, isCurrentSequence: route.params.isCurrentSequence })
                }
            }
        } else {
            // on Play Video
            navigation.navigate('VideoPlayer', { q: videoInfo.videoUrl, title: videoInfo.title })
        }

    }


    const getSelectedCount = () => {

        // if (!route.params.isVideoPlayable) {
        //     return 0
        // }

        let index = extraInfo.levelModel.indexOf(videoInfo)
        let currentLevel = extraInfo.userModel.currentLevel


        let currentAttunement = 0
        if (route.params.isCurrentSequence == extraInfo.userModel.currentTier) {
            if (index == (currentLevel - 1)) {
                currentAttunement = extraInfo.userModel.currentExperienceDay
            } else {
                if (index < currentLevel) {
                    currentAttunement = 7
                } else {
                    currentAttunement = 0
                }
            }
        } else {
            if (route.params.isCurrentSequence < extraInfo.userModel.currentTier) {
                currentAttunement = 7
            } else {
                currentAttunement = 0
            }
        }

        return currentAttunement

    }

    const isVideoPlayable = () => {

        console.log(route.params.isVideoPlayable);
        if (route.params.isVideoPlayable == undefined) {
            return true
        }
        return (route.params.isVideoPlayable == true)
    }

    return (
        <View style={commonStyles.mainContainer} key={'MainContainer'}>
            {gradientBackground()}
            <WebHeader navigation={navigation} titles={['Details']} />

            <View style={commonStyles.webSubContainerStyle} key={'SubContainer'}>
                <View style={[commonStyles.webBorderViewStyle, { width: '70%', maxWidth: _scale(830) }]} key={'BorderView'}>
                    <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.scrollViewStyle}>
                        <View style={styles.itemContainerSTyle} key={'ItemContainer'}>

                            <View style={styles.imageContainerSTyle}>
                                <Image style={styles.imageSTyle} source={{ uri: videoInfo.thumbImageURL }} />
                                {/* <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, justifyContent: 'center', alignItems: 'center', }}>
                                    <FontAwesome onPress={onClickVideo} name="play-circle" size={55} color="white" />
                                </View> */}
                                {route.params.isComingFromPage == constant.ScreensName.theWay &&
                                    <View style={{ position: 'absolute', top: 10, right: 10, padding: 10 }}>
                                        <PagingDots selectedCount={getSelectedCount()} numberOfDots={7} dotStyle={{ width: _scale(10), height: _scale(10), }} />
                                    </View>
                                }

                            </View>

                            <View style={styles.itemRightContainerStyle} key={'ItemRightContainerStyle'}>
                                <Text style={styles.titleStyle} key={'title'}>{videoInfo.title}</Text>
                                <Text style={styles.descriptionStyle} key={'Description'}>{videoInfo.description}</Text>

                                <TouchableOpacity onPress={onClickVideo} style={{ backgroundColor: AppColor.white, padding: _scale(10), marginVertical: _scale(8), borderRadius: _scale(20), justifyContent: 'center', alignItems: 'center' }}>
                                    <Text style={{ fontFamily: AppFonts.robotoBold, fontSize: _scale(13) }}> <FontAwesome name={isVideoPlayable() ? "play" : "lock"} size={15} color="black" />  Play</Text>
                                </TouchableOpacity>
                                <View style={styles.footerStyle} key={'Footer'} />
                            </View>
                        </View>
                    </ScrollView>
                </View>
            </View>
            {isLoading && <AppLoader paymentMessage={true} />}
        </View>
    )
}

const useStyle = () => {
    var isSmallDevice = false
    const { height, width } = useWindowDimensions();
    width <= 950 ? isSmallDevice = true : isSmallDevice = true

    const styles = StyleSheet.create({
        scrollViewStyle: {
            flexGrow: 1,
            flexDirection: 'column',
        },
        itemContainerSTyle: {
            flex: 1,
            flexDirection: isSmallDevice ? 'column' : 'row'
        },
        itemRightContainerStyle: {
            padding: _scale(20),
            flex: 1,
            // backgroundColor: AppColor.lightRed
        },
        imageContainerSTyle: {
            flex: 1,
            width: '100%',
            borderBottomLeftRadius: isSmallDevice ? _scale(0) : _scale(20),
            borderTopRightRadius: isSmallDevice ? _scale(20) : _scale(0),
            borderTopLeftRadius: _scale(20),
            backgroundColor: isSmallDevice ? '' : 'black'
        },
        imageSTyle: {
            resizeMode: isSmallDevice ? 'cover' : 'center',
            borderBottomLeftRadius: isSmallDevice ? _scale(0) : _scale(20),
            borderTopRightRadius: isSmallDevice ? _scale(20) : _scale(0),
            borderTopLeftRadius: _scale(20),
            backgroundColor: isSmallDevice ? '' : 'black',
            flex: 1
        },
        titleStyle: {
            color: AppColor.white,
            fontSize: _scale(20),
            fontFamily: AppFonts.robotoBold
        },
        descriptionStyle: {
            color: AppColor.white,
            fontSize: _scale(15),
            fontFamily: AppFonts.robotoRegular,
            marginBottom: _scale(10),
            lineHeight: _scale(25),
            letterSpacing: 1,
            marginTop: _scale(30),
            flex: 1
        },
        buttonStyle: {
            borderRadius: _scale(25),
            padding: _scale(15),
        },
        footerStyle: {
            height: isSmallDevice ? _scale(15) : _scale(0)
        }
    })
    return { styles }
}

