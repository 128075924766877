import { Platform } from "react-native"
import constant from "../../constant"
import MyStorage from "../../services/storage"
import { decryptAES } from "../../utils"

export default class TrophyVideos {
    constructor(
        tierId = '',
        tierName = '',
        title = '',
        vimeoVideoUri = '',
        videoUrl = '',
        thumbImageURL = '',
        description = '',
        type = '',
        encrypted_url = ''

    ) {

        this.tierId = tierId,
            this.tierName = tierName,
            this.title = title,
            this.vimeoVideoUri = vimeoVideoUri,
            this.videoUrl = videoUrl,
            this.thumbImageURL = thumbImageURL,
            this.description = description,
            this.type = type,
            this.encrypted_url = encrypted_url
    }

    initWithRes(res, type = "") {
        this.tierId = res['tier_id'] || ""
        this.tierName = res['tier_name'] || ""
        this.title = res['trophy_name'] || ""
        this.vimeoVideoUri = res['video_src'] || ""
        this.videoUrl = res['video_src'] || ""
        this.thumbImageURL = res['video_thumbnail'] || ""
        this.description = ""
        this.type = "trophy"
        this.encrypted_url = res['encrypted_url'] || ""

        return this
    }
}