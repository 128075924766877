import { Image } from "@rneui/themed";
import { useState } from "react";
import { View, Modal, ActivityIndicator, StyleSheet, ImageBackground, Text, Dimensions } from "react-native";
import ReactPlayer from 'react-player'
import { localImages } from "../../assets/images";

export default function WebSplash(props) {

    const [isLoader, setLoader] = useState(true)

    return (
        <View>
            <Modal
                animationType='fade'
                visible={true}
                transparent={true}
            >
                {isLoader &&
                    <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, justifyContent: 'center', alignItems: 'center' }}>
                        <Image
                            style={{ width: 100, height: 100 }}
                            source={require('../../../assets/appicon.png')}
                        />
                    </View>
                }

                <ReactPlayer
                    url={'https://player.vimeo.com/progressive_redirect/playback/766116478/rendition/540p/file.mp4?loc=external&signature=9b8afd916c67088c25c5c094fec53e63aed46b7c1ad2d2c44e5802aaf148dca8'}
                    playing={true}
                    width={Dimensions.get('window').width}
                    height={Dimensions.get('window').height}
                    style={{ backgroundColor: 'black' }}
                    muted={true}
                    onReady={() => {
                        setLoader(false)
                    }}
                    onEnded={() => {
                        props.onHideSplash()
                    }}
                    onError={(error) => {
                        // console.log(error);
                        props.onHideSplash()
                    }}
                />


            </Modal>
        </View>
    )
}

const styles = StyleSheet.create({

})