import PaymentUrlRequest from '../../models/PaymentUrlRequestModel';
import ApiManager from '../../services/apiManager';
import Constant from '../../constant';
import constant from '../../constant';
import { Platform } from 'react-native';
var apiManager = ApiManager.getInstance();
const paymentEndPoint = Constant.apiEndPoints.paymentUrl;
const verifyPayment = Platform.OS == 'ios' ? Constant.apiEndPoints.verifyPayment : Constant.apiEndPoints.verifyAndroidPayment;
const saveExpansionVideo = Platform.OS == 'ios' ? Constant.apiEndPoints.saveVideoPayment : Constant.apiEndPoints.saveVideoPaymentForAndroid;



export const onGetPaymentUrl = async (paymentUrlParam: PaymentUrlRequest) => new Promise((resolve, reject) => {

    let requestParameter = {
        'price_id': paymentUrlParam.priceId,
        'video_id': paymentUrlParam.videoId,
        'type': paymentUrlParam.type
    }

    apiManager.onPostApi(paymentEndPoint, requestParameter).then((responseData) => {
        if (responseData.json.status == Constant.successCode) {
            // console.log('----payment success Response : ', responseData.json.data)
            resolve(responseData)
        } else {
            reject(responseData)
        }
    }).catch((error) => {
        // console.log("payment Error:", error);
        reject(error)
    })
});

export const onGetSubscriptionUrl = async () => new Promise((resolve, reject) => {

    let requestParameter = {
        'type': Constant.paymentType.subscription
    }
    apiManager.onPostApi(paymentEndPoint, requestParameter).then((responseData) => {
        if (responseData.json.status == Constant.successCode) {
            // console.log('----payment success Response : ', responseData.json.data)
            resolve(responseData)
        } else {
            reject(responseData)
        }
    }).catch((error) => {
        // console.log("payment Error:", error);
        reject(error)
    })
});


export const onInAppPurchasePayment = async (purchase) => new Promise((resolve, reject) => {

    let requestParameter = {}
    if (Platform.OS == 'android') {
        requestParameter = {
            'purchaseToken': purchase.purchaseToken,
            'productId': constant.subscriptionProductAndroid.monthly,
            'transaction_id': purchase.transactionId,
            'amount': constant.subscriptionAmount.monthly,
        }
    } else {
        requestParameter = {
            'receiptData': purchase.transactionReceipt,
            'originalTransactionId': purchase.originalTransactionIdentifierIOS || purchase.transactionId,
            'amount': constant.subscriptionAmount.monthly,
            'transactionDate': purchase.transactionDate,
            'productId': constant.subscriptionProductIos.monthly,

        }
    }

    console.log(requestParameter);
    apiManager.onPostApi(verifyPayment, requestParameter).then((responseData) => {
        if (responseData.json.status == Constant.successCode) {
            console.log('----payment success Response : ', responseData.json.data)
            resolve(responseData)
        } else {
            reject(responseData)
        }
    }).catch((error) => {
        console.log("payment Error:", error);
        reject(error)
    })
});


export const onInAppExpansionPayment = async (purchase, videoID, productId, amount) => new Promise((resolve, reject) => {

    let requestParameter = ""

    if (Platform.OS == 'android') {
        requestParameter = {
            'receiptData': purchase.purchaseToken,
            'originalTransactionId': purchase.transactionId,
            'amount': amount,
            'video_id': videoID,
            'productId': productId
        }
    } else {
        requestParameter = {
            'receiptData': purchase.transactionReceipt,
            'originalTransactionId': purchase.originalTransactionIdentifierIOS || purchase.transactionId,
            'amount': amount,
            'transactionDate': purchase.transactionDate,
            'video_id': videoID,
            'productId': productId
        }
    }

    console.log(requestParameter);
    console.log("saveExpansionVideo", saveExpansionVideo)
    resolve(requestParameter)

    apiManager.onPostApi(saveExpansionVideo, requestParameter).then((responseData) => {
        if (responseData.json.status == Constant.successCode) {
            console.log('----payment success Response : ', responseData.json.data)
            resolve(responseData)
        } else {
            reject(responseData)
        }
    }).catch((error) => {
        console.log("payment Error:", error);
        reject(error)
    })
});