import AppConstant from "../../constant"

export default class TiersModel {
    constructor(
        id = '',
        sequence = '',
        thumbImageURL = '',
        title = '',
        description = "",
        experienceCount = ""
    ) {
        this.id = id,
            this.sequence = sequence,
            this.thumbImageURL = thumbImageURL,
            this.title = title,
            this.description = description,
            this.experienceCount = experienceCount
    }

    initWithData(res) {
        this.id = res["id"] || ""
        this.sequence = res["sequence"] || ""
        this.thumbImageURL = res["thumb_image_url"] || ""
        this.title = res["title"] || ""
        this.description = res["description"] || "",
            this.experienceCount = res["experience_count"] || ""
        return this
    }
}
