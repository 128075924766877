import { View, Text, FlatList } from 'react-native'
import { useEffect, useState } from 'react'
import AppHeader from '../../../components/appHeader'
import { bottomSafeArea, gradientBackground, topSafeArea, _scale } from '../../../utils'
import { commonStyles } from '../../../utils/commonStyles'
import { getLevel } from '../../../controllers/LevelController'
import VideoPreview from '../../../components/VideoPreview/index.web'
import Constant from '../../../constant'
import ProgressModel from '../../../models/ProgressModel'
import AppLoader from '../../../components/appLoader/index.web'
import WebHeader from '../../../components/WebHeader.js'
import MyStorage from '../../../services/storage'
import AppFonts from '../../../constant/fonts'


export default function WebTierDetails({ navigation, route }) {
    // console.log("route", route.params);
    const [levelData, setLevelData] = useState([])
    const [extraInfo, setExtraInfo] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        if (MyStorage.getItem(Constant.appKey.kAuthToken) == '') {
            window.location.href = window.location.origin
            return;
        }
        setIsLoading(true)
        navigation.addListener('focus', () => {
            callGetLevelApi()
        });
    }, [])

    const callGetLevelApi = () => {
        getLevel(route.params.tierId).then((levelModel) => {
            // console.log("level data is", levelModel);

            if (route.params.isComingFromPage != undefined && route.params.isComingFromPage == Constant.ScreensName.journey) {
                let temp = levelModel
                let userModel = levelModel.userModel
                temp.levelModel.splice(userModel.currentLevel)
                let extraInfo = new ProgressModel(temp.userModel, temp.levelModel, temp.tierVideoModel)
                setExtraInfo(extraInfo)
                setLevelData(temp)
            } else {
                let extraInfo = new ProgressModel(levelModel.userModel, levelModel.levelModel, levelModel.tierVideoModel)
                setExtraInfo(extraInfo)
                setLevelData(levelModel)
            }
            setIsLoading(false)

        }).catch((error) => {
            setIsLoading(false)
        })
    }

    console.log(levelData);
    return (
        <View style={commonStyles.mainContainer}>
            {gradientBackground()}
            <WebHeader navigation={navigation} titles={["Levels"]} />

            {levelData.levelModel != undefined && levelData.levelModel.length == 0 && !isLoading &&
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: 15, color: 'white', fontFamily: AppFonts.robototMedium }}>Coming Soon</Text>
                </View>
            }

            <VideoPreview extraInfo={extraInfo} navigation={navigation} userModel={levelData.userModel} videoData={levelData.levelModel} screen={Constant.ScreensName.level} parentScreen={route.params.isComingFromPage} sequence={route.params.sequence} />
            {isLoading && <AppLoader />}
        </View>
    )
}