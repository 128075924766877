import { scale, verticalScale } from 'react-native-size-matters'
import { Platform, View } from 'react-native'
import { showFlashBanner } from '../services/flashMessage'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import storage from '.././services/storage'
import constant from '../constant'
import { LinearGradient } from 'expo-linear-gradient'
import { commonStyles } from './commonStyles'
import CryptoJS from "react-native-crypto-js";


export const _scale = (number) => {
    return Platform.OS === 'ios' ? scale(number) : number
}

export const _verticalScale = (number) => {
    return Platform.OS === 'ios' ? verticalScale(number) : number
}

export const showErrorBanner = (textMessage, screenName) => {
    showFlashBanner(textMessage, '1', screenName)
}

export const showSuccessBanner = (textMessage) => {
    showFlashBanner(textMessage, '0')
}

export const topSafeArea = () => {
    return <View style={{ height: useSafeAreaInsets().top }} />
}

export const bottomSafeArea = () => {
    return <View style={{ height: useSafeAreaInsets().bottom }} />
}

export const gradientBackground = () => {
    return <LinearGradient colors={['#283C54', '#000000']} style={commonStyles.gradientBackGround} />
}

export const setLoginData = async (userDetails) => {
    // console.log('Set login Item--', userDetails);
    storage.setItem(constant.appKey.kAuthToken, userDetails.bearer_token)
    storage.setItem(constant.appKey.kUserId, userDetails.user.id)
    storage.setItem(constant.appKey.kUserName, userDetails.user.name)
    storage.setItem(constant.appKey.kUserEmail, userDetails.user.email)
    storage.setItem(constant.appKey.kSubscription, userDetails.user.subscription)

    try {
        await AsyncStorage.setItem(constant.appKey.loginData, JSON.stringify(userDetails));
    } catch (error) {
    }
}

export const getLoginData = async () => {
    try {
        const data = await AsyncStorage.getItem(constant.appKey.loginData);
        if (data !== null) {
            const userDataJson = JSON.parse(data);
            // console.log('userData ' + userDataJson)
            return userDataJson;
        }
    } catch (error) {
        //alert(err)
    }
}

export const findIndexOfRings = () => {

    function generateRandomNumber(numsArray) {
        return numsArray[Math.floor(Math.random() * numsArray.length)];
    }

    let indexes = [0, 1, 2, 3, 4, 5, 6, 7, 8];
    let indexOfShowingRings = []
    while (indexOfShowingRings.length < 3) {
        let rendomNum = generateRandomNumber(indexes)
        if (!indexOfShowingRings.includes(rendomNum)) {
            indexOfShowingRings.push(rendomNum)
            indexes.splice((rendomNum - 1), 1)
            const removeItems = [rendomNum - 1, rendomNum, rendomNum + 1]
            indexes = indexes.filter(v => !removeItems.includes(v))
        }
    }
    return indexOfShowingRings;
}

export const findIndexOfRingsWeb = () => {

    function generateRandomNumber(numsArray) {
        return numsArray[Math.floor(Math.random() * numsArray.length)];
    }

    let indexes = [0, 1, 2, 3, 4, 5, 6, 7, 8];
    let indexOfShowingRings = []
    while (indexOfShowingRings.length < 3) {
        let randomNum = generateRandomNumber(indexes)
        if (!indexOfShowingRings.includes(randomNum) &&
            !indexOfShowingRings.includes(randomNum - 1) &&
            !indexOfShowingRings.includes(randomNum + 1)) {
            indexOfShowingRings.push(randomNum)
            indexes.splice(indexes.indexOf(randomNum), 1);
        }
    }
    return indexOfShowingRings;
}


export const decryptAES = (payload, key) => {

    let encryptStr = CryptoJS.enc.Base64.parse(payload);
    let encryptData = encryptStr.toString(CryptoJS.enc.Utf8);
    encryptData = JSON.parse(encryptData);
    let iv = CryptoJS.enc.Base64.parse(encryptData.iv);
    var decrypted = CryptoJS.AES.decrypt(encryptData.value, CryptoJS.enc.Base64.parse(key), {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
    return decrypted
}

export const convertObjectToString = (payload) => {

    return payload
    var cipherText = CryptoJS.AES.encrypt(JSON.stringify(payload), constant.appKey.paramKey).toString();

    console.log('cipherText'), cipherText;

    let test = CryptoJS.enc.Base64.stringify(JSON.stringify(payload))
    console.log('test', test);
    return cipherText
}

export const convertStringToObject = (payload) => {
    return payload
    var bytes = CryptoJS.AES.decrypt(payload, constant.appKey.paramKey);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log(decryptedData);
    return decryptedData
}



