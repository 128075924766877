import { Platform } from "react-native"
import constant from "../../constant"
import MyStorage from "../../services/storage"
import { decryptAES } from "../../utils"

export default class TierVideoModel {
    constructor(
        id = '',
        vimeoVideoUri = '',
        tierId = '',
        title = '',
        description = '',
        thumbImageURL = '',
        videoUrl = '',
        videoType = '',
        createdAt = '',
        updatedAt = '',
        orderInTier = '',
        type = ''
    ) {
        this.id = id,
            this.vimeoVideoUri = vimeoVideoUri,
            this.tierId = tierId,
            this.title = title,
            this.description = description,
            this.thumbImageURL = thumbImageURL,
            this.videoUrl = videoUrl,
            this.videoType = videoType,
            this.createdAt = createdAt,
            this.updatedAt = updatedAt,
            this.orderInTier = orderInTier,
            this.type = type
    }

    initWithRes(res, type = "") {
        this.id = res['id']
        this.vimeoVideoUri = res['vimeo_video_uri'] || ""
        this.tierId = res['tier_id'] || ""
        this.title = res['title'] || ""
        this.description = res['description'] || ""
        this.thumbImageURL = res['preview_url'] || ""

        if (Platform.OS == 'web') {
            this.videoUrl = res['encrypted_url'] || ""
        } else {
            let encryptedBase64 = res['encrypted_url'] || ""
            let decryptedURL = decryptAES(encryptedBase64, MyStorage.getItem(constant.appKey.kDecodeKey))
            this.videoUrl = decryptedURL
        }
        this.videoType = res['video_type'] || ""
        this.createdAt = res['created_at'] || ""
        this.updatedAt = res['updated_at'] || ""
        this.orderInTier = res['order_in_tier'] || ""
        this.type = type
        return this
    }
}