import { TouchableOpacity, Text } from 'react-native'
import DefaultValues from '../../constant/defaultValues'
import { commonStyles } from '../../utils/commonStyles'
import SpinnerButton from 'react-native-spinner-button'

export default function AppButton(props) {
    return (
        <SpinnerButton
            buttonStyle={[commonStyles.buttonStyle, { ...props.buttonStyle }]}
            onPress={props.onPress}
            spinnerType='MaterialIndicator'
            isLoading={props.isLoading}
            disabled={props.isDisable}
        >

            <Text style={[commonStyles.buttonTitleStyle, { ...props.buttonTileStyle }]}>{props.title}</Text>

        </SpinnerButton>
    )
}

AppButton.defaultProps = {
    title: DefaultValues.buttonValue.title,
    isLoading: DefaultValues.buttonValue.isLoading,
    isDisable: false
}


