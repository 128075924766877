import Constant from '../../constant';
import ApiManager from "../../services/apiManager";
import GetProfileModel from '../../models/GetProfileModel';
let getProfileEndPoint = Constant.apiEndPoints.getProfile
let cancelSubscriptionEndPoint = Constant.apiEndPoints.cancelSub

var apiManager;

export const getProfile = async (navigation) => new Promise((resolve, reject) => {
    apiManager = ApiManager.getInstance(navigation)
    apiManager.onGetApi(getProfileEndPoint).then((responseData) => {

        console.log("responseData>>", responseData);
        let modelObj = new GetProfileModel().initWithRes(responseData.json["data"])
        resolve(modelObj)
    }).catch((error) => {
        reject(error)
    })
})

export const cancelSubscriptionAPI = async () => new Promise((resolve, reject) => {

    apiManager.onPostApi(cancelSubscriptionEndPoint).then((responseData) => {
        resolve(responseData)
    }).catch((error) => {
        reject(error)
    })
})