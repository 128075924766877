import { SafeAreaView, StyleSheet, View, FlatList, Image, useWindowDimensions, Text } from 'react-native';
import React, { useState, useEffect } from 'react';
import VideoPreview from '../../../components/VideoPreview/index.web';
import { convertObjectToString, gradientBackground } from '../../../utils';
import { getTiers } from '../../../controllers/Journey'
import Constant from '../../../constant';
import AppLoader from '../../../components/appLoader/index.web';
import ProgressModel from '../../../models/ProgressModel';
import MyStorage from '../../../services/storage';
import SubscriptionInfoView from '../../../components/SubscriptionInfoView';
import { getLevel } from '../../../controllers/LevelController';
import AppFonts from '../../../constant/fonts';
import WebHeader from '../../../components/WebHeader.js';
import AppStrings from '../../../constant/appStrings';


let expansionVideosGlobal = []
let runningTier = [];
let trophyVideosGlobal = []


export default function YourJourney({ navigation }) {
    const [isLoading, setIsLoading] = useState(false)
    const [tiersData, setTiersData] = useState([])
    const [totalVideosToShow, setTotalVideosToShow] = useState([])
    const [extraInfo, setExtraInfo] = useState([])

    useEffect(() => {

        setIsLoading(true)
        navigation.addListener('focus', () => {
            getTiersFromServer()
        });
    }, [])

    function getTiersFromServer() {
        getTiers(navigation).then((tiersModel) => {
            console.log("getTiersFromServer Web >>>> ", tiersModel);
            let temp = tiersModel
            let currentAttunement = temp.userModel.currentAttunement
            let currentTier = temp.userModel.currentTier
            //console.log('>>>> tt ', temp.userModel.currentLevel);
            if (temp.userModel.currentLevel > 0) {
                temp.tiersModel.splice(currentTier)
                setTiersData(temp)
                expansionVideosGlobal = temp.purchasedExpansionVideos
                trophyVideosGlobal = temp.trophy_videos
                runningTier = []
                runningTier = runningTier.concat(trophyVideosGlobal)
                callGetLevelApi(0, temp.tiersModel)
            } else {

                if (tiersModel.purchasedExpansionVideos != undefined) {
                    setTotalVideosToShow(tiersModel.purchasedExpansionVideos)
                    let extraInfo = new ProgressModel(tiersModel.userModel, undefined, undefined)
                    setExtraInfo(extraInfo)
                    setIsLoading(false)
                }
            }
        }).catch((error) => {
            setIsLoading(false)
        })
    }

    const callGetLevelApi = (index, modelArray) => {

        if (index < modelArray.length) {

            let tierId = modelArray[index].id
            getLevel(tierId, modelArray[index]).then((levelModel) => {
                //console.log('callGetLevelApi rrrrrrrrr===== ', levelModel);
                if (index == levelModel.userModel.currentTier - 1) {
                    let temp = levelModel
                    let userModel = levelModel.userModel
                    console.log('callGetLevelApi User model rrrrrrr=== ', userModel);
                    temp.levelModel.splice(userModel.currentLevel - 1)
                    runningTier = runningTier.concat(temp.levelModel)

                    let extraInfo = new ProgressModel(temp.userModel, temp.levelModel, temp.tierVideoModel)
                    setExtraInfo(extraInfo)
                } else {
                    runningTier = runningTier.concat(levelModel.levelModel)
                    let extraInfo = new ProgressModel(levelModel.userModel, levelModel.levelModel, levelModel.tierVideoModel)
                    setExtraInfo(extraInfo)

                }
                callGetLevelApi(index + 1, modelArray)

            }).catch((error) => {
                setIsLoading(false)
            })
        } else {
            runningTier = runningTier.concat(expansionVideosGlobal)
            setTotalVideosToShow(runningTier)
            setIsLoading(false)

        }
    }

    const loaderToggle = (status) => {
        // console.log('status', status);
        setIsLoading(status)
    }

    const clickCell = (item) => {

        setIsLoading(true)
        getLevel(item.tierId).then((levelModel) => {
            let extraInfo = new ProgressModel(levelModel.userModel, levelModel.levelModel, levelModel.tierVideoModel)
            let index = extraInfo.levelModel.findIndex((x) => x.id == item.id)
            if (index > -1) {
                let isPlay = MyStorage.getItem(Constant.paymentType.subscription) == '1'
                console.log('navigation', isPlay);
                navigation.navigate(AppStrings.videoDetailsView, { videoInfo: convertObjectToString(item), infoData: convertObjectToString(extraInfo), isComingFromPage: Constant.ScreensName.journey, isVideoPlayable: isPlay, isCurrentSequence: item.tierSequence })
            }
            setIsLoading(false)

        }).catch((error) => {
            setIsLoading(false)
        })
    }

    return (
        <View style={styles.container}>
            {gradientBackground()}
            <WebHeader navigation={navigation} titles={["My Way"]} />
            <View style={[styles.container, { padding: 20, backgroundColor: 'clear' }]}>
                {MyStorage.getItem(Constant.paymentType.subscription) == '0' && <SubscriptionInfoView loaderActive={loaderToggle} />}
                {totalVideosToShow.length == 0 && !isLoading &&
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: 15, color: 'white', fontFamily: AppFonts.robototMedium }}>No videos unlocked</Text>
                    </View>

                }
                <VideoPreview onClickMyWayLevel={clickCell} navigation={navigation} extraInfo={extraInfo} userModel={tiersData.userModel} videoData={totalVideosToShow} screen={Constant.ScreensName.journey} />
            </View>

            {isLoading && <AppLoader />}
        </View>
    );

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: 'white',

    },

});