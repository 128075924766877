import ContactUsRequest from '../../models/ContactUsModel';
import ApiManager from '../../services/apiManager';
import Constant from '../../constant';
var apiManager = ApiManager.getInstance();
let contactUsEndPoint = Constant.apiEndPoints.contactUs;

export const onContactUs = async (contactUsParam: ContactUsRequest) => new Promise((resolve, reject) => {

    let requestParameter = {
        'full_name': contactUsParam.fullName,
        'email': contactUsParam.email,
        'phone': contactUsParam.phone,
        'message': contactUsParam.message
    }

    apiManager.onPostApi(contactUsEndPoint, requestParameter).then((responseData) => {
        if (responseData.json.status == Constant.successCode) {
            // console.log('----ContactUs success Response : ', responseData.json.data)
            resolve()
        }
    }).catch((error) => {
        // console.log("ContactUs Error:", error);
        reject()
    })
})