import { View, Image, Text, TouchableOpacity, } from 'react-native'
import { _scale } from '../../utils'
import { commonStyles } from '../../utils/commonStyles'
import styles from './index.style.web'
import WebButton from '../../components/webButton'
import { LinearGradient } from 'expo-linear-gradient'
import WebSplash from '../../components/WebSplash'
import { useEffect, useState } from 'react'
import MyStorage from '../../services/storage'
import constant from '../../constant'

export default function Welcome({ props }) {

    const onClickSignInBtn = () => {
        props.replace('LoginContainer')
    }
    const [showSplash, setShowSplash] = useState(false)

    useEffect(() => {

        if (MyStorage.getItem(constant.appKey.isComeFromLogin) == '1') {
            MyStorage.setItem(constant.appKey.isComeFromLogin, '0')
        } else {
            setShowSplash(true)
        }
    }, [])

    const onHideSplash = () => {
        setShowSplash(false)
    }

    return (
        <View style={[commonStyles.mainContainer, { borderRadius: _scale(20), minHeight: 300, }]}>
            {showSplash && <WebSplash onHideSplash={onHideSplash} />}

            <LinearGradient colors={['#283C54', '#000000']} style={[commonStyles.gradientBackGround, { borderRadius: 20, }]} />
            <View key={'componentsContainer'} style={{ flex: 1, justifyContent: 'space-between', }}>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Image
                        source={require('../../assets/images/appicon.png')}
                        style={styles.logoStyle} />
                </View>

                <View key={'BottomContainer'} style={styles.bottomContainer}>
                    <WebButton title="Join Now" buttonStyle={styles.buttonStyle} onPress={() => props.navigate('AuthStack', { screen: 'SignUpContainer' })} />
                    <View key={'FooterContainer'} style={styles.footerContainerStyle}>
                        <Text style={styles.hintTextStyle}>Already have an account</Text>
                        <TouchableOpacity key={'SigninContainer'} style={{ paddingHorizontal: _scale(5) }} onPress={onClickSignInBtn}>
                            <Text style={styles.signInTextStyle}>Sign in?</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>

        </View>
    )
}