import { Ionicons, AntDesign, Entypo } from '@expo/vector-icons'
import React, { useState, useRef, useEffect } from 'react'
import { View, Text, TouchableOpacity, Image, KeyboardAvoidingView, ScrollView, Platform, Keyboard, useWindowDimensions } from 'react-native'
import AppTextInput from '../../../components/appTextInput'
import AppStrings from '../../../constant/appStrings'
import AppColor from '../../../constant/colors'
import { CheckBox, Icon } from '@rneui/base';
import { bottomSafeArea, showErrorBanner, topSafeArea, _scale } from '../../../utils'
import { commonStyles } from '../../../utils/commonStyles'
import styles from './index.style.web'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Constant from '../../../constant'
import LoginRequest from '../../../models/LoginModel'
import { onLogin } from '../../../controllers/LoginController'
import WebButton from '../../../components/webButton'
import { LinearGradient } from 'expo-linear-gradient'
import AppLoader from '../../../components/appLoader/index.web'
import Storage from '../../../services/storage'

export default function Login({ props }) {
    const [isPassVisible, setPassVisible] = useState(false)
    const [rememberMeChecked, setRememberMeChecked] = useState(false);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const emailRef = useRef()
    const passwordRef = useRef()
    const [isEmailClear, setEmailClear] = useState(false)
    const [isPasswordClear, setPasswordClear] = useState(false)
    const [focus, setFocus] = useState('email')
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;


    useEffect(() => {
        AsyncStorage.getItem(Constant.appKey.isRememberMe).then(isRememberMe => {
            if (isRememberMe == "true") {
                AsyncStorage.getItem(Constant.appKey.kUserEmail).then(kUserEmail => {
                    setEmail(kUserEmail)
                })
                AsyncStorage.getItem(Constant.appKey.kUserPassword).then(kUserPassword => {
                    setPassword(kUserPassword)
                })
                setRememberMeChecked(true)
            }
        });
    }, [])

    const checkValidation = () => {
        if (email.trim().length < 1) {
            setEmail('')
            showErrorBanner(AppStrings.emailRequired)
        } else if (!email.match(Constant.emailRegex)) {
            showErrorBanner(AppStrings.validEmailAddress)
        }
        else if (password.trim().length < 1) {
            setPassword('')
            showErrorBanner(AppStrings.passwordRequired)
        } else {
            callLoginApi()
        }
    }

    const passVisible = () => {
        isPassVisible == true ? setPassVisible(false) : setPassVisible(true)
    }

    const changeStatusRememberMe = () => {
        setRememberMeChecked(!rememberMeChecked)
    }

    const navigateToScreen = (screen) => {
        // console.log('email', email);
        props.push(screen, { q: email })
    }

    function clearTextFilled(filledName) {
        // console.log("fil", filledName);
        if (filledName == 'email') {
            emailRef.current.clear()
            setEmail('')
            setEmailClear(false)
        } else {
            passwordRef.current.clear()
            setPassword('')
            setPasswordClear(false)
        }
    }

    //--------------API CALL---------------//

    const callLoginApi = () => {
        setIsLoading(true)
        Keyboard.dismiss()
        let loginRequest = new LoginRequest(email, password)
        onLogin(loginRequest).then(() => {
            if (rememberMeChecked == true) {
                Storage.setItem(Constant.appKey.isRememberMe, 'true')
            } else {
                Storage.setItem(Constant.appKey.isRememberMe, 'false')
            }
            Storage.setItem(Constant.appKey.isComeFromLogin, '1')

            props.replace('WebDrawerNavigator')
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false)
        })
    }

    return (
        <View style={[commonStyles.mainContainer, styles.mainContainer]} key={'MainContainer'}
            pointerEvents={isLoading ? 'none' : 'auto'}>
            <LinearGradient colors={['#283C54', '#000000']} style={[commonStyles.gradientBackGround, { borderRadius: 20, }]} />

            <View style={{ flex: 1, paddingTop: useSafeAreaInsets().top, borderRadius: 20 }} key={'SubContainer'}>

                <KeyboardAvoidingView
                    style={{ flex: 1 }}
                    behavior={Platform.OS === "ios" ? "padding" : null}>

                    <TouchableOpacity onPress={() => window.location.href = window.location.origin}>
                        <Image
                            source={require('../../../assets/images/appicon.png')}
                            style={styles.logoStyle} />
                    </TouchableOpacity>

                    <ScrollView keyboardShouldPersistTaps={'handled'}>
                        <View key={'FiledContainer'} style={[styles.filedContainerStyle, { marginHorizontal: isLargeScreen ? 80 : 16 }]}>
                            <AppTextInput
                                placeHolder={'E-mail'}
                                reference={emailRef}
                                defaultValue={email}
                                autoFocus={true}
                                autoCapitalize={'none'}
                                keyboardType={'email-address'}
                                returnKeyLabel={'next'}
                                returnKeyType={'next'}
                                leftIcon={<Ionicons name="person-sharp" size={24} color="white" />}
                                clearIcon={isEmailClear && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('email')} />}
                                setValue={(text) => {
                                    setEmail(text)
                                    text.trim().length >= 1 && focus == 'email' ? setEmailClear(true) : setEmailClear(false)
                                }
                                }
                                onSubmitEditing={() => passwordRef.current.focus()}
                                blurOnSubmit={false}
                                textInputDivider={{ marginTop: _scale(10) }}
                                placeholderTextColor={AppColor.lightGrey}
                                textInputStyle={{ outline: "none" }}
                                onFocus={() => {
                                    setPasswordClear(false)
                                    setFocus('email')
                                    email.trim().length > 0 ? setEmailClear(true) : setEmailClear(false)
                                }
                                }
                            />

                            <AppTextInput
                                reference={passwordRef}
                                placeHolder={'Password'}
                                defaultValue={password}
                                secureTextEntry={!isPassVisible}
                                textInputContainerStyle={{ marginTop: _scale(25) }}
                                leftIcon={<AntDesign name="lock1" size={24} color="white" />}
                                rightIcon={isPassVisible == true
                                    ? <Ionicons name="md-eye-off" size={24} color="white" onPress={passVisible} />
                                    : <Ionicons name="md-eye" size={24} color="white" onPress={passVisible} />}
                                clearIcon={isPasswordClear && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('password')} />}
                                setValue={(text) => {
                                    setPassword(text)
                                    text.trim().length >= 1 && focus == 'password' ? setPasswordClear(true) : setPasswordClear(false)
                                }
                                }
                                textInputDivider={{ marginTop: _scale(10) }}
                                placeholderTextColor={AppColor.lightGrey}
                                onSubmitEditing={() => checkValidation()}
                                textInputStyle={{ outline: "none" }}
                                onFocus={() => {
                                    setEmailClear(false)
                                    setFocus('password')
                                    password.trim().length > 0 ? setPasswordClear(true) : setPasswordClear(false)
                                }
                                }
                            />

                            <View key={'RememberMeContainer'} style={styles.checkBoxContainerStyle}>
                                <CheckBox
                                    containerStyle={styles.checkBoxStyle}
                                    checked={rememberMeChecked}
                                    onPress={changeStatusRememberMe}
                                    checkedColor={'white'}
                                    uncheckedColor={'white'}
                                />
                                <Text onPress={changeStatusRememberMe} style={styles.hintTextStyle}>Remember me</Text>
                            </View>

                            <WebButton title={'Login'} onPress={checkValidation} buttonStyle={styles.buttonStyle} />

                        </View>

                        <View key={'SignUpBtnContainer'} style={styles.signUpBtnContainer}>

                            <TouchableOpacity onPress={() => navigateToScreen('SignUpContainer')} style={styles.signUpBtnView} key={'SignUpContainer'}>
                                <Text style={styles.signUpTextStyle}>Sign Up</Text>
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => navigateToScreen('ForgotPasswordContainer')} key={'ForgotPassContainer'} style={styles.signUpBtnView}>
                                <Text style={styles.forgotPassTextStyle}>Forgot Password?</Text>
                            </TouchableOpacity>

                        </View>

                    </ScrollView>
                </KeyboardAvoidingView>

            </View>
            {isLoading && <AppLoader />}
        </View>
    )
}



