import { StyleSheet } from 'react-native'
import AppColor from '../../../constant/colors'
import { _scale } from '../../../utils'

export default StyleSheet.create({
    logoStyle: {
        height: _scale(120),
        width: _scale(120),
        alignSelf: 'center',
        marginTop: _scale(15)
    },
    filedContainerStyle: {
        marginTop: _scale(50),
        marginHorizontal: _scale(35)
    },
    buttonStyle: {
        borderRadius: _scale(25),
        marginTop: _scale(25),
        padding: _scale(15),
        backgroundColor: AppColor.lightRed,
    },
})