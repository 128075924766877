import { Platform } from "react-native";
import constant from "../../constant";
import MyStorage from "../../services/storage";
import { decryptAES } from "../../utils";
import PivotModel from "../PivotModel";

export default class ExpansionModel {
    pivotModel: PivotModel
    constructor(
        id = '',
        vimeoVideoUri = '',
        title = '',
        stripeProductId = '',
        stripePriceId = '',
        description = '',
        thumbImageURL = '',
        videoUrl = '',
        createdAt = '',
        updatedAt = '',
        subscriptionAmount = '',
        isPurchased = "",
        iOSAppleID = "",
        isLive = ""

    ) {
        this.id = id,
            this.vimeoVideoUri = vimeoVideoUri,
            this.title = title,
            this.stripeProductId = stripeProductId,
            this.stripePriceId = stripePriceId,
            this.description = description,
            this.thumbImageURL = thumbImageURL,
            this.videoUrl = videoUrl,
            this.createdAt = createdAt,
            this.updatedAt = updatedAt,
            this.subscriptionAmount = subscriptionAmount
        this.isPurchased = isPurchased
        this.iOSAppleID = iOSAppleID
        this.isLive = isLive

    }
    initWithData(res) {
        this.id = res['id'] || ''
        this.vimeoVideoUri = res['vimeo_video_uri'] || ''
        this.title = res['title'] || ''
        this.stripeProductId = res['stripe_product_id'] || ''
        this.stripePriceId = res['stripe_price_id'] || ''
        this.description = res['description'] || ''
        this.thumbImageURL = res['preview_url'] || ''

        if (Platform.OS == 'web') {
            this.videoUrl = res['encrypted_url'] || ''
        } else {
            let encryptedBase64 = res['encrypted_url'] || ""
            let decryptedURL = decryptAES(encryptedBase64, MyStorage.getItem(constant.appKey.kDecodeKey))
            this.videoUrl = decryptedURL
        }

        this.createdAt = res['created_at'] || ''
        this.updatedAt = res['updated_at'] || ''
        this.subscriptionAmount = res['subscription_amount'] || ''
        this.isPurchased = res['is_purchased'] || '0'

        this.iOSAppleID = (Platform.OS == 'ios') ? res['ios_product_id'] || "" : Platform.OS == 'android' ? res['android_product_id'] || "" : ''
        this.isLive = res['is_live'] || '0'

        // this.pivotModel = new PivotModel().initWithData(res['pivot'])
        return this;
    }
}