import { View, StyleSheet } from 'react-native'
import { _scale } from '../../utils';

export default function PagingDots(props) {
    const { numberOfDots, selectedCount } = props

    const renderDots = () => {
        let jsx = [...Array(numberOfDots)].map((val, index) => {
            return (
                <View key={'dot_' + index} style={[styles.dotStyle, { backgroundColor: index < selectedCount ? 'green' : 'white', ...props.dotStyle }]} />
            )
        })
        return jsx;
    }

    return (
        <View style={[styles.dotContainerStyle, { ...props.dotContainerStyle }]} key={'MainContainer'}>
            {renderDots()}
        </View>
    )
}
const styles = StyleSheet.create({
    dotContainerStyle: {
        flexDirection: 'row'
    },
    dotStyle: {
        height: _scale(12),
        width: _scale(12),
        borderRadius: _scale(10),
        marginLeft: _scale(5)
    }
})