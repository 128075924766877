import React from 'react'
import { View, StyleSheet, useWindowDimensions } from 'react-native'
import ForgotPassword from '../../../screens/authScreens/forgotPassword/index.web'
import { gradientBackground } from '../../../utils'
import { commonStyles } from '../../../utils/commonStyles'

export default function SignUpContainer({ navigation, route }) {

    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;

    return (
        <View style={commonStyles.mainContainer} key={'MainContainer'}>
            {gradientBackground()}
            <View style={commonStyles.webSubContainerStyle} key={'SubContainer'}>
                <View style={[commonStyles.webBorderViewStyle, { width: isLargeScreen ? '50%' : '90%', height: '60%' }]} key={'BorderView'}>
                    <View style={{ flex: 1 }}>
                        <ForgotPassword props={navigation} email={route.params.q || ""} />
                    </View>
                </View>
            </View>
        </View>
    )
}





