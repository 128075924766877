import { View, Text } from 'react-native'
import { WebView } from 'react-native-webview'
import { LinearProgress } from '@rneui/themed'
import { bottomSafeArea, gradientBackground, topSafeArea, _scale } from '../../../utils'
import { useState } from 'react';
import { showFlashBanner } from '../../../services/flashMessage'
import AppHeader from '../../../components/appHeader';


export default function WebPage({ route, navigation }) {
    let url = route.params.paymentUrl
    //console.log('....>>>>', url);
    const [progress, setProgress] = useState(0)

    return (
        <View style={{ flex: 1 }}>


            {gradientBackground()}
            {topSafeArea()}
            <AppHeader
                isBackButton={true}
                headerTitle={route.params.title || ""}
                headerContainerStyle={{ marginVertical: _scale(5), marginHorizontal: _scale(5) }}
                onBackPress={() => navigation.goBack()}
            />
            {progress <= 0.8 &&
                <LinearProgress variant='determinate' color='red' value={progress} />
            }
            <WebView source={{ uri: url }}
                onLoadProgress={({ nativeEvent }) => {
                    // console.log(nativeEvent.progress);
                    setProgress(nativeEvent.progress)
                }}
                onNavigationStateChange={(navState) => {
                    // console.log(navState);
                    if (navState.url.includes('=success')) {
                        showFlashBanner('Payment has been successfully done.')
                        if (route.params.onRefreshView != undefined) {
                            route.params.onRefreshView()
                        }
                        navigation.pop()
                    }
                    if (navState.url.includes('=error')) {
                        showFlashBanner('Payment has been failed.', 1)
                        navigation.pop()
                    }
                }}
            />
            {bottomSafeArea()}
        </View>
    )
}