import React from "react";
import { Alert } from 'react-native';
import * as RNIap from 'react-native-iap';

export default class IAPManager extends React.Component {

  static myInstance = null;

  static getInstance() {
    if (this.myInstance === null) {
      this.myInstance = new IAPManager();
    }
    return this.myInstance;
  }

  async initMethods() {
    try {
      const result = await RNIap.initConnection();
      await RNIap.flushFailedPurchasesCachedAsPendingAndroid();
      console.log('result', result);
    } catch (err) {
      console.warn(err.code, err.message);
    }
  }

  getProductIDs = async (productIDs) => new Promise((resolve, reject) => {

    RNIap.getProducts({ skus: productIDs }).then((products) => {
      resolve(products)
    }).catch((err) => {
      console.log('Error');
      console.warn(err);
      reject(err)
    })
  });

  // Restore 
  getAvailablePurchases = async () => new Promise((resolve, reject) => {

    RNIap.getAvailablePurchases().then((purchases) => {
      console.log(purchases);
      resolve(purchases)
    }).catch((err) => {
      console.log('Error');
      console.warn(err);
      reject(err)
    })
  });



  requestPurchase = async (sku) => new Promise((resolve, reject) => {

    RNIap.requestPurchase(sku).then((products) => {
      console.log(products);
      resolve(products)
    }).catch((err) => {
      console.log('requestPurchase Error');
      console.warn(err);
      reject(err)
    })
  });


  getSubscriptions = async (itemSubs) => new Promise((resolve, reject) => {

    RNIap.getSubscriptions({ skus: itemSubs }).then((products) => {
      resolve(products)
    }).catch((err) => {
      console.log('Error');
      console.warn(err);
      reject(err)
    })
  });

  requestSubscription = async (productIDs) => new Promise((resolve, reject) => {

    RNIap.requestSubscription(productIDs).then((products) => {
      resolve(products)
    }).catch((err) => {
      console.log('Error');
      console.warn(err);
      reject(err)
    })
  });


  finishTransaction = async (purchase, isConsumable = false) => new Promise((resolve, reject) => {
    console.log('finishTransaction >>>>>>> ', isConsumable);
    RNIap.finishTransaction({ purchase, isConsumable }).then((res) => {
      resolve(res)
    }).catch((err) => {
      console.log('Error in finishTransaction');
      console.warn(err);
      reject(err)
    })

  });

}