import AppColor from "../colors";
const DefaultValues = {
    buttonValue: {
        title: 'Button',
        isLoading: false
    },
    textInputValues: {
        placeHolder: 'Enter Text',
        keyboardType: 'default',
        placeholderTextColor: AppColor.white,
        returnKeyType: 'done',
        returnKeyLabel: 'done',
        autoCapitalize: 'sentences',
        blurOnSubmit: true
    }
}
export default DefaultValues;