import { showMessage } from 'react-native-flash-message'
import { Platform } from 'react-native'
import { _scale } from '../../utils';
import AppFonts from '../../constant/fonts';
import AppStrings from '../../constant/appStrings';

export const showFlashBanner = (message, type = '0', screenName = 'Other') => {
    showMessage({
        message: AppStrings.appName,
        description: message,
        type: type == '0' ? 'success' : 'danger',
        duration: 4000,
        icon: 'auto',
        statusBarHeight: Platform.OS === 'ios' ? (screenName == 'Other' ? 10 : 0) : 5,
        style: { paddingTop: Platform.OS == 'ios' ? _scale(30) : Platform.OS == 'android' ? _scale(40) : _scale(20), backgroundColor: '#d9534f' },
        titleStyle: { fontFamily: AppFonts.robotoBold },
        textStyle: { fontFamily: AppFonts.robotoRegular }
    });
}