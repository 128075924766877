import TiersModel from "../TiersModel";
import UserModel from "../UserModel";
import TierVideoModel from '../TierVideoModel';
import TrophyVideos from '../TrophyVideos';

export default class TiersResponseModel {

    userModel: UserModel
    tiersModel: TiersModel
    purchasedExpansionVideos: TierVideoModel
    trophy_videos: TrophyVideos
    constructor() {
    }

    initWithRes(res) {

        this.userModel = new UserModel().initWithData(res["user"])
        let tiersArray = res["tiers"]
        this.tiersModel = []
        if (tiersArray.length > 0) {
            tiersArray.forEach(element => {
                this.tiersModel.push(new TiersModel().initWithData(element))
            });
        }

        this.purchasedExpansionVideos = []
        let tempExpansion = res["purchased_expansion_videos"]
        if (tempExpansion.length > 0) {
            tempExpansion.forEach(element => {
                this.purchasedExpansionVideos.push(new TierVideoModel().initWithRes(element, 'expansion'))
            });
        }

        this.trophy_videos = []
        let tempTrophy = res["trophy_videos"]
        if (tempTrophy.length > 0) {
            tempTrophy.forEach(element => {
                this.trophy_videos.push(new TrophyVideos().initWithRes(element))
            });
        }

        return this
    }

}