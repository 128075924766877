import { Image, Platform, StyleSheet, Text } from 'react-native'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { createStackNavigator } from '@react-navigation/stack'
import Home from '../../screens/tabsScreen/home'
import YourJourney from '../../screens/tabsScreen/yourJourney'
import TheWay from '../../screens/tabsScreen/theWay'
import Expansion from '../../screens/tabsScreen/expansion'
import Source from '../../screens/tabsScreen/source'
import { Entypo, MaterialCommunityIcons, MaterialIcons, FontAwesome5 } from '@expo/vector-icons';
import AppStrings from '../../constant/appStrings'
import AppColor from '../../constant/colors'
import { _scale } from '../../utils'
import ContactUs from '../../screens/extraScreen/contactUs'
import TierDetails from '../../screens/tabsScreen/tierDetails'
import ExpansionDetail from '../../screens/tabsScreen/expansionDetail'
import AppFonts from '../../constant/fonts'
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import VideoDetailsView from '../../screens/tabsScreen/VideoDetailsView'
import constant from '../../constant'
import Subscription from '../../screens/tabsScreen/common/subscriptions'
import DeleteAccount from '../../screens/authScreens/deleteAccount'


const Tab = createBottomTabNavigator()
const Stack = createStackNavigator();

function HomeStack({ navigation }) {
    return (
        <Stack.Navigator >
            <Stack.Screen name={AppStrings.homeScreen} component={Home} options={{ headerShown: false }} />
            <Stack.Screen name={AppStrings.videoDetailsView} component={VideoDetailsView} options={{ headerShown: false }} />
            <Stack.Screen name={'Subscription'} component={Subscription} options={{ headerShown: false }} />


        </Stack.Navigator>
    )
}

function YourJourneyStack({ navigation }) {
    return (
        <Stack.Navigator >
            <Stack.Screen name={AppStrings.yourJourneyScreen} component={YourJourney} options={{ headerShown: false }} />
            <Stack.Screen name={AppStrings.tierDetailsScreen} component={TierDetails} options={{ headerShown: false }} />
            <Stack.Screen name={AppStrings.videoDetailsView} component={VideoDetailsView} options={{ headerShown: false }} />
            <Stack.Screen name={'Subscription'} component={Subscription} options={{ headerShown: false }} />

        </Stack.Navigator>
    )
}

function TheWayStack({ navigation }) {
    return (
        <Stack.Navigator >
            <Stack.Screen name={AppStrings.theWayScreen} component={TheWay} options={{ headerShown: false }} />
            <Stack.Screen name={AppStrings.tierDetailsScreen} component={TierDetails} options={{ headerShown: false }} />
            <Stack.Screen name={AppStrings.videoDetailsView} component={VideoDetailsView} options={{ headerShown: false }} />
            <Stack.Screen name={'Subscription'} component={Subscription} options={{ headerShown: false }} />

        </Stack.Navigator>
    )
}

function ExpansionStack({ navigation }) {
    return (
        <Stack.Navigator >
            <Stack.Screen name={AppStrings.expansionScreen} component={Expansion} options={{ headerShown: false }} />
            <Stack.Screen name={AppStrings.expansionDetailScreen} component={ExpansionDetail} options={{ headerShown: false }} />
        </Stack.Navigator>
    )
}

function SourceStack({ navigation }) {
    return (
        <Stack.Navigator >
            <Stack.Screen name={AppStrings.sourceScreen} component={Source} options={{ headerShown: false }} />
            <Stack.Screen name={AppStrings.contactUs} component={ContactUs} options={{ headerShown: false }} />
            <Stack.Screen name={AppStrings.deleteAccount} component={DeleteAccount} options={{ headerShown: false }} />
        </Stack.Navigator>
    )
}

export default function BottomTabBar() {
    const insets = useSafeAreaInsets();

    return (
        <Tab.Navigator initialRouteName='Home'
            screenOptions={({ route, navigation }) => ({
                tabBarIcon: ({ focused, color, size }) => {
                    if (route.name === AppStrings.homeStack) {
                        return <Image source={require('../../assets/images/home.png')} style={styles.inActiveIconStyle} />;
                    } else if (route.name === AppStrings.yourJourneyStack) {
                        return <Image source={require('../../assets/images/tab_2.png')} style={styles.inActiveIconStyle} />
                    } else if (route.name === AppStrings.theWayStack) {
                        return <Image source={require('../../assets/images/tab_3.png')} style={styles.inActiveIconStyle} />

                    } else if (route.name === AppStrings.expansionStack) {
                        return <Image source={require('../../assets/images/tab_4.png')} style={styles.inActiveIconStyle} />
                    } else {
                        return <Image source={require('../../assets/images/tab_5.png')} style={styles.inActiveIconStyle} />
                    }
                },

                tabBarLabel: ({ focused, tintColor }) => {
                    let screenName = ''
                    switch (route.name) {
                        case 'HomeStack':
                            screenName = constant.ScreensName.home
                            break;
                        case 'YourJourneyScreen':
                            screenName = constant.ScreensName.journey
                            break;
                        case 'TheWayStack':
                            screenName = constant.ScreensName.theWay
                            break;
                        case 'ExpansionStack':
                            screenName = constant.ScreensName.expansion
                            break;
                        case 'SourceStack':
                            screenName = constant.ScreensName.source
                            break;
                        default:
                            break;
                    }
                    return <Text style={{ fontFamily: focused ? AppFonts.robotoBold : AppFonts.robotoRegular, color: AppColor.white, fontSize: focused ? _scale(12) : _scale(9) }}>{screenName}</Text>
                },
                tabBarStyle: {
                    backgroundColor: AppColor.black,
                    height: Platform.OS == 'android' ? _scale(65) : _scale(54) + insets.bottom,
                    borderTopWidth: _scale(0),
                },
                tabBarHideOnKeyboard: true,
            })}
        >
            <Tab.Screen name={AppStrings.homeStack} component={HomeStack} options={{ headerShown: false, title: 'Home' }} />
            <Tab.Screen name={AppStrings.yourJourneyStack} component={YourJourneyStack} options={{ headerShown: false, title: 'My Way' }} />
            <Tab.Screen name={AppStrings.theWayStack} component={TheWayStack} options={{ headerShown: false, title: 'The Way' }} />
            <Tab.Screen name={AppStrings.expansionStack} component={ExpansionStack} options={{ headerShown: false, title: 'Expansion' }} />
            <Tab.Screen name={AppStrings.sourceStack} component={SourceStack} options={{ headerShown: false, title: 'Source' }} />
        </Tab.Navigator>
    )
}

const styles = StyleSheet.create({
    inActiveIconStyle: {
        height: _scale(30),
        width: _scale(30),
    },
    activeIconStyle: {
        height: _scale(45),
        width: _scale(45)
    },

})