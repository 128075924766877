import { View, Text, KeyboardAvoidingView, Platform, ScrollView, Image, TouchableOpacity, Keyboard, useWindowDimensions } from 'react-native'
import React, { useState, useRef, useEffect } from 'react'
import { bottomSafeArea, showErrorBanner, showSuccessBanner, topSafeArea, _scale } from '../../../utils'
import { commonStyles } from '../../../utils/commonStyles'
import { MaterialIcons, Ionicons, Entypo } from '@expo/vector-icons'
import AppTextInput from '../../../components/appTextInput'
import AppStrings from '../../../constant/appStrings'
import constant from '../../../constant'
import styles from './index.style.web'
import AppColor from '../../../constant/colors'
import ForgotPassRequest from '../../../models/ForgotPasswordModel'
import { onForgot } from '../../../controllers/ForgotPasswordController'
import WebButton from '../../../components/webButton'
import { LinearGradient } from 'expo-linear-gradient'
import AppLoader from '../../../components/appLoader/index.web'

export default function ForgotPassword(props) {

    const [email, setEmail] = useState(props.email)
    const [isLoading, setIsLoading] = useState(false)
    const [crossIcon, setCrossIcon] = useState('')
    const [focus, setFocus] = useState('')
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;
    const emailRef = useRef()



    useEffect(() => {
        const unsubscribe = props.props.addListener('focus', () => {

        });
        return unsubscribe;
    }, [])

    const checkValidation = () => {
        if (email.trim().length < 1) {
            setEmail('')
            showErrorBanner(AppStrings.emailRequired)
        } else if (!email.match(constant.emailRegex)) {
            showErrorBanner(AppStrings.validEmailAddress)
        } else {
            forgotPassword()
        }
    }

    const navigateToScreen = (screen) => {
        props.props.push(screen, { q: email })
    }

    function clearTextFilled(filledName) {
        if (filledName == 'email') {
            emailRef.current.clear()
            setEmail('')
            setCrossIcon('')
        }
    }

    //--------- API CALL-----------//

    const forgotPassword = () => {
        setIsLoading(true)
        Keyboard.dismiss()
        let forgotPassRequest = new ForgotPassRequest(email)
        onForgot(forgotPassRequest).then(() => {
            props.props.push('ResetPasswordContainer', { q: email })
            showSuccessBanner("Code send on your email ")
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false)
        })
    }

    return (
        <View style={[commonStyles.mainContainer, { borderRadius: _scale(20) }]} key={'MainContainer'}
            pointerEvents={isLoading ? 'none' : 'auto'}>

            <LinearGradient colors={['#283C54', '#000000']} style={[commonStyles.gradientBackGround, { borderRadius: 20, }]} />

            {topSafeArea()}

            <KeyboardAvoidingView
                behavior={Platform.OS === "ios" ? "padding" : null}
                style={{ flex: 1 }}>
                <TouchableOpacity onPress={() => window.location.href = window.location.origin}>
                    <Image
                        source={require('../../../assets/images/appicon.png')}
                        style={styles.logoStyle} />
                </TouchableOpacity>

                <ScrollView keyboardShouldPersistTaps={'handled'}>

                    <Text style={styles.headingText}>Forgot password</Text>

                    <View key={'FiledContainer'} style={[styles.filedContainerStyle, { marginHorizontal: isLargeScreen ? 80 : 16 }]}>
                        <AppTextInput
                            reference={emailRef}
                            placeHolder={'E-mail'}
                            defaultValue={email}
                            autoFocus={true}
                            keyboardType={'email-address'}
                            autoCapitalize={'none'}
                            textInputContainerStyle={{ marginTop: _scale(20) }}
                            leftIcon={<MaterialIcons name="email" size={24} color="white" />}
                            clearIcon={crossIcon == 'email' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('email')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 && focus == 'email' ? setCrossIcon('email') : setCrossIcon('')
                                setEmail(text)

                            }
                            }
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.lightGrey}
                            textInputStyle={{ outline: "none" }}
                            onFocus={() => {
                                setCrossIcon('email')
                                setFocus('email')
                                email.trim().length > 0 ? setCrossIcon('email') : setCrossIcon('')
                            }
                            }
                        />

                        <WebButton title="Send Email" buttonStyle={styles.buttonStyle} onPress={checkValidation} />

                    </View>

                    <View key={'FooterContainer'} style={styles.footerContainerStyle}>
                        <Text style={styles.hintTextStyle}>Already have reset code?</Text>
                        <TouchableOpacity key={'SigninContainer'} style={{ paddingHorizontal: _scale(5) }} onPress={() => navigateToScreen('ResetPasswordContainer')} >
                            <Text style={styles.redTextStyle}>Set new password</Text>
                        </TouchableOpacity>
                    </View>

                </ScrollView>
            </KeyboardAvoidingView>
            {isLoading && <AppLoader />}
            {bottomSafeArea()}
        </View>
    )
}
