import { View, Text, Image, useWindowDimensions, ScrollView, StyleSheet, Linking, } from "react-native";
import { useEffect, useState } from 'react'
import WebButton from "../../../components/webButton";
import AppColor from "../../../constant/colors";
import AppFonts from "../../../constant/fonts";
import { gradientBackground, _scale } from "../../../utils";
import { commonStyles } from "../../../utils/commonStyles";
import { onGetPaymentUrl } from '../../../controllers/PaymentUrlController'
import PaymentUrlRequestModel from '../../../models/PaymentUrlRequestModel'
import AppLoader from "../../../components/appLoader/index.web";
import constant from "../../../constant";

export default function ExpansionDetail({ navigation, route }) {
    const [isLoading, setIsLoading] = useState(false)
    const { styles } = useStyle();
    let priceId = route.params.itemDetail.stripePriceId
    let videoId = route.params.itemDetail.id

    //--------API CALL--------//


    useEffect(() => {
        if (priceId == undefined) {
            let url = window.location.pathname
            let path = url.split('/');
            path.pop();
            path = path.join('/');
            window.location.href = window.location.origin + path
        }

    }, [])


    const callGetPaymentUrlApi = () => {
        setIsLoading(true)
        let paymentUrlRequest = new PaymentUrlRequestModel(priceId, videoId, constant.paymentType.payment)
        onGetPaymentUrl(paymentUrlRequest).then((response) => {
            // console.log("-----url success", response.json.data[0].url);
            window.location.href = response.json.data[0].url
            setIsLoading(false)
        }).catch((error) => {
            // console.log("-----url error", error);
            setIsLoading(false)
        })
    }

    return (
        <View style={commonStyles.mainContainer} key={'MainContainer'}>
            {gradientBackground()}
            <View style={commonStyles.webSubContainerStyle} key={'SubContainer'}>
                <View style={[commonStyles.webBorderViewStyle, { width: '70%', maxWidth: _scale(830) }]} key={'BorderView'}>
                    <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.scrollViewStyle}>
                        <View style={styles.itemContainerSTyle} key={'ItemContainer'}>
                            <Image source={{ uri: route.params.itemDetail.thumbImageURL }} style={styles.imageSTyle} />
                            <View style={styles.itemRightContainerStyle} key={'ItemRightContainerStyle'}>
                                <Text style={styles.titleStyle} key={'title'}>{route.params.itemDetail.title}</Text>
                                <Text style={styles.descriptionStyle} key={'Description'}>{route.params.itemDetail.description}</Text>
                                <WebButton onPress={callGetPaymentUrlApi} title={`Buy Now $${route.params.itemDetail.subscriptionAmount}`} buttonStyle={styles.buttonStyle} />
                                <View style={styles.footerStyle} key={'Footer'} />
                            </View>
                        </View>
                    </ScrollView>
                </View>
            </View>
            {isLoading && <AppLoader paymentMessage={true} />}
        </View>
    )
}

const useStyle = () => {
    var isSmallDevice = false
    const { height, width } = useWindowDimensions();
    width <= 950 ? isSmallDevice = true : isSmallDevice = true

    const styles = StyleSheet.create({
        scrollViewStyle: {
            flexGrow: 1,
            flexDirection: 'column',
        },
        itemContainerSTyle: {
            flex: 1,
            flexDirection: isSmallDevice ? 'column' : 'row'
        },
        itemRightContainerStyle: {
            padding: _scale(20),
            flex: 1
        },
        imageSTyle: {
            flex: 1,
            width: '100%',
            borderBottomLeftRadius: isSmallDevice ? _scale(0) : _scale(20),
            borderTopRightRadius: isSmallDevice ? _scale(20) : _scale(0),
            borderTopLeftRadius: _scale(20),
            resizeMode: isSmallDevice ? 'cover' : 'center',
            backgroundColor: isSmallDevice ? '' : 'black'
        },
        titleStyle: {
            color: AppColor.white,
            fontSize: _scale(25),
            fontFamily: AppFonts.robotoBold
        },
        descriptionStyle: {
            color: AppColor.white,
            fontSize: _scale(18),
            fontFamily: AppFonts.robototMedium,
            marginBottom: _scale(10),
            lineHeight: _scale(25),
            letterSpacing: 1,
            marginTop: _scale(30),
            flex: 1
        },
        buttonStyle: {
            borderRadius: _scale(25),
            padding: _scale(15),
        },
        footerStyle: {
            height: isSmallDevice ? _scale(15) : _scale(0)
        }
    })
    return { styles }
}

