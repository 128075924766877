import ApiManager from '../../services/apiManager';
import Storage from '../../services/storage';
import Constant from '../../constant';
var apiManager = ApiManager.getInstance();
import AsyncStorage from '@react-native-async-storage/async-storage'
let logoutEndPoint = Constant.apiEndPoints.logout;


export const onLogout = async () => new Promise((resolve, reject) => {
    var userEmail = '', usePass = '', rememberMeStatus = ''

    apiManager.onPostApi(logoutEndPoint).then((responseData) => {
        if (responseData.json.status == Constant.successCode) {
            // console.log('----Logout success Response : ', responseData)
            AsyncStorage.getItem(Constant.appKey.kUserEmail).then((response) => {
                userEmail = response
            })
            AsyncStorage.getItem(Constant.appKey.isRememberMe).then((response) => {
                rememberMeStatus = response
            })
            AsyncStorage.getItem(Constant.appKey.kUserPassword).then((response) => {
                usePass = response
                setData()
            }).catch(() => {
                setData()
            })
            resolve()
        }
    }).catch((error) => {
        // console.log("Logout Error:", error);
        reject()
    })

    function setData() {
        Storage.clear()
        Storage.setItem(Constant.appKey.isVerifedUser, false)
        Storage.setItem(Constant.appKey.isRememberMe, rememberMeStatus)
        Storage.setItem(Constant.appKey.kUserEmail, userEmail)
        Storage.setItem(Constant.appKey.kUserPassword, usePass)
    }

})