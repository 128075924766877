import ApiManager from '../../services/apiManager';
import Storage from '../../services/storage';
import Constant from '../../constant';
import ProgressModel from '../../models/ProgressModel';
import UserModel from '../../models/UserModel';
var apiManager = ApiManager.getInstance();

const updateUserProgress = Constant.apiEndPoints.updateUserProgress;

const key_tier = "tier"
const key_level = "level"
const key_attunement = "attunement"
const key_experience = "experience_day"



export const updateProgress = async (progressModel: ProgressModel) => new Promise((resolve, reject) => {

    console.log('Inside updateProgress >>> ', progressModel);
    let userInfo = progressModel.userModel
    let levelInfo = progressModel.levelModel
    let tierInfo = progressModel.tierVideoModel

    let currentAttunement = userInfo.currentAttunement
    let currentLevel = userInfo.currentLevel
    let currentExperienceDay = userInfo.currentExperienceDay
    let currentTier = userInfo.currentTier

    let updateValue = ""
    console.log('Inside updateProgress currentAttunement >>> ', currentAttunement);
    console.log('Inside updateProgress tierInfo >>> ', tierInfo);
    if (currentAttunement < tierInfo.length) { // user should be show all Attunement videos
        console.log('updateProgress IF');
        currentAttunement = currentAttunement + 1
        updateValue = key_attunement
    } else {
        console.log('updateProgress ELSE');
        currentExperienceDay = currentExperienceDay + 1
        currentAttunement = 0
        updateValue = key_experience
        if (currentExperienceDay == 7) {
            currentLevel = currentLevel + 1
            updateValue = key_level
            currentExperienceDay = 0
        }
        if (currentLevel == levelInfo.length + 1) {
            currentTier = currentTier + 1
            currentLevel = 1
            updateValue = key_tier
        }
    }

    let requestParameter = {
        'update': updateValue,
        'tier': currentTier,
        "level": currentLevel,
        "attunement": currentAttunement,
        "experience_day": currentExperienceDay
    }

    apiManager.onPostApi(updateUserProgress, requestParameter).then((responseData) => {
        if (responseData.json.status == Constant.successCode) {
            let userModel = new UserModel().initWithData(responseData.json.data['user'])
            console.log('Updated user', userModel);
            resolve(userModel)
        }
    }).catch((error) => {
        // console.log("Logout Error:", error);
        reject(error)
    })
})