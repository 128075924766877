import { createStackNavigator } from '@react-navigation/stack'
import AppStrings from '../../constant/appStrings';
// Mobile
import Login from '../../screens/authScreens/login';
import SignUp from '../../screens/authScreens/signUp';
import ForgotPassword from '../../screens/authScreens/forgotPassword';
import ResetPassword from '../../screens/authScreens/resetPassword';
import MainScreen from '../../entry/mainscreen';
import Welcome from '../../entry/welcome';
import { Platform } from 'react-native';
//Web
import LoginContainer from '../../containers/Auth/loginContainer'
import SignUpContainer from '../../containers/Auth/signupContainer';
import ForgotPasswordContainer from '../../containers/Auth/forgotPasswordContainer';
import RestPasswordContainer from '../../containers/Auth/resetPasswordContainer';
import WelcomeContainer from '../../containers/Auth/welcomeContainer';
import MyAccount from '../../screens/authScreens/myAccount';
import constant from '../../constant';
import ChangePassword from '../../screens/authScreens/changePassword';

const Stack = createStackNavigator();

export default function AuthStack() {
    if (Platform.OS == 'web') {
        return (
            <Stack.Navigator initialRouteName='MainScreen'>
                <Stack.Screen name={AppStrings.mainScreen} component={MainScreen} options={{
                    headerShown: false
                }} />
                <Stack.Screen name={AppStrings.welcomeContainer} component={WelcomeContainer} options={{
                    headerShown: false,
                    headerTitle: '',
                    title: constant.AppInfo.name + ' | ' + 'Welcome',
                }} />
                <Stack.Screen name={AppStrings.loginContainer} component={LoginContainer} options={{
                    headerShown: false,
                    headerTitle: '',
                    title: constant.AppInfo.name + ' | ' + 'Sign in',
                }} />
                <Stack.Screen name={AppStrings.signUpContainer} component={SignUpContainer} options={{
                    headerShown: false,
                    headerTitle: '',
                    title: constant.AppInfo.name + ' | ' + 'Sign up',
                }} />
                <Stack.Screen name={AppStrings.forgotPasswordContainer} component={ForgotPasswordContainer} options={{
                    headerShown: false,
                    headerTitle: '',
                    title: constant.AppInfo.name + ' | ' + 'Forgot Password',
                }} />
                <Stack.Screen name={AppStrings.resetPasswordContainer} component={RestPasswordContainer} options={{
                    headerShown: false,
                    headerTitle: '',
                    title: constant.AppInfo.name + ' | ' + 'Reset Password',
                }} />
            </Stack.Navigator>
        )
    } else {
        return (
            <Stack.Navigator>
                <Stack.Screen name={AppStrings.mainScreen} component={MainScreen} options={{ headerShown: false, }} />
                <Stack.Screen name={AppStrings.welcome} component={Welcome} options={{ headerShown: false, presentation: 'modal' }} />
                <Stack.Screen name={AppStrings.loginScreen} component={Login} options={{ headerShown: false }} />
                <Stack.Screen name={AppStrings.signUpScreen} component={SignUp} options={{ headerShown: false }} />
                <Stack.Screen name={AppStrings.forgotPassword} component={ForgotPassword} options={{ headerShown: false }} />
                <Stack.Screen name={AppStrings.resetPassword} component={ResetPassword} options={{ headerShown: false }} />
                <Stack.Screen name={AppStrings.myAccount} component={MyAccount} options={{ headerShown: false }} />
                <Stack.Screen name={AppStrings.changePassword} component={ChangePassword} options={{ headerShown: false }} />
            </Stack.Navigator>
        )
    }
}