import { useEffect, useState } from "react";
import { View } from "react-native";
import { gradientBackground, _scale } from "../../../utils";
import { commonStyles } from "../../../utils/commonStyles";
import { getExpansion } from '../../../controllers/ExpansionController'
import VideoPreview from "../../../components/VideoPreview/index.web";
import constant from "../../../constant";
import AppLoader from "../../../components/appLoader/index.web";
import MyStorage from "../../../services/storage";
import SubscriptionInfoView from "../../../components/SubscriptionInfoView";
import WebHeader from "../../../components/WebHeader.js";
import ProgressModel from "../../../models/ProgressModel";
import UserModel from "../../../models/UserModel";

export default function Expansion({ navigation }) {
    const [expansionData, setExpansionData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [extraData, setExtraData] = useState({})

    useEffect(() => {
        setIsLoading(true)
        getExpansionApi()
    }, [])

    function getExpansionApi() {
        getExpansion(navigation).then((expansionModel) => {
            // console.log("expansion web success", expansionModel.expansionModel);
            setIsLoading(false)
            setExpansionData(expansionModel.expansionModel)

            let userModel = new UserModel()
            userModel.key = MyStorage.getItem(constant.appKey.kDecodeKey)
            let extraInfo = new ProgressModel(userModel)
            // console.log('extraInfo', extraInfo);
            setExtraData(extraInfo)

        }).catch((error) => {
            setIsLoading(false)
            // console.log("expansion web error");
        })
    }

    const loaderToggle = (status) => {
        // console.log('status', status);
        setIsLoading(status)
    }

    return (
        <View style={[commonStyles.mainContainer, {}]} key={'MainContainer'}>
            {gradientBackground()}

            <WebHeader navigation={navigation} titles={["Expansion"]} />
            <View style={[commonStyles.mainContainer, { padding: 20, backgroundColor: 'clear' }]}>
                {MyStorage.getItem(constant.paymentType.subscription) == '0' && <SubscriptionInfoView loaderActive={loaderToggle} />}
                <VideoPreview extraInfo={extraData} screen={constant.ScreensName.expansion} navigation={navigation} videoData={expansionData} />
            </View>
            {isLoading && <AppLoader />}
        </View>
    )
}


