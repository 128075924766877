import React, { useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Button } from "@rneui/base";
import { onGetSubscriptionUrl } from '../../controllers/PaymentUrlController';

export default function SubscriptionInfoView(props) {

    const onClickSubscribe = () => {

        onGetSubscriptionUrl().then((response) => {
            // console.log("-----url success", response.json.data[0].url);
            props.loaderActive(false)
            window.location.href = response.json.data[0].url
        }).catch((error) => {
        })
    }

    const onPressSubscriptionBtn = () => {
        props.loaderActive(true)
        onClickSubscribe()
    }

    return (
        <View style={{ paddingHorizontal: 16 }}>
            <View style={{ backgroundColor: 'red', flex: 1, borderRadius: 10, flexDirection: 'row', padding: 12, justifyContent: 'space-between', alignItems: 'center' }}>
                <Text style={{ color: 'white' }}>You can only watch free videos. For more videos, click here to Subscribe</Text>
                <Button title="Subscribe" type="outline"
                    buttonStyle={{
                        borderColor: 'white',
                    }}
                    titleStyle={{ color: 'white' }}
                    onPress={onPressSubscriptionBtn}
                />
            </View>
        </View >
    )
}