import ResetPasswordRequest from '../../models/LoginModel';
import ApiManager from '../../services/apiManager';
import Constant from '../../constant';
var apiManager = ApiManager.getInstance();

let resetPasswordEndPoint = Constant.apiEndPoints.resetPassword;

export const onResetPassword = async (resetPasswordParam: ResetPasswordRequest) => new Promise((resolve, reject) => {

    let requestParameter = {
        'email': resetPasswordParam.email,
        'reset_code': resetPasswordParam.resetCode,
        'new_password': resetPasswordParam.newPassword
    }

    apiManager.onPostApi(resetPasswordEndPoint, requestParameter).then((responseData) => {
        if (responseData.json.status == Constant.successCode) {
            // console.log('----Reset password success Response : ', responseData.json.data)
            resolve()
        }
    }).catch((error) => {
        // console.log("Reset password Error:", error);
        reject()
    })
})