import React from 'react'
import { View, useWindowDimensions } from 'react-native'
import WebHeader from '../../../components/WebHeader.js'
import MyAccount from '../../../screens/authScreens/myAccount/index.web'
import { gradientBackground } from '../../../utils'
import { commonStyles } from '../../../utils/commonStyles'

export default function MyAccountContainer({ navigation }) {

    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;

    return (
        <View style={commonStyles.mainContainer} key={'MainContainer'}>
            {gradientBackground()}
            <WebHeader hideAccount={true} navigation={navigation} titles={["My Account"]} />
            <View style={commonStyles.webSubContainerStyle} key={'SubContainer'}>
                <View style={[commonStyles.webBorderViewStyle, { width: isLargeScreen ? '50%' : '90%' }]} key={'BorderView'}>
                    <View style={{ flex: 1 }}>
                        <MyAccount navigation={navigation} />
                    </View>
                </View>
            </View>

        </View>
    )
}





