import { SafeAreaView, StyleSheet, View, FlatList, Image, useWindowDimensions } from 'react-native';
import React, { useState, useEffect } from 'react';
import VideoPreview from '../../../components/VideoPreview/index.web';
import { gradientBackground } from '../../../utils';
import Constant from '../../../constant';
import SubscriptionInfoView from '../../../components/SubscriptionInfoView';
import AppLoader from '../../../components/appLoader/index.web';
import MyStorage from '../../../services/storage';
import WebHeader from '../../../components/WebHeader.js';
import WebSplash from '../../../components/WebSplash';
import constant from '../../../constant';
import { getProfile } from '../../../controllers/GetProfileController';


export default function Home({ navigation }) {

    const [userModel, setUserModel] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [showSplash, setShowSplash] = useState(false)


    useEffect(() => {
        if (MyStorage.getItem(Constant.appKey.isComeFromLogin) == '1') {
            MyStorage.setItem(Constant.appKey.isComeFromLogin, '0')
        } else {
            setShowSplash(true)
        }
        if (MyStorage.getItem(constant.appKey.kAuthToken) != "") {
            callGetProfileApi()
        }
        return (() => {
            setIsLoading(false)
        })
    }, [])


    function callGetProfileApi() {
        getProfile(navigation).then((profileModel) => {
            setUserModel(profileModel.userModel)
        }).catch((error) => {
        })
    }

    const loaderToggle = (status) => {

        // console.log('status', status);
        setIsLoading(status)
    }

    const onHideSplash = () => {
        setShowSplash(false)
    }

    return (
        <View style={styles.container}>
            {gradientBackground()}

            {showSplash && <WebSplash onHideSplash={onHideSplash} />}
            <WebHeader navigation={navigation} titles={["Home"]} />
            <View style={[styles.container, { padding: 20, backgroundColor: 'clear' }]}>
                {MyStorage.getItem(Constant.paymentType.subscription) === '0' &&
                    <SubscriptionInfoView loaderActive={loaderToggle} />
                }
                <VideoPreview navigation={navigation} screen={Constant.ScreensName.home} />
            </View>

            {isLoading && <AppLoader paymentMessage={true} />}
        </View>
    );

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: 'white',
        // padding: 20,

    },

});