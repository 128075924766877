import 'react-native-gesture-handler';
import { View } from 'react-native'
import Entry from "./src/entry";
import { commonStyles } from './src/utils/commonStyles';
import { MenuProvider } from 'react-native-popup-menu'

export default function App() {
  return (
    <MenuProvider style={commonStyles.mainContainer}>
      <Entry />
    </MenuProvider>
  );
}


