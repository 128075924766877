import { TouchableOpacity, Text, StyleSheet, View } from "react-native";
import DefaultValues from "../../constant/defaultValues";
import { FontAwesome5 } from '@expo/vector-icons';
import constant from "../../constant";
import AppFonts from "../../constant/fonts";
import AppColor from "../../constant/colors";
import AppStrings from "../../constant/appStrings";

export default function WebHeader(props) {


    // console.log("titles", props.titles.length);
    const onPressLabel = (pageName) => {
    }

    const pageTitleView = () => {
        var pageTitle = ''
        pageTitle = props.titles.map((pageName, index) => {
            return (
                <View key={`headingView_${index}`} style={styles.headingView} >
                    <View key={'dividerView'} style={styles.dividerView} />
                    <Text style={styles.pageText} >{pageName}</Text>
                    {/* <Text onPress={() => onPressLabel(pageName)} style={styles.pageText} >{pageName}</Text> */}

                </View >
            )
        })
        return pageTitle
    }

    const reloadHome = () => {
        window.location.href = window.location.origin
    }

    const navigateAccount = () => {
        // console.log(props.navigation);
        if (props.navigation != undefined) {
            props.navigation.navigate(AppStrings.myAccountContainer)
        }
    }


    return (
        <View key={'mainContainer'} style={styles.mainContainer}>
            <View key={'leftHeaderView'} style={styles.leftHeaderView}>
                <FontAwesome5 onPress={() => reloadHome()} name={'home'} color={AppColor.white} size={20} />
                <Text onPress={() => reloadHome()} style={styles.companyText} >{constant.AppInfo.name}</Text>
                {props.titles.length > 0 && pageTitleView()}
            </View>

            {props.hideAccount == undefined &&
                < View style={styles.headerRightContainer}>
                    <TouchableOpacity onPress={() => navigateAccount()} numberOfLines={1} style={[styles.nameTextStyle]}>{AppStrings.webRightHeaderText}</TouchableOpacity>
                </View>
            }

        </View >

    )
}

WebHeader.defaultProps = {
    titles: [],
}

const styles = StyleSheet.create({

    mainContainer: {
        height: 60,
        backgroundColor: 'clear',
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        borderBottomColor: AppColor.white,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'

    },

    leftHeaderView: {
        alignItems: 'center',
        flexDirection: 'row',
    },

    headingView: {
        marginLeft: 15,
        flexDirection: 'row'
    },

    companyText: {
        marginLeft: 10,
        fontFamily: AppFonts.robotoBold,
        color: AppColor.white,
    },
    dividerView: {
        height: 15,
        width: 1,
        backgroundColor: AppColor.white,

    },
    pageText: {
        marginLeft: 15,
        fontFamily: AppFonts.robotoRegular,
        color: AppColor.white,
    },

    headerRightContainer: {

    },

    nameTextStyle: {
        color: AppColor.white,
        fontFamily: AppFonts.robototMedium,
        fontSize: (15),
        textAlign: 'center',
        textDecorationLine: 'underline'
    }

});