import { View, Text, ScrollView, useWindowDimensions } from 'react-native'
import { useState, useRef } from 'react'
import AppTextInput from '../../../components/appTextInput'
import WebButton from '../../../components/webButton'
import AppStrings from '../../../constant/appStrings'
import AppColor from '../../../constant/colors'
import { gradientBackground, showErrorBanner, showSuccessBanner, _scale } from '../../../utils'
import { commonStyles } from '../../../utils/commonStyles'
import { Ionicons, MaterialIcons, FontAwesome, MaterialCommunityIcons, Entypo } from '@expo/vector-icons'
import styles from './index.style.web'
import constant from '../../../constant'
import ContactUsRequest from '../../../models/ContactUsModel'
import { onContactUs } from '../../../controllers/ContactUsController'
import AppLoader from '../../../components/appLoader/index.web'
import WebHeader from '../../../components/WebHeader.js'

export default function ContactUs({ navigation }) {
    const { width, height } = useWindowDimensions();
    let isSmallScreen = false;
    width < 1000 ? isSmallScreen = true : isSmallScreen = false
    const nameRef = useRef()
    const emailRef = useRef()
    const phoneRef = useRef()
    const messageRef = useRef()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [crossIcon, setCrossIcon] = useState('')
    const [focus, setFocus] = useState('')


    const checkValidation = () => {
        if (name.trim().length < 1) {
            showErrorBanner(AppStrings.nameRequired)
        } else if (email.trim().length < 1) {
            showErrorBanner(AppStrings.emailRequired)
        } else if (!email.match(constant.emailRegex)) {
            showErrorBanner(AppStrings.validEmailAddress)
        } else if (message.trim().length < 1) {
            showErrorBanner(AppStrings.messageRequired)
        } else if (phone.trim().length >= 1 && phone.trim().length < 10) {
            showErrorBanner("please enter valid number")

        } else if (phone.trim().length == 10) {
            let checkMobile = numbersOnly(phone)
            if (checkMobile == false) {
                showErrorBanner("please enter valid number")
            } else {
                callContactUsApi()
            }
        } else {
            callContactUsApi()
        }
    }

    function numbersOnly(e) {
        return /^\d+$/.test(e.toString()) ? true : false
    }

    function clearTextFilled(filledName) {
        if (filledName == 'name') {
            nameRef.current.clear()
            setName('')
            setCrossIcon('')

        } else if (filledName == 'phone') {
            phoneRef.current.clear()
            setPhone('')
            setCrossIcon('')
        }
        else if (filledName == 'email') {
            emailRef.current.clear()
            setEmail('')
            setCrossIcon('')
        } else {
            messageRef.current.clear()
            setMessage('')
            setCrossIcon('')
        }
    }

    //-----API CALL-----//

    const callContactUsApi = () => {
        let contactUsRequest = new ContactUsRequest(name, email, phone, message)
        setIsLoading(true)
        onContactUs(contactUsRequest).then(() => {
            // showSuccessBanner("successfully submitted")
            setIsLoading(false)
            navigation.goBack()
            // nameRef.current.clear()
            // emailRef.current.clear()
            // phoneRef.current.clear()
            // messageRef.current.clear()
            // console.log("success contact");
        }).catch((error) => {
            // console.log("error contact");
        })
    }

    return (
        <View style={commonStyles.mainContainer} key={'MainContainer'}>
            {gradientBackground()}
            <WebHeader navigation={navigation} titles={["Contact Us"]} />

            <View style={commonStyles.webSubContainerStyle} key={'SubContainer'}>
                <View style={[commonStyles.webBorderViewStyle, { minWidth: 300, height: '90%' }]} key={'BorderView'}>
                    <View style={styles.itemContainer} key={'ItemContainer'}>
                        <ScrollView showsVerticalScrollIndicator={false}>
                            <Text style={styles.headerTitleStyle}>Contact Us</Text>
                            <Text style={styles.messageTextStyleWeb}>{AppStrings.contactUsMessage}</Text>
                            <View style={[styles.filedContainerStyle, { marginHorizontal: isSmallScreen ? _scale(20) : _scale(80) }]}>
                                <AppTextInput
                                    reference={nameRef}
                                    placeHolder={'Full Name'}
                                    defaultValue={name}
                                    autoFocus={true}
                                    leftIcon={<Ionicons name="person-sharp" size={24} color="white" />}
                                    clearIcon={crossIcon == 'name' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('name')} />}
                                    setValue={(text) => {
                                        text.trim().length >= 1 && focus == 'name' ? setCrossIcon('name') : setCrossIcon('')
                                        setName(text)

                                    }
                                    }
                                    blurOnSubmit={false}
                                    textInputDivider={{ marginTop: _scale(10) }}
                                    placeholderTextColor={AppColor.lightGrey}
                                    textInputStyle={{ outline: "none", }}
                                    autoComplete={true}
                                    onFocus={() => {
                                        setCrossIcon('name')
                                        setFocus('name')
                                        name.trim().length > 0 ? setCrossIcon('name') : setCrossIcon('')
                                    }
                                    }

                                />
                                <AppTextInput
                                    reference={emailRef}
                                    placeHolder={'E-mail'}
                                    defaultValue={email}
                                    autoCapitalize={'none'}
                                    textInputContainerStyle={{ marginTop: _scale(15) }}
                                    leftIcon={<MaterialIcons name="email" size={24} color="white" />}
                                    clearIcon={crossIcon == 'email' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('email')} />}
                                    setValue={(text) => {
                                        text.trim().length >= 1 && focus == 'email' ? setCrossIcon('email') : setCrossIcon('')
                                        setEmail(text)

                                    }
                                    }
                                    blurOnSubmit={false}
                                    textInputDivider={{ marginTop: _scale(10) }}
                                    placeholderTextColor={AppColor.lightGrey}
                                    textInputStyle={{ outline: "none" }}
                                    onFocus={() => {
                                        setCrossIcon('email')
                                        setFocus('email')
                                        email.trim().length > 0 ? setCrossIcon('email') : setCrossIcon('')
                                    }
                                    }
                                />
                                <AppTextInput
                                    reference={phoneRef}
                                    placeHolder={'Phone (Optional)'}
                                    defaultValue={phone}
                                    keyboardType={'numeric'}
                                    textInputContainerStyle={{ marginTop: _scale(15) }}
                                    leftIcon={<FontAwesome name="phone" size={24} color="white" />}
                                    clearIcon={crossIcon == 'phone' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('phone')} />}
                                    setValue={(text) => {
                                        text.trim().length >= 1 && focus == 'phone' ? setCrossIcon('phone') : setCrossIcon('')
                                        setPhone(text)

                                    }
                                    }
                                    blurOnSubmit={false}
                                    textInputDivider={{ marginTop: _scale(10) }}
                                    placeholderTextColor={AppColor.lightGrey}
                                    textInputStyle={{ outline: "none" }}
                                    maxLength={10}
                                    onFocus={() => {
                                        setCrossIcon('phone')
                                        setFocus('phone')
                                        phone.trim().length > 0 ? setCrossIcon('phone') : setCrossIcon('')
                                    }
                                    }
                                />
                                <AppTextInput
                                    reference={messageRef}
                                    placeHolder={'Message'}
                                    defaultValue={message}
                                    textInputContainerStyle={{ marginTop: _scale(15), borderColor: 'white', borderWidth: 1, minHeight: 100, padding: _scale(10) }}
                                    leftIcon={<MaterialCommunityIcons name="message-text" size={20} color="white" />}
                                    clearIcon={crossIcon == 'message' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('message')} />}
                                    setValue={(text) => {
                                        text.trim().length >= 1 && focus == 'message' ? setCrossIcon('message') : setCrossIcon('')
                                        setMessage(text)

                                    }
                                    }
                                    blurOnSubmit={false}
                                    textInputDivider={{ marginTop: _scale(10), borderBottomWidth: 0 }}
                                    placeholderTextColor={AppColor.lightGrey}
                                    textInputStyle={{ outline: "none", height: 80 }}
                                    multiline={true}
                                    textSubContainer={{ alignItems: 'flex-start' }}
                                    onFocus={() => {
                                        setCrossIcon('message')
                                        setFocus('message')
                                        message.trim().length > 0 ? setCrossIcon('message') : setCrossIcon('')
                                    }
                                    }
                                />
                                <WebButton onPress={checkValidation} title={'Submit'} buttonStyle={styles.buttonStyle} />
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </View>
            {isLoading && <AppLoader />}
        </View>
    )
}