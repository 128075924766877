import { View, Text } from 'react-native'
import styles from './index.style'
import { AntDesign, Octicons } from '@expo/vector-icons'
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Slider } from '@rneui/themed'
import { _scale } from '../../../../utils'
import { useEffect } from 'react';
import { TouchableOpacity } from 'react-native';
import AppFonts from '../../../../constant/fonts';


var interval;

export default function MediaController(props) {

    useEffect(() => {
        setVideoControlTimer()
    }, [])


    const setVideoControlTimer = () => {
        interval != null && clearInterval(interval)
        interval = setInterval(() => {
            props.shouldVideoControlDismiss()
        }, 3000)
    }

    function millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }

    const onClickPlayIcon = () => {
        setVideoControlTimer()
        props.onPlayClick()
    }

    return (

        <View key={'MainContainer'} style={styles.mainContainer}>
            {props.currentVideoTime != undefined &&
                <View key={'SubContainer'} style={{ flex: 1, }}>
                    <View key={'Fotter'} style={[styles.mediaControllerViewStyle, { bottom: useSafeAreaInsets().bottom, padding: _scale(5) }]}>
                        <View style={styles.timeViewStyle}>
                            <View >
                                <AntDesign name={props.isPlay ? "pausecircle" : "playcircleo"} size={35} color="white" onPress={onClickPlayIcon} />
                            </View>
                            <View style={{ flex: 1, paddingHorizontal: 10 }}>
                                <View key={'TimeController'} style={styles.timeViewStyle}>
                                    <Text style={[styles.timeTextStyle, { flex: 1 }]}>{millisToMinutesAndSeconds(props.currentVideoTime)}</Text>
                                    <Text style={styles.timeTextStyle}>{millisToMinutesAndSeconds(props.totalDuration)}</Text>
                                </View>
                                <Slider
                                    style={styles.sliderStyle}
                                    thumbStyle={styles.thumbStyle}
                                    value={((props.currentVideoTime / props.totalDuration) * 100) / 100}
                                    minimumTrackTintColor={'red'}
                                    onSlidingStart={(val) => props.onSlidingStart(val)}
                                    onValueChange={(val) => {
                                        props.onValueChange(val)
                                        setVideoControlTimer()
                                    }}
                                    onSlidingComplete={(value) => props.onSlidingComplete(value)}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            }
            <View style={[styles.backButtonContainer, { top: useSafeAreaInsets().top, left: useSafeAreaInsets().left + 20, right: useSafeAreaInsets().right }]}>
                <Octicons onPress={() => props.onBackPress()} name="arrow-left" size={30} color="white" />
                <View>
                    <Text style={{ fontFamily: AppFonts.robototMedium, color: 'white', fontSize: _scale(17) }}>{props.title}</Text>
                </View>
                <View></View>
            </View>
        </View>
    )
}