import { View, Text, KeyboardAvoidingView, ScrollView, Image, Platform, Keyboard, TouchableOpacity } from 'react-native'
import { useState, useRef } from 'react'
import { commonStyles } from '../../../utils/commonStyles'
import { LinearGradient } from 'expo-linear-gradient'
import { Ionicons, AntDesign, Entypo } from '@expo/vector-icons'
import styles from './index.style.web'
import { bottomSafeArea, showErrorBanner, showSuccessBanner, topSafeArea, _scale } from '../../../utils'
import AppTextInput from '../../../components/appTextInput'
import AppColor from '../../../constant/colors'
import WebButton from '../../../components/webButton'
import AppStrings from '../../../constant/appStrings'
import ChangePasswordRequest from '../../../models/ChangePasswordModel'
import { onChangePassword } from '../../../controllers/ChangePasswordController'
import AppLoader from '../../../components/appLoader/index.web'

export default function ChangePassword(props) {
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isOldPassVisible, setOldPassVisible] = useState(false)
    const [isNewPassVisible, setNewPassVisible] = useState(false)
    const [isConfirmPassVisible, setConfirmPassVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [crossIcon, setCrossIcon] = useState('')
    const [focus, setFocus] = useState('')
    const oldPassRef = useRef()
    const newPassRef = useRef()
    const confirmPassRef = useRef()

    const oldPassVisible = () => {
        isOldPassVisible == true ? setOldPassVisible(false) : setOldPassVisible(true)
    }

    const newPassVisible = () => {
        isNewPassVisible == true ? setNewPassVisible(false) : setNewPassVisible(true)
    }

    const confirmPassVisible = () => {
        isConfirmPassVisible == true ? setConfirmPassVisible(false) : setConfirmPassVisible(true)
    }

    const checkValidation = () => {
        if (oldPassword.trim().length < 1) {
            showErrorBanner(AppStrings.oldPassRequired)
        } else if (newPassword.trim().length < 1) {
            showErrorBanner(AppStrings.newPassRequired)
        } else if (confirmPassword.trim().length < 1) {
            showErrorBanner(AppStrings.confirmPassRequired)
        } else if (newPassword != confirmPassword) {
            showErrorBanner(AppStrings.confirmPassword)
        } else {
            callChangePasswordApi()
        }
    }

    function clearTextFilled(filledName) {
        if (filledName == 'oldPass') {
            oldPassRef.current.clear()
            setOldPassword('')
            setCrossIcon('')
        } else if (filledName == 'newPass') {
            newPassRef.current.clear()
            setNewPassword('')
            setCrossIcon('')
        }
        else {
            confirmPassRef.current.clear()
            setConfirmPassword('')
            setCrossIcon('')
        }
    }

    //-----------API CALL-----------//

    const callChangePasswordApi = () => {
        setIsLoading(true)
        Keyboard.dismiss()
        let changePasswordRequest = new ChangePasswordRequest(oldPassword, newPassword)
        onChangePassword(changePasswordRequest).then(() => {
            showSuccessBanner(AppStrings.changePasswordSuccess)
            props.navigation.goBack()
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false)
        })
    }

    return (
        <View key={'MainContainer'} style={[commonStyles.mainContainer, { borderRadius: _scale(20) }]}>
            <LinearGradient colors={['#283C54', '#000000']} style={[commonStyles.gradientBackGround, { borderRadius: 20, }]} />
            {topSafeArea()}
            <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === "ios" ? "padding" : null}>
                <TouchableOpacity onPress={() => window.location.href = window.location.origin}>
                    <Image
                        source={require('../../../assets/images/appicon.png')}
                        style={styles.logoStyle} />
                </TouchableOpacity>
                <ScrollView keyboardShouldPersistTaps={'handled'}>
                    <View key={'FiledContainer'} style={styles.filedContainerStyle}>
                        <AppTextInput
                            reference={oldPassRef}
                            placeHolder={'Old Password'}
                            defaultValue={oldPassword}
                            returnKeyLabel={'next'}
                            returnKeyType={'next'}
                            secureTextEntry={!isOldPassVisible}
                            leftIcon={<AntDesign name="lock1" size={24} color="white" />}
                            rightIcon={isOldPassVisible == true
                                ? <Ionicons name="md-eye-off" size={24} color="white" onPress={oldPassVisible} />
                                : <Ionicons name="md-eye" size={24} color="white" onPress={oldPassVisible} />}
                            clearIcon={crossIcon == 'oldPass' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('oldPass')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 && focus == 'oldPass' ? setCrossIcon('oldPass') : setCrossIcon('')
                                setOldPassword(text)
                            }
                            }
                            onSubmitEditing={() => newPassRef.current.focus()}
                            blurOnSubmit={false}
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.lightGrey}
                            textInputStyle={{ outline: 'none' }}
                            onFocus={() => {
                                setCrossIcon('oldPass')
                                setFocus('oldPass')
                                oldPassword.trim().length > 0 ? setCrossIcon('oldPass') : setCrossIcon('')
                            }
                            }
                        />

                        <AppTextInput
                            reference={newPassRef}
                            placeHolder={'New Password'}
                            defaultValue={newPassword}
                            secureTextEntry={!isNewPassVisible}
                            returnKeyLabel={'next'}
                            returnKeyType={'next'}
                            textInputContainerStyle={{ marginTop: _scale(25) }}
                            leftIcon={<AntDesign name="lock1" size={24} color="white" />}
                            rightIcon={isNewPassVisible == true
                                ? <Ionicons name="md-eye-off" size={24} color="white" onPress={newPassVisible} />
                                : <Ionicons name="md-eye" size={24} color="white" onPress={newPassVisible} />}
                            clearIcon={crossIcon == 'newPass' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('newPass')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 && focus == 'newPass' ? setCrossIcon('newPass') : setCrossIcon('')
                                setNewPassword(text)
                            }
                            }
                            onSubmitEditing={() => confirmPassRef.current.focus()}
                            blurOnSubmit={false}
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.lightGrey}
                            textInputStyle={{ outline: 'none' }}
                            onFocus={() => {
                                setCrossIcon('newPass')
                                setFocus('newPass')
                                newPassword.trim().length > 0 ? setCrossIcon('newPass') : setCrossIcon('')
                            }
                            }
                        />

                        <AppTextInput
                            reference={confirmPassRef}
                            placeHolder={'Confirm Password'}
                            defaultValue={confirmPassword}
                            secureTextEntry={!isConfirmPassVisible}
                            textInputContainerStyle={{ marginTop: _scale(25) }}
                            leftIcon={<AntDesign name="lock1" size={24} color="white" />}
                            rightIcon={isConfirmPassVisible == true
                                ? <Ionicons name="md-eye-off" size={24} color="white" onPress={confirmPassVisible} />
                                : <Ionicons name="md-eye" size={24} color="white" onPress={confirmPassVisible} />}
                            clearIcon={crossIcon == 'confirmPass' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('confirmPass')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 && focus == 'confirmPass' ? setCrossIcon('confirmPass') : setCrossIcon('')
                                setConfirmPassword(text)
                            }
                            }
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.lightGrey}
                            onSubmitEditing={() => checkValidation()}
                            textInputStyle={{ outline: "none" }}
                            onFocus={() => {
                                setCrossIcon('confirmPass')
                                setFocus('confirmPass')
                                confirmPassword.trim().length > 0 ? setCrossIcon('confirmPass') : setCrossIcon('')
                            }
                            }
                        />

                        <WebButton title={'Change Password'} onPress={checkValidation} buttonStyle={styles.buttonStyle} />

                    </View>
                </ScrollView>
                {bottomSafeArea()}
            </KeyboardAvoidingView>
            {isLoading && <AppLoader />}
        </View>
    )
}