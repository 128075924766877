import AsyncStorage from '@react-native-async-storage/async-storage'
import React, { useEffect } from 'react'
import { View, useWindowDimensions, Platform } from 'react-native'
import constant from '../../../constant'
import SignUp from '../../../screens/authScreens/signUp/index.web'
import { gradientBackground } from '../../../utils'
import { commonStyles } from '../../../utils/commonStyles'

export default function SignUpContainer({ navigation }) {

    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;

    useEffect(() => {

        if (Platform.OS == 'web') {
            AsyncStorage.getItem(constant.appKey.isVerifedUser).then(validUser => {
                if (validUser == "true") {
                    navigation.replace('WebDrawerNavigator')
                }
            });
        }
    }, [])


    return (
        <View style={commonStyles.mainContainer} key={'MainContainer'}>
            {gradientBackground()}
            <View style={commonStyles.webSubContainerStyle} key={'SubContainer'}>
                <View style={[commonStyles.webBorderViewStyle, { width: isLargeScreen ? '50%' : '90%' }]} key={'BorderView'}>
                    <View style={{ flex: 1 }}>
                        <SignUp props={navigation} />
                    </View>
                </View>
            </View>

        </View>
    )
}





