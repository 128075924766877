import { StyleSheet, Dimensions } from 'react-native'
import AppColor from '../../constant/colors';
import AppFonts from '../../constant/fonts';
import { _scale } from '../../utils'
const width = Dimensions.get('window').width - 35;

export default StyleSheet.create({
    logoStyle: {
        height: _scale(120),
        width: _scale(120),
    },
    buttonStyle: {
        borderRadius: _scale(25),
        padding: _scale(15),
        backgroundColor: AppColor.lightRed,
        marginBottom: _scale(40),
        width: '80%'
    },
    bottomContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    footerContainerStyle: {
        flexDirection: 'row',
        alignSelf: 'center',
        marginBottom: _scale(20)
    },
    signInTextStyle: {
        fontFamily: AppFonts.robotoRegular,
        color: AppColor.lightRed,
        fontSize: _scale(14)
    },
    hintTextStyle: {
        fontFamily: AppFonts.robotoRegular,
        color: AppColor.white,
        fontSize: _scale(14)
    }
})
