import React, { useState, useEffect, useRef } from 'react'
import {
    View,
    useWindowDimensions,
    StyleSheet,
    ActivityIndicator,
    TouchableWithoutFeedback,
    Text,
    Dimensions
} from 'react-native'
import * as ScreenOrientation from 'expo-screen-orientation';
import ReactPlayer from 'react-player'
import AppColor from '../../../../constant/colors';
import { updateProgress } from '../../../../controllers/progress';
import { decryptAES, findIndexOfRings, showErrorBanner, _scale, findIndexOfRingsWeb } from '../../../../utils';
import ActionableCircle from '../actionableCircle/index.web';
import SkipButton from '../../../../components/SkipButton';
import AppFonts from '../../../../constant/fonts';
import MediaController from '../mediaController';
import { Asset } from 'expo-asset';
import MyStorage from '../../../../services/storage';
import constant from '../../../../constant';



let holdForNextVideo = false
let isApiCall = false
let videoTitle = ''

export default function VideoPlayer({ navigation, route }) {

    const [videoUrl, setVideoUrl] = useState(route.params.q)
    const [decodeURL, setDecodedURL] = useState(route.params.q)
    const [showSkipButton, setShowSkipButton] = useState(false)
    const [isVideoControlShow, setVideoControlShow] = useState(true)
    const [isLoading, setLoading] = useState(true)
    const [totalDurationInSec, setTotalDurationInSec] = useState(true)
    const [isShowingRings, setShowingRings] = useState(false)
    const [currentPlayingVideoInfo, setCurrentPlayingVideoInfo] = useState(route.params.currentPlayingVideoInfo)
    const [ringSelectedByUser, setRingSelectedByUser] = useState(false)
    const [indexOfShowingRings, setIndexOfShowingRings] = useState([])
    const [playing, setPlaying] = useState(true)
    const [showingRingSelectPopup, setShowingRingSelectPopup] = useState(false)
    const [userInfoData, setUserInfoData] = useState(route.params.infoData)
    const [videoHeight, setVideoHeight] = useState(1)
    const [videoWidth, setVideoWidth] = useState(1)
    const [currentVideoTime, setCurrentVideoTime] = useState(0)
    const videoRef = useRef()
    const { height, width } = useWindowDimensions();
    const [isPortraitDevice, setPortraitDevice] = useState(true)
    const [isExpVideo, setIsExpVideo] = useState(false)
    const isLargeScreen = width >= 768;

    useEffect(() => {

        MyStorage.setItem(constant.appKey.shouldPopToRoot, '1')
        if (!isLargeScreen) {
            isPortrait()
        }
        cacheImages()

        holdForNextVideo = false
        isApiCall = false
        convertURL(route.params.q)
        videoTitle = route.params.title || ""
        setVideoHeight(height)
        setVideoWidth(width)

        getRingValue()
        window.addEventListener("orientationchange", function () {
            if (window.orientation != 0) {
                window.setTimeout(() => {
                    setVideoHeight(window.innerHeight)
                    setVideoWidth(window.innerWidth)
                }, 200)
                setPortraitDevice(true)
            } else {
                setPortraitDevice(false)
            }
        }, false);
    }, [])


    useEffect(() => {
        if (isPortraitDevice) {
            setPlaying(true)
            console.log('IspLaying');
        }
    }, [isPortraitDevice])

    function cacheImages() {

        let image = require('../../../../assets/images/ring.png')
        let newImageLog = Asset.fromModule(image).downloadAsync();
        console.log('imagecah', newImageLog);
    }

    const getRingShowingTime = () => {

        if (currentPlayingVideoInfo != undefined) {
            let id = currentPlayingVideoInfo.id
            if (id == 19 || id == 22 || id == 23 || id == 24 || id == 29 || id == 30
                || id == 33 || id == 34) {
                return 36
            }
            if (id == 20 || id == 31) {
                return 22
            } if (id == 40 || id == 42) {
                return 24
            }
            if (id == 21 || id == 32 || id == 39 || id == 41 || id == 43 || id == 44) {
                return 37
            }
            return 0
        } else {
            return 0
        }

    }

    const handleProgress = status => {

        // console.log('status', status);
        setCurrentVideoTime(status.playedSeconds)
        if (userInfoData) {
            let currentTime = status.playedSeconds
            console.log('_onPlaybackStatusUpdate EXP>>>>> >>>>>>>>>>>> ', isExpVideo);
            console.log('_onPlaybackStatusUpdate currentPlayingVideoInfo >>>>>>>>>>>> ', currentPlayingVideoInfo);

            //  Showing Ring for Question Answer videos
            let ringShowingTime = getRingShowingTime()
            if (ringShowingTime == 0) {
                ringShowingTime = totalDurationInSec - 12  // 12 sec before
            }

            if (currentTime >= ringShowingTime) {
                console.log('Inside Ring show>>>>>>>>>>> ', currentTime);
                if (currentPlayingVideoInfo != undefined) {
                    let videoInfo = currentPlayingVideoInfo
                    if (videoInfo.videoType == "qa_video" && !holdForNextVideo) {
                        setShowingRings(true)
                    }
                }
            }

            // For Expreience Video
            if (isExpVideo || currentPlayingVideoInfo == undefined) {
                console.log('currentTime >>>>>>>>>>> ', currentTime);
                if (currentTime >= 540 && !isApiCall) {
                    isApiCall = true
                    updateProgress(userInfoData).then((userModel) => {
                        let temp = userInfoData
                        temp.userModel = userModel
                        if (route.params.updateUserModel != undefined) {
                            route.params.updateUserModel(userModel)
                        }
                        setUserInfoData(temp)
                    })
                }
            }
            let extraData = userInfoData
            let currentLevel = extraData.userModel.currentLevel
            if (route.params.currentIndex == currentLevel - 1) {
                let apiValue = ringShowingTime - 1
                if (currentTime >= apiValue && !isApiCall) {
                    isApiCall = true
                    updateProgress(userInfoData).then((userModel) => {
                        let temp = userInfoData
                        temp.userModel = userModel
                        if (route.params.updateUserModel != undefined) {
                            route.params.updateUserModel(userModel)
                        }
                        setUserInfoData(temp)
                    })
                }
            } else {
                // console.log('Playing Old Video');
            }

            if (currentTime >= totalDurationInSec && !holdForNextVideo) {
                console.log("currentTime ---------", currentTime);
                console.log("totalDurationInSec >>>>>>>>>", totalDurationInSec);

                if (currentPlayingVideoInfo != undefined && !ringSelectedByUser) {
                    console.log("showingRingSelectPopup >>>>>>>>>", showingRingSelectPopup);
                    if (currentPlayingVideoInfo.videoType == 'qa_video') {
                        setPlaying(false)
                        setShowingRingSelectPopup(true)
                        showingWarningAlertForRingSelect()
                    } else {
                        prepareForNextVideo()
                    }
                    // if (currentPlayingVideoInfo.videoType == 'qa_video' && !showingRingSelectPopup) {
                    //     setPlaying(false)
                    //     setShowingRingSelectPopup(true)
                    //     showingWarningAlertForRingSelect()
                    // } else {
                    //     console.log("prepareForNextVideo 1");
                    //     prepareForNextVideo()
                    // }
                } else {
                    console.log("prepareForNextVideo 2");
                    prepareForNextVideo()
                }
            }
        }
    }

    function prepareForNextVideo() {
        holdForNextVideo = true
        setPlaying(false)
        setShowingRings(false)
        didEndVideo()
    }

    function convertURL(encryptedBase64) {

        setDecodedURL(encryptedBase64)
        if (userInfoData) {
            let decryptedURL = decryptAES(encryptedBase64, userInfoData.userModel.key)
            setVideoUrl(decryptedURL)
        } else {
            setVideoUrl(encryptedBase64)
        }
    }

    function didEndVideo() {
        // console.log('End Video', holdForNextVideo);

        if (userInfoData && route.params.currentIndex != undefined) {
            let extraData = userInfoData

            let currentLevel = extraData.userModel.currentLevel - 1

            if (route.params.isCurrentSequence < extraData.userModel.currentTier) {

                let tierVideoModel = extraData.tierVideoModel
                let currentAttunement = tierVideoModel.findIndex(o => o.videoUrl == decodeURL);
                // console.log('currentAttunement', currentAttunement);
                if (currentAttunement >= 0) {
                    setShowSkipButton(false)
                    if (currentAttunement < (extraData.tierVideoModel.length - 1)) {
                        let videoModel = extraData.tierVideoModel[currentAttunement + 1]
                        videoTitle = videoModel.title
                        setIsExpVideo(false)
                        setCurrentPlayingVideoInfo(videoModel)
                        convertURL(videoModel.videoUrl)
                    } else {
                        setIsExpVideo(true)
                        setCurrentPlayingVideoInfo(undefined)
                        // console.log('this is exp video');
                        let expVideoUrl = extraData.levelModel[route.params.currentIndex].videoUrl
                        videoTitle = extraData.levelModel[route.params.currentIndex].title
                        convertURL(expVideoUrl)
                    }
                    getRingValue()
                    setPlaying(true)
                } else {
                    // console.log('this was last video');
                    showErrorBanner('Congratulations on completing your current day. Your next experience will unlock tomorrow.')

                }
                return
            }



            if (route.params.currentIndex <= currentLevel) {
                let tierVideoModel = extraData.tierVideoModel
                let currentAttunement = tierVideoModel.findIndex(o => o.videoUrl == decodeURL);
                // console.log('currentAttunement', currentAttunement);
                if (currentAttunement >= 0) {
                    setShowSkipButton(false)
                    if (currentAttunement < (extraData.tierVideoModel.length - 1)) {
                        let videoModel = extraData.tierVideoModel[currentAttunement + 1]
                        videoTitle = videoModel.title
                        setIsExpVideo(false)
                        setCurrentPlayingVideoInfo(videoModel)
                        convertURL(videoModel.videoUrl)
                    } else {
                        setIsExpVideo(true)
                        setCurrentPlayingVideoInfo(undefined)
                        // console.log('this is exp video');
                        let expVideoUrl = extraData.levelModel[route.params.currentIndex].videoUrl
                        videoTitle = extraData.levelModel[route.params.currentIndex].title
                        convertURL(expVideoUrl)
                    }
                    getRingValue()
                    setPlaying(true)
                } else {
                    // console.log('this was last video');
                    showErrorBanner('Congratulations on completing your current day. Your next experience will unlock tomorrow.')

                }
            } else {
                // console.log('Current Playing');
                showErrorBanner('Congratulations on completing your current tier. Go back to The Way 126 to access your next tier.')
            }
        }
    }

    const showingWarningAlertForRingSelect = () => {
        alert(
            "Please pick an option \nTo advance to the next video, make a selection.");
    }

    const handleDuration = (duration) => {
        setTotalDurationInSec(duration)
    }

    const getRingValue = () => {
        let ringsIndex = findIndexOfRingsWeb()
        //let ringsIndex = [1, 6, 7]
        console.log('ringsIndex', ringsIndex);
        setIndexOfShowingRings(ringsIndex)
    }

    const onPressRing = () => {
        setRingSelectedByUser(true)
        setShowingRings(false)
        setPlaying(true)
        console.log("prepareForNextVideo 3");
        prepareForNextVideo()

    }

    function prepareForNextVideo() {
        setPlaying(false)
        holdForNextVideo = true
        setShowingRings(false)
        didEndVideo()
    }

    const handleReady = () => {
        checkVideoShouldSkip()
    }

    const onPlayClick = () => {
        if (playing) {
            setPlaying(false)
        } else {
            setPlaying(true)
        }
    }

    function clickSkipButton() {
        setPlaying(false)
        setShowSkipButton(false)
        setLoading(true)

        if (userInfoData && route.params.currentIndex != undefined) {
            let extraData = userInfoData
            let currentLevel = extraData.userModel.currentLevel - 1
            if (route.params.currentIndex == currentLevel) {
                updateProgress(userInfoData).then((userModel) => {
                    let temp = userInfoData
                    temp.userModel = userModel
                    if (route.params.updateUserModel != undefined) {
                        route.params.updateUserModel(userModel)
                    }
                    setUserInfoData(temp)
                })
            }
        }
        prepareForNextVideo()
    }

    function checkVideoShouldSkip() {
        if (userInfoData && currentPlayingVideoInfo != undefined) {
            let videoInfo = currentPlayingVideoInfo
            if (videoInfo.videoType == "welcome") {
                let extraData = userInfoData

                let currentLevel = extraData.userModel.currentLevel - 1

                if ((route.params.isCurrentSequence < extraData.userModel.currentTier || route.params.currentIndex < currentLevel) || extraData.userModel.currentExperienceDay > 0) {
                    setShowSkipButton(true)
                }
                // if (extraData.userModel.currentExperienceDay > 0) {

                //     // console.log('Showing Skipped');
                //     setShowSkipButton(true)
                // }
            }
        }
    }

    const changeVideoControlState = () => {
        if (isVideoControlShow == true) {
            setVideoControlShow(false)
        } else {
            setVideoControlShow(true)
        }
    }

    const onSlidingComplete = (value) => {
        let skipPosition = (totalDurationInSec * (value))
        videoRef.current.seekTo(skipPosition);
        setPlaying(true)
    }

    const onValueChange = (val) => {
        setCurrentVideoTime(totalDurationInSec * val)
    }

    const onSlidingStart = (val) => {
        setPlaying(false)
    }
    const backPress = () => {
        navigation.goBack()
    }

    function isPortrait() {
        setPortraitDevice(window.orientation != 0)
    }

    return (

        <View style={{ flex: 1, backgroundColor: 'black' }}>
            {isPortraitDevice &&
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', }}>
                    <View style={{}}>
                        <ReactPlayer
                            ref={videoRef}
                            url={videoUrl}
                            playing={playing}
                            width={'auto'}
                            height={videoHeight}
                            style={{ backgroundColor: 'black' }}
                            onProgress={handleProgress}
                            onDuration={handleDuration}
                            onReady={handleReady}
                            playsinline={true}
                            onStart={() => {
                                holdForNextVideo = false
                                isApiCall = false
                                setRingSelectedByUser(false)
                                setShowingRingSelectPopup(false)
                                setLoading(false)
                            }}
                            onPlay={() => {
                                setLoading(false)
                                setVideoControlShow(true)
                            }}
                        />

                        {(isShowingRings && !ringSelectedByUser) &&
                            <View style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, }}>
                                <ActionableCircle
                                    enableRings={indexOfShowingRings}
                                    onPressRing={onPressRing}
                                    ringSize={130}
                                    currentPlayingVideoInfo={currentPlayingVideoInfo}
                                />
                            </View>
                        }
                    </View>

                    {!isShowingRings &&
                        <View key={'VideoClick'} style={{ flex: 1, position: 'absolute', top: 80, right: 0, left: 0, bottom: 50, }}>
                            <TouchableWithoutFeedback style={{ flex: 1 }} onPress={changeVideoControlState}>
                                <View style={{ flex: 1 }}></View>
                            </TouchableWithoutFeedback>
                        </View>
                    }

                    {showSkipButton &&
                        <SkipButton onClickSkipButton={clickSkipButton} />
                    }

                    {!isShowingRings && isVideoControlShow &&
                        <View style={[styles.mediaControllerViewStyle, { bottom: 5, padding: 5, }]}>
                            <MediaController
                                currentVideoTime={currentVideoTime * 1000}
                                totalDuration={totalDurationInSec * 1000}
                                isPlay={playing}
                                onPlayClick={onPlayClick}
                                onSlidingComplete={onSlidingComplete}
                                onValueChange={onValueChange}
                                onSlidingStart={onSlidingStart}
                                onBackPress={backPress}
                                shouldVideoControlDismiss={changeVideoControlState}
                                title={videoTitle}
                            />
                        </View>
                    }
                    {isLoading &&
                        <ActivityIndicator size={40} color={AppColor.white} style={styles.loaderStyle} />
                    }
                </View>
            }

            {!isPortraitDevice &&
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ color: 'white' }}>Please change orientation</Text>
                </View>
            }
        </ View >
    )
}


const styles = StyleSheet.create({
    videoStyle: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        flex: 1,
        backgroundColor: 'black'
    },
    loaderStyle: {
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,

    },

    mediaControllerViewStyle: {
        flex: 1,
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        top: 0
    },

    timeViewStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1
    },
    timeTextStyle: {
        color: 'white',
        fontFamily: AppFonts.robotoRegular,
    },
    sliderStyle: {
        height: _scale(10),

    },
    thumbStyle: {
        width: _scale(15),
        height: _scale(15)
    },
    playIconStyle: {
    }
})