import { StyleSheet, Dimensions } from 'react-native'
import AppColor from '../../../constant/colors'
import AppFonts from '../../../constant/fonts'
import { _scale } from '../../../utils'

export default StyleSheet.create({
    backIconStyle: {
        paddingVertical: _scale(15),
        position: 'absolute',
        paddingHorizontal: _scale(10),
    },
    logoStyle: {
        height: _scale(120),
        width: _scale(120),
        alignSelf: 'center',
        marginTop: _scale(15)
    },
    headingText: {
        fontFamily: AppFonts.robototMedium,
        color: AppColor.white,
        textAlign: 'center',
        marginTop: _scale(15),
        fontSize: _scale(18)
    },
    filedContainerStyle: {
        marginTop: _scale(20),
        marginHorizontal: _scale(80)
    },
    buttonStyle: {
        borderRadius: _scale(25),
        marginTop: _scale(30),
        padding: _scale(15),
        backgroundColor: AppColor.lightRed,
    },
})