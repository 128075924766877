import { View, StyleSheet, Image, KeyboardAvoidingView, Platform, ScrollView, Text, TouchableOpacity } from 'react-native'
import { useEffect, useState, useRef } from 'react'
import { bottomSafeArea, showErrorBanner, showSuccessBanner, topSafeArea, _scale } from '../../../utils'
import { LinearGradient } from 'expo-linear-gradient'
import { commonStyles } from '../../../utils/commonStyles'
import AppTextInput from '../../../components/appTextInput'
import { Ionicons, MaterialIcons, Entypo } from '@expo/vector-icons'
import AppColor from '../../../constant/colors'
import AsyncStorage from '@react-native-async-storage/async-storage'
import constant from '../../../constant'
import AppFonts from '../../../constant/fonts'
import AppStrings from '../../../constant/appStrings'
import { cancelSubscriptionAPI, getProfile } from '../../../controllers/GetProfileController'
import AppLoader from '../../../components/appLoader/index.web'
import UpdateProfileRequest from '../../../models/UpdateProfileModel'
import { onUpdateProile } from '../../../controllers/UpdateProfileController'
import WebButton from '../../../components/webButton'

let moment = require('moment')

export default function MyAccount(props) {

    const [email, setEmail] = useState('')
    const [subscriptionInfo, setSubscriptionInfo] = useState(undefined)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [isFirstNameClear, setFirstNameClear] = useState(false)
    const [isLastNameClear, setLastNameClear] = useState(false)
    const [isEmailClear, setEmailClear] = useState(false)
    const [crossIcon, setCrossIcon] = useState('')

    const [focus, setFocus] = useState('')
    const [isShimmerView, setShimmerView] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isPromo, setPromo] = useState(false)
    const [promoExp, setPromoExp] = useState()
    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const emailRef = useRef()


    useEffect(() => {
        callGetProfileApi()

    }, [])


    function callGetProfileApi() {
        setIsLoading(true)
        getProfile().then((profileModel) => {
            // console.log('get profile data is', profileModel.subscriptionModel)
            setSubscriptionInfo(profileModel.subscriptionModel)
            let userModel = profileModel.userModel
            let fullName = userModel.name

            let firstName = fullName.split(' ').slice(0, -1).join(' ');
            let lastName = fullName.split(' ').slice(-1).join(' ');
            setFirstName(firstName)
            setLastName(lastName)
            setEmail(userModel.email)
            setPromo(userModel.isPromo)
            setPromoExp(userModel.promoExpiresAt)
            setIsLoading(false)
        }).catch((error) => {
            setShimmerView(false)
        })
    }
    const checkValidation = () => {

        // console.log(firstName.trim());
        if (firstName.trim().length < 1) {
            showSuccessBanner(AppStrings.firstNameRequired)
        } else if (lastName.trim().length < 1) {
            showErrorBanner(AppStrings.lastNameRequired)
        } else if (email.trim().length < 1) {
            showErrorBanner(AppStrings.emailRequired)
        } else if (!email.match(constant.emailRegex)) {
            showErrorBanner(AppStrings.validEmailAddress)
        } else {
            callUpdateProfileApi()
        }
    }

    function callUpdateProfileApi() {
        setIsLoading(true)
        let updateProfileRequest = new UpdateProfileRequest(email, firstName, lastName)
        onUpdateProile(updateProfileRequest).then(() => {
            showSuccessBanner(AppStrings.profileUpdated)
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false)
        })
    }

    function subApi() {
        setIsLoading(true)
        cancelSubscriptionAPI().then((abc) => {
            callGetProfileApi()
        }).catch((error) => {
            setIsLoading(false)
        })
    }

    function cancelSubscription() {

        if (subscriptionInfo.deviceType == 'ios' || subscriptionInfo.deviceType == 'android') {
            alert('You can cancel this subscription from original transaction mode')
            return
        }

        if (confirm("We are sorry that you want to cancel your subscription. Click on OK if you really want to cancel your subscription.") == true) {
            subApi()
        }
    }

    const navigateToOtherScreen = () => {
        props.navigation.navigate(AppStrings.changePasswordContainer)
    }

    function clearTextFilled(filledName) {
        if (filledName == 'firstName') {
            firstNameRef.current.clear()
            setFirstName('')
            setCrossIcon('')

        } else if (filledName == 'lastName') {
            lastNameRef.current.clear()
            setLastName('')
            setCrossIcon('')
        }
        else if (filledName == 'email') {
            emailRef.current.clear()
            setEmail('')
            setCrossIcon('')
        }
    }

    const showPaymentInfo = () => {

        let createdDate = moment(subscriptionInfo.expiresDateTime).format('MM/DD/YYYY')

        if ((subscriptionInfo.userModel.transactionStatus || "") == 'active') {
            return `Your current subscription is active till ${createdDate}  and will be charged monthly.`
        }
        if ((subscriptionInfo.userModel.transactionStatus || "") == 'canceled') {
            return `Your current subscription is valid till ${createdDate}. After that, you will not be able to access the Way videos.`
        }
        return 'Your current transaction in under progress.'
    }

    return (
        <View key={'MainContainer'} style={[commonStyles.mainContainer, { borderRadius: _scale(20) }]}>
            <LinearGradient colors={['#283C54', '#000000']} style={[commonStyles.gradientBackGround, { borderRadius: 20, }]} />
            {topSafeArea()}
            <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === "ios" ? "padding" : null}>
                <Image
                    source={require('../../../assets/images/appicon.png')}
                    style={styles.logoStyle} />
                <ScrollView keyboardShouldPersistTaps={'handled'}>
                    <View key={'FiledContainer'} style={styles.filedContainerStyle}>
                        <AppTextInput
                            reference={firstNameRef}
                            placeHolder={'First Name'}
                            defaultValue={firstName}
                            returnKeyLabel={'next'}
                            returnKeyType={'next'}
                            leftIcon={<Ionicons name="person-sharp" size={24} color="white" />}
                            clearIcon={crossIcon == 'firstName' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('firstName')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 && focus == 'firstName' ? setCrossIcon('firstName') : setCrossIcon('')
                                setFirstName(text)

                            }
                            }
                            onSubmitEditing={() => lastNameRef.current.focus()}
                            blurOnSubmit={false}
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.lightGrey}
                            textInputStyle={{ outline: 'none' }}
                            selectTextOnFocus={false}
                            onFocus={() => {
                                setCrossIcon('firstName')
                                setFocus('firstName')
                                firstName.trim().length > 0 ? setCrossIcon('firstName') : setCrossIcon('')
                            }
                            }
                        />

                        <AppTextInput
                            reference={lastNameRef}
                            placeHolder={'Last Name'}
                            defaultValue={lastName}
                            returnKeyLabel={'next'}
                            returnKeyType={'next'}
                            textInputContainerStyle={{ marginTop: _scale(25) }}
                            leftIcon={<Ionicons name="person-sharp" size={24} color="white" />}
                            clearIcon={crossIcon == 'lastName' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('lastName')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 && focus == 'lastName' ? setCrossIcon('lastName') : setCrossIcon('')
                                setLastName(text)

                            }
                            }
                            onSubmitEditing={() => emailRef.current.focus()}
                            blurOnSubmit={false}
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.lightGrey}
                            textInputStyle={{ outline: 'none' }}
                            onFocus={() => {
                                setCrossIcon('lastName')
                                setFocus('lastName')
                                lastName.trim().length > 0 ? setCrossIcon('lastName') : setCrossIcon('')
                            }
                            }
                        />

                        <AppTextInput
                            reference={emailRef}
                            placeHolder={'E-mail'}
                            defaultValue={email}
                            returnKeyLabel={'next'}
                            returnKeyType={'next'}
                            keyboardType={'email-address'}
                            autoCapitalize={'none'}
                            textInputContainerStyle={{ marginTop: _scale(25) }}
                            leftIcon={<MaterialIcons name="email" size={24} color="white" />}
                            clearIcon={crossIcon == 'email' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('email')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 && focus == 'email' ? setCrossIcon('email') : setCrossIcon('')
                                setEmail(text)

                            }
                            }
                            onSubmitEditing={() => passwordRef.current.focus()}
                            blurOnSubmit={false}
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.lightGrey}
                            textInputStyle={{ outline: 'none' }}
                            onFocus={() => {
                                setCrossIcon('email')
                                setFocus('email')
                                email.trim().length > 0 ? setCrossIcon('email') : setCrossIcon('')
                            }
                            }
                        />

                        {isPromo == 1 &&
                            <Text style={{ marginTop: 20, fontFamily: AppFonts.robotoBold, color: 'white', fontSize: _scale(12) }}>Your trial period expires on {String(promoExp).slice(0, 10)} </Text>

                        }


                        {subscriptionInfo != undefined &&
                            <View style={{ marginTop: 20, }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Text style={{ fontFamily: AppFonts.robotoRegular, color: 'white', fontSize: _scale(16) }}>Subscription Status:</Text>
                                    {subscriptionInfo != undefined &&
                                        <View style={{ backgroundColor: (subscriptionInfo.userModel.transactionStatus || "") == 'active' ? 'green' : 'red', padding: 5, borderRadius: 5 }}>
                                            <Text style={{ fontFamily: AppFonts.robotoBold, color: 'white', fontSize: _scale(12) }}>{String(subscriptionInfo.userModel.transactionStatus || "").toUpperCase()}</Text>
                                        </View>
                                    }
                                </View>
                                <Text style={{ marginTop: 10, fontFamily: AppFonts.robotoRegular, color: 'white', fontSize: _scale(12) }}>
                                    {subscriptionInfo.expiresDateTime != undefined &&
                                        showPaymentInfo()
                                    }
                                </Text>

                                {subscriptionInfo != undefined && (subscriptionInfo.userModel.transactionStatus || "") == 'active' &&
                                    <Text onPress={() => cancelSubscription()} style={{ marginTop: 10, alignSelf: 'flex-end', fontFamily: AppFonts.robotoRegular, color: AppColor.red, fontSize: _scale(13), textDecorationLine: 'underline' }}>Cancel Subscription</Text>
                                }
                            </View>

                        }

                        <WebButton title="Edit Profile" buttonStyle={styles.buttonStyle} onPress={checkValidation} />


                        <TouchableOpacity onPress={navigateToOtherScreen} style={styles.changePassContainer}>
                            <Text style={styles.changePassText}>{AppStrings.changePasswordText}</Text>
                        </TouchableOpacity>
                    </View>
                </ScrollView>
                {isLoading && <AppLoader />}

                {bottomSafeArea()}
            </KeyboardAvoidingView>
        </View>
    )
}

const styles = StyleSheet.create({
    logoStyle: {
        height: _scale(120),
        width: _scale(120),
        alignSelf: 'center',
        marginTop: _scale(15)
    },
    filedContainerStyle: {
        marginTop: _scale(50),
        marginHorizontal: _scale(35)
    },
    changePassContainer: {
        alignSelf: 'flex-end',
        paddingVertical: _scale(15)
    },
    changePassText: {
        color: AppColor.lightRed,
        fontFamily: AppFonts.robotoBold
    },
    buttonStyle: {
        borderRadius: _scale(25),
        marginTop: _scale(10),
        padding: _scale(15),
        backgroundColor: AppColor.lightRed,
    },

})