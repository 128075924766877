
import { SafeAreaView, StyleSheet, View, FlatList, Text, useWindowDimensions, ScrollView } from 'react-native';
import React, { useState } from 'react';
import AppVideoPreview from '../../components/appVideoPreview';
import { FreeVideosModel } from '../../models/FreeVideos';
import Constant from '../../constant';
import AppStrings from '../../constant/appStrings';
import MyStorage from '../../services/storage';
import constant from '../../constant';
import ProgressModel from '../../models/ProgressModel';
import { convertObjectToString } from '../../utils';

const freeContent = new FreeVideosModel().getFreeVideos()

export default function VideoPreview(props) {


    //console.log('>>> props.screen', props.screen);

    const { height, width } = useWindowDimensions();
    let isLandscape = 0
    // console.log('width', width);
    if (width <= 500) {
        isLandscape = '100%'
    } else if (501 < width && width <= 1100) {
        isLandscape = '48%'
    } else if (1100 < width && width <= 1400) {
        isLandscape = '33%'
    } else {
        isLandscape = '24%'
    }

    const onClickCell = (item) => {
        console.log('onClickCell web =====>> ', item.type);
        console.log('onClickCell web =====>> ', props.screen);
        if ((props.screen == Constant.ScreensName.journey) && (item.type == 'trophy')) {
            if (item.type) {
                props.navigation.navigate('VideoPlayer', { q: item.encrypted_url, infoData: props.extraInfo, title: item.title })
            } else {
                //   props.navigation.navigate(AppStrings.webTierDetails, { tierId: item.id, isComingFromPage: props.screen, sequence: item.sequence })
            }
        } else if ((props.screen == Constant.ScreensName.theWay)) {
            if (item.type) {
                props.navigation.navigate('VideoPlayer', { q: item.videoUrl, infoData: props.extraInfo, title: item.title })
            } else {
                props.navigation.navigate(AppStrings.webTierDetails, { tierId: item.id, isComingFromPage: props.screen, sequence: item.sequence })
            }
        } else if (props.screen == Constant.ScreensName.expansion) {
            if (item.isPurchased == '1') {
                props.navigation.navigate('VideoPlayer', { q: item.videoUrl, infoData: props.extraInfo, title: item.title })
            } else {
                if (item.isLive == '1')
                    props.navigation.navigate(AppStrings.expansionDetailWebScreen, { itemDetail: item })
            }
        } else if (props.screen == Constant.ScreensName.level || (props.screen == Constant.ScreensName.journey)) {

            if (item.type) {
                props.navigation.navigate('VideoPlayer', { q: item.videoUrl, infoData: props.extraInfo, title: item.title })
            } else {

                if (props.screen == Constant.ScreensName.journey && props.onClickMyWayLevel != undefined) {
                    props.onClickMyWayLevel(item)
                } else {
                    let currentLevel = props.extraInfo.userModel.currentLevel - 1
                    let index = props.extraInfo.levelModel.indexOf(item)
                    if (index > -1) {

                        console.log('props.sequence', props.sequence);
                        let playVideo = false
                        if (MyStorage.getItem(constant.paymentType.subscription) == '1') {
                            if (props.sequence < props.extraInfo.userModel.currentTier) {
                                playVideo = true
                            } else {
                                if (props.sequence == props.extraInfo.userModel.currentTier && index <= (currentLevel)) {
                                    playVideo = true
                                }
                            }
                        }


                        // let isPlay = MyStorage.getItem(constant.paymentType.subscription) == '1' && (props.sequence <= props.extraInfo.userModel.currentTier && index <= (currentLevel))
                        props.navigation.navigate(AppStrings.videoDetailsView, { videoInfo: convertObjectToString(item), infoData: convertObjectToString(props.extraInfo), isComingFromPage: props.parentScreen, isVideoPlayable: playVideo, isCurrentSequence: props.sequence })
                    }
                }
            }
        } else {
            props.navigation.navigate(AppStrings.videoDetailsView, { videoInfo: convertObjectToString(item) })

        }
    }

    const renderItem = (item, index) => {

        let isCellDisabled = false
        let selectedCount = 0
        let numberOfDotsShowing = 0
        if (props.userModel != undefined) {
            if (props.screen == Constant.ScreensName.level) {

                let currentLevel = props.userModel.currentLevel
                if (props.sequence == props.userModel.currentTier) {
                    if (index == (currentLevel - 1)) {
                        selectedCount = props.userModel.currentExperienceDay
                    } else {
                        if (index < currentLevel) {
                            selectedCount = 7
                        }
                    }
                } else {
                    if (props.sequence < props.userModel.currentTier) {
                        selectedCount = 7
                    }
                }

                isCellDisabled = MyStorage.getItem(constant.paymentType.subscription) == '0' || (index >= (currentLevel))
                numberOfDotsShowing = 7

                if (props.parentScreen == Constant.ScreensName.journey) {
                    numberOfDotsShowing = 0
                }

            } else if (props.screen == Constant.ScreensName.journey) {
                numberOfDotsShowing = undefined
                if (item.type == undefined) {
                    isCellDisabled = MyStorage.getItem(constant.paymentType.subscription) == '0'
                }
            } else {
                let currentTier = props.userModel.currentTier
                let currentLevel = props.userModel.currentLevel
                isCellDisabled = MyStorage.getItem(constant.paymentType.subscription) == '0' || (index >= (props.userModel.currentLevel))
                numberOfDotsShowing = 9
                if (index == currentTier - 1) {
                    selectedCount = currentLevel - 1
                } else {
                    if (index < currentTier) {
                        selectedCount = 9
                    } else {
                        selectedCount = 0
                    }
                }
            }
        }
        return (
            <View key={index} style={{ width: isLandscape, margin: 1, }}>
                <AppVideoPreview
                    item={item}
                    playIcon={props.screen == Constant.ScreensName.expansion ? false : true}
                    onPressCell={onClickCell}
                    numberOfDots={numberOfDotsShowing}
                    selectedCount={selectedCount}
                    // disabled={isCellDisabled}
                    screenName={props.screen}
                    isTheWay={props.isTheWay}
                    isExperienceCount={props.screen == Constant.ScreensName.theWay}
                    isPurchased={item.isPurchased != undefined && item.isPurchased == '1'}
                />
            </View>
        )
    }

    const renderList = () => {

        let dataArray = props.screen == Constant.ScreensName.home ? freeContent : props.videoData
        if (dataArray == undefined) return null;
        return dataArray.map((item, index) => {
            return renderItem(item, index)
        });
    }

    return (
        <View style={styles.container}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
                    {renderList()}
                </View>
            </ScrollView>
        </View>
    );

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        padding: 0,
    },
    imageThumbnail: {
        justifyContent: 'center',
        alignItems: 'center',
        aspectRatio: 1,

    },
});