import constant from "../../constant"
import MyStorage from "../../services/storage"

export default class UserModel {
    constructor(
        id = '',
        name = '',
        email = '',
        title = '',
        currentTier = 0,
        currentLevel = 0,
        currentExperienceDay = 0,
        currentAttunement = 0,
        createdAt = "",
        subscription = "",
        transactionStatus,
        key = "",
        isPromo = 0,
        promoExpiresAt
    ) {
        this.id = id
        this.name = name
        this.email = email
        this.title = title,
            this.currentTier = currentTier
        this.currentLevel = currentLevel
        this.currentExperienceDay = currentExperienceDay
        this.currentAttunement = currentAttunement
        this.createdAt = createdAt
        this.subscription = subscription
        this.isPromo = isPromo
        this.key = key
        this.promoExpiresAt = promoExpiresAt
        this.transactionStatus = transactionStatus

    }

    initWithData(res) {
        this.id = res["id"] || ""
        this.name = res["name"] || ""
        this.email = res["email"] || ""
        this.title = res["title"] || ""
        this.currentTier = res["current_tier"] || 0
        this.currentLevel = res["current_level"] || 0
        this.currentExperienceDay = res["current_experience_day"] || 0
        this.currentAttunement = res["current_atttunement"] || 0
        this.createdAt = res["created_at"] || 0
        this.subscription = res["subscription"] || 0
        this.key = res["key"] || ""
        this.isPromo = res["is_promo"] || ""
        this.promoExpiresAt = res["promo_expires_at"] || ""
        this.transactionStatus = res["transaction_status"] || ""



        MyStorage.setItem(constant.appKey.kDecodeKey, this.key)

        if (this.isPromo == 1) {
            MyStorage.setItem(constant.paymentType.subscription, String(1))
        } else {
            console.log(String(this.subscription));
            MyStorage.setItem(constant.paymentType.subscription, String(this.subscription))
        }
        return this
    }

}
