import ApiManager from '../../services/apiManager';
import Constant from '../../constant';
import LevelResponseModel from '../../models/LevelResponseModel';
var apiManager = ApiManager.getInstance();
let getLevelEndPoint = Constant.apiEndPoints.getLevel;


export const getLevel = async (tierId, tierObj) => new Promise((resolve, reject) => {

    apiManager.onGetApi(getLevelEndPoint + tierId).then((responseData) => {

        let modelObj = new LevelResponseModel().initWithRes(responseData.json["data"])

        if (modelObj.levelModel.length > 0) {
            let temp = [...modelObj.levelModel]
            if (tierObj != undefined) {
                temp = temp.map((v => ({ ...v, 'tierSequence': tierObj.sequence })))
            }
            modelObj.levelModel = temp
        }
        resolve(modelObj)

    }).catch((error) => {
        reject(error)
    })
})
