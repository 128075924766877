import { View, Text, TouchableOpacity, Image, Alert } from 'react-native'
import { _scale } from '../../utils'
import { commonStyles } from '../../utils/commonStyles'
import styles from './index.style'
import { Menu, MenuOption, MenuOptions, MenuTrigger } from 'react-native-popup-menu'
import AppString from '../../constant/appStrings'
import { Ionicons } from '@expo/vector-icons'
import AppColor from '../../constant/colors'
import constant from '../../constant'

export default function AppHeader(props) {

    const renderMenuOptions = () => {

        const logoutPopup = () => {
            Alert.alert(
                constant.AppInfo.name,
                "Are you sure you want to log out?",
                [
                    {
                        text: "Cancel",
                        onPress: () => console.log("Cancel Pressed"),
                        style: "cancel"
                    },
                    { text: "Logout", onPress: () => props.FourthMenuClick() }
                ]
            );
        }

        const openLink = (url, title) => {
            if (props.navigation != undefined) {
                props.navigation.navigate(AppString.webPage, { paymentUrl: url, title: title })
            }
        }

        return (
            <View
                style={[styles.menuContainerStyle, { ...props.menuContainerStyle }]}
                key={'MenuContainer'}>
                <Menu>
                    <MenuTrigger style={{ paddingHorizontal: _scale(15) }}>
                        <Image source={require('../../assets/images/menu.png')}
                            style={styles.menuIconStyle} />
                    </MenuTrigger>
                    <MenuOptions
                        optionsContainerStyle={[styles.menuOptionContainerStyle, { ...props.menuOptionContainerStyle }]}>
                        <MenuOption
                            onSelect={props.FirstMenuClick} >
                            <Text style={[styles.menuTitleStyle, { ...props.menuTitleStyle }]}>{props.FirstMenuTitle}</Text>
                        </MenuOption>
                        <MenuOption
                            onSelect={() => openLink(constant.URL.EULA, props.EULA)} >
                            <Text style={[styles.menuTitleStyle, { ...props.menuTitleStyle }]}>{props.EULA}</Text>
                        </MenuOption>
                        <MenuOption
                            onSelect={() => openLink(constant.URL.TnC, props.SecondMenuTitle)} >
                            <Text style={[styles.menuTitleStyle, { ...props.menuTitleStyle }]}>{props.SecondMenuTitle}</Text>
                        </MenuOption>
                        <MenuOption
                            onSelect={() => openLink(constant.URL.PrivacyPolicy, props.ThirdMenuTitle)} >
                            <Text style={[styles.menuTitleStyle, { ...props.menuTitleStyle }]}>{props.ThirdMenuTitle}</Text>
                        </MenuOption>
                        <MenuOption
                            onSelect={logoutPopup} >
                            <Text style={[styles.menuTitleStyle, { ...props.menuTitleStyle }]}>{props.FourthMenuTitle}</Text>
                        </MenuOption>
                    </MenuOptions>
                </Menu>
            </View>
        )
    }

    return (
        <View key={'MainContainer'}
            style={[styles.headerContainer, { ...props.headerContainerStyle }]}>
            {props.isBackButton &&
                <Ionicons name="arrow-back-outline" size={30} color={AppColor.white}
                    style={[styles.backIconStyle, { ...props.backIconStyle }]}
                    onPress={props.onBackPress}
                />
            }
            <Text
                style={[commonStyles.headerTextStyle, { ...props.headerTitleStyle }]}> {props.headerTitle}
            </Text>
            {props.isMenuButton &&
                renderMenuOptions()
            }
        </View>
    )
}
AppHeader.defaultProps = {
    FirstMenuTitle: AppString.myAccountPage,
    EULA: 'EULA',
    SecondMenuTitle: AppString.termsandConditions,
    ThirdMenuTitle: AppString.privacyPolicy,
    FourthMenuTitle: AppString.logout,
}