import { useState, useRef, useEffect } from "react";
import { View, Text, KeyboardAvoidingView, Image, ScrollView, Platform, Keyboard, Alert } from "react-native";
import { Ionicons, MaterialIcons, FontAwesome, MaterialCommunityIcons, Entypo, AntDesign } from '@expo/vector-icons'
import { bottomSafeArea, gradientBackground, showErrorBanner, showSuccessBanner, topSafeArea, _scale } from "../../../utils";
import { commonStyles } from "../../../utils/commonStyles";
import AppColor from "../../../constant/colors";
import styles from './index.style'
import AppTextInput from '../../../components/appTextInput'
import AppStrings from "../../../constant/appStrings";
import AppButton from "../../../components/appButton";
import AppHeader from '../../../components/appHeader'
import * as RNIap from 'react-native-iap';
import IAPManager from '../../../services/IAPManager/index';
import { onDeleteAccount } from "../../../controllers/DeleteAccountController";
import Storage from "../../../services/storage";
import constant from "../../../constant";

export default function DeleteAccount({ navigation }) {
    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [isPassVisible, setPassVisible] = useState(false)
    const [isPasswordClear, setPasswordClear] = useState(false)
    const [focus, setFocus] = useState('')
    const [isDisable, setIsDisable] = useState(true)


    var iapManager = IAPManager.getInstance()

    const goBack = () => {
        navigation.pop()
    }

    const passVisible = () => {
        isPassVisible == true ? setPassVisible(false) : setPassVisible(true)
    }

    function clearTextFilled(filledName) {
        setPassword('')
        setPasswordClear(false)
    }

    const checkValidation = () => {
        if (password.trim().length < 1) {
            setPassword('')
            showErrorBanner(AppStrings.passwordRequired)
        }
    }

    // useEffect(() => {
    //     checkPurchase()
    // }, [])

    // const checkPurchase = () => {
    //     iapManager.getAvailablePurchases().then((products) => {
    //         console.log('Getting Product', products);
    //     }).catch((error) => {
    //         console.log('Error');
    //     })
    // }

    const callDeleteAccountApi = () => {
        Keyboard.dismiss()
        setIsLoading(true)
        console.log('>>>>>>>> callDeleteAccountApi ');
        let request = {
            'password': password
        }
        onDeleteAccount(request).then((response) => {
            setIsLoading(false)
            if (response.status == 1) {
                showSuccessBanner(response.message)
                setData()
            } else {
                showSuccessBanner(response.message)
            }
            //console.log("Del >>>", response);
        }).catch((error) => {
            setIsLoading(false)
            //   console.log("Del error>>>", error);
        })
    }

    const showDeletePopup = () => {
        Alert.alert(
            AppStrings.appName,
            AppStrings.deleteMessagePopup,
            [
                {
                    text: "Delete", onPress: () => {
                        callDeleteAccountApi()
                    }, style: "cancel"
                },
                {
                    text: 'Cancel',
                    onPress: () => { },
                    style: 'destructive',
                }
            ]
        );
    }

    function setData() {
        Storage.clear()
        navigation.replace('AuthStack', { screen: 'Login' })
    }


    return (
        <View style={commonStyles.mainContainer} key={'MainContainer'}
            pointerEvents={isLoading ? 'none' : 'auto'}>
            {gradientBackground()}
            {topSafeArea()}
            <KeyboardAvoidingView
                style={{ flex: 1 }}
                behavior={Platform.OS === "ios" ? "padding" : null}>

                <AppHeader
                    navigation={navigation}
                    headerTitle={'Delete Account'}
                    isBackButton={true}
                    // headerContainerStyle={styles.headerStyle}
                    backIconStyle={styles.backIconStyle}
                    onBackPress={goBack} />

                <ScrollView keyboardShouldPersistTaps={'handled'} style={{ marginTop: 25 }}>
                    <Text style={styles.messageTextStyleDelAccount}>{AppStrings.deleteAccountMessage}</Text>
                    <View key={'FiledContainer'} style={styles.filedContainerStyleContact}>
                        <AppTextInput
                            placeHolder={'Enter password'}
                            defaultValue={password}
                            secureTextEntry={!isPassVisible}
                            textInputContainerStyle={{ marginTop: _scale(25) }}
                            leftIcon={<AntDesign name="lock1" size={24} color="white" />}
                            rightIcon={isPassVisible == true
                                ? <Ionicons name="md-eye-off" size={24} color="white" onPress={passVisible} />
                                : <Ionicons name="md-eye" size={24} color="white" onPress={passVisible} />}
                            clearIcon={isPasswordClear && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('password')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 ? setIsDisable(false) : setIsDisable(true)
                                setPassword(text)
                                text.trim().length >= 1 && focus == 'password' ? setPasswordClear(true) : setPasswordClear(false)
                            }
                            }
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.white}
                            onSubmitEditing={() => checkValidation()}
                            textInputStyle={commonStyles.authInputTextStyle}
                            onFocus={() => {
                                setFocus('password')
                                password.trim().length > 0 ? setPasswordClear(true) : setPasswordClear(false)
                            }
                            }
                            onBlur={() => setPasswordClear(false)}
                        />
                        <AppButton isLoading={isLoading} title="Delete Account" buttonStyle={isDisable ? styles.buttonStyleDisable : styles.buttonStyle} onPress={showDeletePopup} isDisable={isDisable} />
                    </View>

                </ScrollView>

            </KeyboardAvoidingView>
            {bottomSafeArea()}
        </View>
    )
}