import { StyleSheet, Platform } from 'react-native'
import AppColor from '../../constant/colors'
import AppFonts from '../../constant/fonts'
import { _scale } from '..'

const commonStyles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        backgroundColor: AppColor.appBackground
    },
    webSubContainerStyle: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    webBorderViewStyle: {
        width: '50%',
        height: '80%',
        backgroundColor: '#283C54',
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 8,
        borderColor: '#283C54',
        borderWidth: 1,
        borderRadius: 20
    },
    gradientBackGround: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0
    },
    buttonStyle: {
        backgroundColor: AppColor.grey,
        borderRadius: Platform.OS == 'web' ? 5 : _scale(5),
        padding: Platform.OS == 'web' ? 10 : _scale(10)
    },
    buttonTitleStyle: {
        color: AppColor.white,
        fontFamily: AppFonts.robototMedium,
        textAlign: 'center',
        fontSize: Platform.OS == 'web' ? 14 : _scale(14)

    },
    textInputStyle: {
        flex: 1,
        marginLeft: Platform.OS == 'web' ? 10 : _scale(10),
        fontFamily: AppFonts.robotoRegular,
        color: AppColor.white,
        fontSize: Platform.OS == 'web' ? 14 : _scale(14),
        backgroundColor: 'clear'

    },
    textInputDivider: {
        borderBottomColor: AppColor.white,
        borderBottomWidth: Platform.OS == 'web' ? 1 : _scale(1),
    },
    headerTextStyle: {
        fontFamily: AppFonts.robototMedium,
        color: AppColor.white,
        fontSize: Platform.OS == 'web' ? 18 : _scale(18)
    },

    authInputTextStyle: {
        fontSize: Platform.OS == 'web' ? 14 : _scale(14),
        fontFamily: AppFonts.robotoRegular,
        color: 'white'
    }



})

export { commonStyles }