import { StyleSheet, Dimensions } from "react-native";
import AppColor from "../../../constant/colors";
import AppFonts from "../../../constant/fonts";
import { _scale } from "../../../utils";

export default StyleSheet.create({
    mainContainer: {
        borderRadius: _scale(20)
    },
    crossIconStyle: {
        width: _scale(50),
        padding: _scale(5),
    },
    logoStyle: {
        height: _scale(120),
        width: _scale(120),
        alignSelf: 'center',
        marginTop: _scale(15)
    },
    filedContainerStyle: {
        marginTop: _scale(50),
        marginHorizontal: _scale(80)
    },
    checkBoxContainerStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: _scale(10)
    },
    checkBoxStyle: {
        backgroundColor: 'none',
        marginLeft: -_scale(7),
    },
    hintTextStyle: {
        fontFamily: AppFonts.robotoRegular,
        color: AppColor.white,
        marginLeft: -_scale(10),
        fontSize: _scale(14)
    },
    buttonStyle: {
        borderRadius: _scale(25),
        marginTop: _scale(10),
        padding: _scale(15),
        backgroundColor: AppColor.lightRed,
    },
    signUpBtnContainer: {
        alignItems: 'center',
        marginTop: _scale(20),
    },
    signUpBtnView: {
        padding: _scale(10),
        marginTop: _scale(10),
    },
    signUpTextStyle: {
        fontFamily: AppFonts.robotoBold,
        color: AppColor.lightRed,
        fontSize: _scale(20)
    },
    forgotPassTextStyle: {
        fontFamily: AppFonts.robototMedium,
        color: AppColor.lightRed,
        fontSize: _scale(14)
    }
})