
// import { FreeVideos } from '../../assets/videos/freeVideos'


const originsVideoURL = 'https://player.vimeo.com/progressive_redirect/playback/748674658/rendition/360p/file.mp4?loc=external&signature=6625514da538643d8b450e1c33b19c19770b73e2696e26c21b5444837a296116'
const foundationsVideoURL = 'https://player.vimeo.com/progressive_redirect/playback/748674825/rendition/360p/file.mp4?loc=external&signature=be72841976453ec7636d6e8368df8241456163e7b7e39187d736bdde35e7bdd7'
const longevityVideoURL = 'https://player.vimeo.com/progressive_redirect/playback/748674433/rendition/360p/file.mp4?loc=external&signature=561409d4a8687a69aa29b0f0fd1ca5742faa3d0a17a0a628f09514181a75b017'
const suppleVideoURL = 'https://player.vimeo.com/progressive_redirect/playback/748674738/rendition/360p/file.mp4?loc=external&signature=0c1ec70c5d82f5bc4a34cbc0d2047805efff9b98439741f54b5b5e8fab65ea3e'

export class FreeVideosModel {
    constructor(title, description, videoUrl, thumbImageURL) {
        this.title = title;
        this.description = description;
        this.videoUrl = videoUrl;
        this.thumbImageURL = thumbImageURL;
    }


    getFreeVideos() {
        return (
            [

                new FreeVideosModel(
                    "Origins",
                    "An understanding of the source, the lineage, the history, the power of where, how and who has been reinforcing the ancient practices that have been utilized to create a contemporary offering of an ancient practice, The Way 126 Experience.",
                    originsVideoURL,
                    //'https://projects8.sofmen.com/thewayapp/public/images/free_video_thumbnail/orgins.jpeg'
                    'https://admin.theway126.com/images/free_video_thumbnail/orgins.jpeg'),

                new FreeVideosModel(
                    "Foundations",
                    "Nine fundamental pillars explaining, correlating, reinforcing the 21st century understanding of an ancient practice, translated in foundational terms that give the user an informed interpretation of a contemporary offering of an ancient practice, The Way 126 Experience.",
                    foundationsVideoURL,
                    //  "https://projects8.sofmen.com/thewayapp/public/images/free_video_thumbnail/Foundations.jpeg"
                    "https://admin.theway126.com/images/free_video_thumbnail/Foundations.jpeg"

                ),
                new FreeVideosModel(
                    "Longevity",
                    "Unlock the secrets of the human body and its ability to transform and reset to its original state, giving opportunity to extended duration of a human being and its connection with the universe through ancient practices that bear history, lineage and affirmation to constitutional strength.",
                    longevityVideoURL,
                    //  'https://projects8.sofmen.com/thewayapp/public/images/free_video_thumbnail/Longevity.jpeg'
                    'https://admin.theway126.com/images/free_video_thumbnail/Longevity.jpeg'

                ),
                new FreeVideosModel(
                    "Supple",
                    "Fluidity, rolling, agile, supple, through the magic of the human body and the architecture that lies within, unlocks to ability to move, execute, endurance, strength, flow, proven with ancient practices that reinforce the constitution of the body as a whole.",
                    suppleVideoURL,
                    //"https://projects8.sofmen.com/thewayapp/public/images/free_video_thumbnail/Supple.jpeg"
                    "https://admin.theway126.com/images/free_video_thumbnail/Supple.jpeg"
                ),
            ]

        )

    }
}
