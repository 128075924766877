import { Linking, Platform, View } from 'react-native';
import { useEffect } from 'react'
import { Video, Audio } from 'expo-av';
import { commonStyles } from '../../utils/commonStyles';
import SplashScreen from "react-native-splash-screen";


export default function SplashView({ navigation }) {

    useEffect(() => {
        // navigation.replace('SplashView')

        setAudioModeSilent()

    }, [])

    async function setAudioModeSilent() {
        try {
            await Audio.setAudioModeAsync({
                playsInSilentModeIOS: true,
            });
        } catch (err) {
            console.error('Failed to start recording', err);
        }
    }


    const _onPlaybackStatusUpdate = status => {

        if (status.isLoaded) {
            SplashScreen.hide()
        }

        if (status.isLoaded && status.didJustFinish) {
            navigation.replace('BottomTabBar')
        }
    };

    return (
        <View style={commonStyles.mainContainer}>
            <Video
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    flex: 1
                }}
                source={require('../../assets/videos/splashVideo.mp4')}
                shouldPlay={true}
                resizeMode='cover'
                onPlaybackStatusUpdate={_onPlaybackStatusUpdate}
            />

        </View>
    );
}


