import Constant from '../../constant';
import ApiManager from "../../services/apiManager";
import ExpansionResponseModel from '../../models/ExpansionResponseModel'
let getExpansionEndPoint = Constant.apiEndPoints.getExpansion
var apiManager;

export const getExpansion = async (navigation) => new Promise((resolve, reject) => {
    apiManager = ApiManager.getInstance(navigation);
    apiManager.onGetApi(getExpansionEndPoint).then((responseData) => {
        let modelObj = new ExpansionResponseModel().initWithRes(responseData.json["data"])
        resolve(modelObj)
    }).catch((error) => {
        reject(error)
    })
})