import { Platform, StyleSheet } from "react-native";
import AppColor from "../../constant/colors";
import AppFonts from "../../constant/fonts";
import { _scale } from "../../utils";

export default StyleSheet.create({
    bgImageStyle: {
        width: '100%',
        height: _scale(105),
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        backgroundColor: Platform.OS == 'web' ? "" : 'black'
    },
    titleViewStyle: {
        // backgroundColor: AppColor.transparentBlack,
        // borderBottomLeftRadius: _scale(8),
        // borderBottomRightRadius: _scale(8),
        // position: 'absolute',
        // bottom: 0,
        // width: '100%',
        // flexDirection: 'row',
        // alignItems: 'center'
        justifyContent: 'center'
    },
    titleViewConatinerStyle: {
        backgroundColor: AppColor.transparentBlack,
        borderBottomLeftRadius: _scale(8),
        borderBottomRightRadius: _scale(8),
        position: 'absolute',
        bottom: 0,
        width: '100%',
        paddingHorizontal: _scale(10),
        paddingVertical: _scale(8),
    },
    experienceCountTextStyle: {
        color: AppColor.white,
        fontFamily: AppFonts.robotoRegular,
        fontSize: _scale(12),

    },
    cartIconStyle: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: _scale(15)
    },
    videoPreviewContainer: {
        marginHorizontal: _scale(5),
        marginTop: _scale(20),
        // borderBottomLeftRadius: _scale(8),
        // borderBottomRightRadius: (_scale(8)),
        // borderRadius: 10,
    },
    titleTextStyle: {
        color: AppColor.white,
        fontFamily: AppFonts.robototMedium,
        fontSize: _scale(14),
    },
    isTheWayTitleTextStyle: {
        color: AppColor.white,
        fontFamily: AppFonts.robototMedium,
        fontSize: _scale(14),
        maxWidth: _scale(100)
    },
    imageStyle: {
        borderRadius: _scale(8)
    }
})