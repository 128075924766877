import { SafeAreaView, StyleSheet, View, FlatList, Image, useWindowDimensions } from 'react-native';
import React, { useState, useEffect } from 'react';
import VideoPreview from '../../../components/VideoPreview/index.web';
import { gradientBackground } from '../../../utils';
import { getTiers } from '../../../controllers/Journey'
import Constant from '../../../constant';
import AppLoader from '../../../components/appLoader/index.web';
import SubscriptionInfoView from '../../../components/SubscriptionInfoView';
import MyStorage from '../../../services/storage';
import WebHeader from '../../../components/WebHeader.js';

export default function TheWay({ navigation }) {
    const [isLoading, setIsLoading] = useState(false)
    const [tiersData, setTiersData] = useState([])

    useEffect(() => {

        if (MyStorage.getItem(Constant.appKey.kAuthToken) == '') {
            window.location.href = window.location.origin
            return;
        }

        setIsLoading(true)
        navigation.addListener('focus', () => {
            getTiersFromServer()
        });
    }, [])

    function getTiersFromServer() {
        getTiers(navigation).then((tiersModel) => {
            setTiersData(tiersModel)
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false)
        })
    }

    const loaderToggle = (status) => {
        // console.log('status', status);
        setIsLoading(status)
    }

    return (
        <View style={styles.container}>
            {gradientBackground()}
            <WebHeader navigation={navigation} titles={["The Way"]} />
            <View style={[styles.container, { padding: 20, backgroundColor: 'clear' }]}>
                {MyStorage.getItem(Constant.paymentType.subscription) === '0' && <SubscriptionInfoView loaderActive={loaderToggle} />}
                <VideoPreview isTheWay={true} navigation={navigation} userModel={tiersData.userModel} videoData={tiersData.tiersModel} screen={Constant.ScreensName.theWay} />
            </View>
            {isLoading && <AppLoader />}
        </View>
    );

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: 'white',

    },

});