import { useRef, useState, useEffect } from 'react'
import { View, Animated, Text, TouchableOpacity } from 'react-native'
import AppStrings from '../../constant/appStrings'
import AppFonts from '../../constant/fonts'
import { _scale } from '../../utils';
import { FontAwesome } from '@expo/vector-icons';


export default function SkipButton(props) {

    return (
        <Animated.View style={{ height: 50, width: 100, position: 'absolute', right: 20, top: 20, }}>
            <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'white', opacity: 0.2, borderRadius: 5 }}></View>
            <TouchableOpacity onPress={props.onClickSkipButton} style={{ height: 50, width: 100, justifyContent: 'center', alignItems: 'center', borderRadius: 5 }}>
                <Text style={{ fontSize: _scale(14), fontFamily: AppFonts.robototMedium, color: 'white' }}>Skip  <FontAwesome name="step-forward" size={15} color="white" /> </Text>
            </TouchableOpacity>
        </Animated.View>
    )
}