import { View, Text, Keyboard, Image, KeyboardAvoidingView, ScrollView, TouchableOpacity, Platform, useWindowDimensions, Linking } from "react-native";
import React, { useState, useRef, useEffect } from 'react'
import { Ionicons, AntDesign, MaterialIcons, Entypo } from '@expo/vector-icons'
import { bottomSafeArea, showErrorBanner, showSuccessBanner, topSafeArea, _scale } from "../../../utils";
import { commonStyles } from '../../../utils/commonStyles'
import { CheckBox, Icon } from '@rneui/themed';
import AppColor from "../../../constant/colors";
import AppTextInput from "../../../components/appTextInput";
import AppStrings from "../../../constant/appStrings";
import constant from "../../../constant";
import styles from './index.style.web';
import MyStorage from "../../../services/storage";
import * as utils from '../../../utils'
import ApiManager from "../../../services/apiManager";
import WebButton from "../../../components/webButton";
var apiManager = ApiManager.getInstance();
import { LinearGradient } from 'expo-linear-gradient'
import AppLoader from "../../../components/appLoader/index.web";

let influencerID = ""

export default function SignUp({ props }) {
    const [isPassVisible, setPassVisible] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [termChecked, setTermChecked] = useState(false);
    const [rememberMeChecked, setRememberMeChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [crossIcon, setCrossIcon] = useState('')
    const [focus, setFocus] = useState('')
    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;


    useEffect(() => {
        influencerID = ""
        // console.log('URl', window.location.href);
        const params = new URL(window.location.href).searchParams;
        influencerID = params.get('influencer_id') || '';
        console.log('influencerID', influencerID);
        if (influencerID != '') {
            // showErrorBanner('Influencer Applied')
        }

    }, [])


    const passVisible = () => {
        isPassVisible == true ? setPassVisible(false) : setPassVisible(true)
    }

    const goBack = () => {
        props.navigate('LoginContainer')
    }

    const checkValidation = () => {
        if (firstName.trim().length < 1) {
            setFirstName('')
            showErrorBanner(AppStrings.firstNameRequired)
        } else if (lastName.trim().length < 1) {
            setLastName('')
            showErrorBanner(AppStrings.lastNameRequired)
        } else if (email.trim().length < 1) {
            setEmail('')
            showErrorBanner(AppStrings.emailRequired)
        } else if (!email.match(constant.emailRegex)) {
            showErrorBanner(AppStrings.validEmailAddress)
        } else if (password.trim().length < 1) {
            setPassword('')
            showErrorBanner(AppStrings.passwordRequired)
        } else if (!termChecked) {
            showErrorBanner(AppStrings.acceptTermsConditions)
        } else {
            callSignUpApi()
        }
    }

    const changeStatusRememberMe = () => {
        setRememberMeChecked(!rememberMeChecked)
    }

    const changeStatusTermCondition = () => {
        setTermChecked(!termChecked)
    }

    const openWebUrl = (url) => {

        Linking.openURL(url)
    }

    function clearTextFilled(filledName) {
        if (filledName == 'firstName') {
            firstNameRef.current.clear()
            setFirstName('')
            setCrossIcon('')

        } else if (filledName == 'lastName') {
            lastNameRef.current.clear()
            setLastName('')
            setCrossIcon('')
        }
        else if (filledName == 'email') {
            emailRef.current.clear()
            setEmail('')
            setCrossIcon('')
        } else {
            passwordRef.current.clear()
            setPassword('')
            setCrossIcon('')
        }
    }

    //------------API CALL-----------//

    const callSignUpApi = () => {
        setIsLoading(true)
        Keyboard.dismiss()
        let requestParameter = {
            'first_name': firstName,
            'last_name': lastName,
            'email': email,
            'password': password
        }
        if (influencerID != '') {
            requestParameter['influencer_id'] = influencerID
        }
        // console.log(requestParameter);

        callAPIToServer(requestParameter, constant.apiEndPoints.signUp)
    }

    const callAPIToServer = (requestParameter, endPoint) => {
        apiManager.onPostApi(endPoint, requestParameter).then((responseData) => {
            if (responseData.json.status == constant.successCode) {
                showSuccessBanner(AppStrings.signUpSuccess)
                console.log('----SignUp success Response : ', responseData.json.data)
                MyStorage.setItem(constant.appKey.isVerifedUser, true)
                utils.setLoginData(responseData.json.data)
                MyStorage.setItem(constant.appKey.isComeFromLogin, '1')

                props.replace('WebDrawerNavigator')
                // if (rememberMeChecked == true) {
                //     console.log("remember me ", rememberMeChecked);
                //     storage.setItem(constant.appKey.isRememberMe, true)
                //     storage.setItem(constant.appKey.kUserEmail, email)
                //     storage.setItem(constant.appKey.kUserPassword, password)

                // }
            }
            setIsLoading(false)

        }).catch((error) => {
            // console.log("SignUp Error:", error);
            setIsLoading(false)
        })
    }

    return (
        <View key={'MainContainer'} style={[commonStyles.mainContainer, { borderRadius: _scale(20) }]}
            pointerEvents={isLoading ? 'none' : 'auto'}>
            <LinearGradient colors={['#283C54', '#000000']} style={[commonStyles.gradientBackGround, { borderRadius: 20, }]} />
            {topSafeArea()}
            <KeyboardAvoidingView
                style={{ flex: 1 }}
                behavior={Platform.OS === "ios" ? "padding" : null}>

                <TouchableOpacity onPress={() => window.location.href = window.location.origin}>
                    <Image
                        source={require('../../../assets/images/appicon.png')}
                        style={styles.logoStyle} />
                </TouchableOpacity>

                <ScrollView keyboardShouldPersistTaps={'handled'} showsHorizontalScrollIndicator={false}>

                    <View key={'FiledContainer'} style={[styles.filedContainerStyle, { marginHorizontal: isLargeScreen ? 80 : 16 }]}>
                        <AppTextInput
                            reference={firstNameRef}
                            placeHolder={'First Name'}
                            defaultValue={firstName}
                            autoFocus={true}
                            returnKeyLabel={'next'}
                            returnKeyType={'next'}
                            leftIcon={<Ionicons name="person-sharp" size={24} color="white" />}
                            clearIcon={crossIcon == 'firstName' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('firstName')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 && focus == 'firstName' ? setCrossIcon('firstName') : setCrossIcon('')
                                setFirstName(text)

                            }
                            }
                            onSubmitEditing={() => lastNameRef.current.focus()}
                            blurOnSubmit={false}
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.lightGrey}
                            textInputStyle={{ outline: "none" }}
                            onFocus={() => {
                                setCrossIcon('firstName')
                                setFocus('firstName')
                                firstName.trim().length > 0 ? setCrossIcon('firstName') : setCrossIcon('')
                            }
                            }
                        />

                        <AppTextInput
                            reference={lastNameRef}
                            placeHolder={'Last Name'}
                            defaultValue={lastName}
                            returnKeyLabel={'next'}
                            returnKeyType={'next'}
                            textInputContainerStyle={{ marginTop: _scale(25) }}
                            leftIcon={<Ionicons name="person-sharp" size={24} color="white" />}
                            clearIcon={crossIcon == 'lastName' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('lastName')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 && focus == 'lastName' ? setCrossIcon('lastName') : setCrossIcon('')
                                setLastName(text)

                            }
                            }
                            onSubmitEditing={() => emailRef.current.focus()}
                            blurOnSubmit={false}
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.lightGrey}
                            textInputStyle={{ outline: "none" }}
                            onFocus={() => {
                                setCrossIcon('lastName')
                                setFocus('lastName')
                                lastName.trim().length > 0 ? setCrossIcon('lastName') : setCrossIcon('')
                            }
                            }
                        />

                        <AppTextInput
                            reference={emailRef}
                            placeHolder={'E-mail'}
                            defaultValue={email}
                            returnKeyLabel={'next'}
                            returnKeyType={'next'}
                            keyboardType={'email-address'}
                            autoCapitalize={'none'}
                            textInputContainerStyle={{ marginTop: _scale(25) }}
                            leftIcon={<MaterialIcons name="email" size={24} color="white" />}
                            clearIcon={crossIcon == 'email' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('email')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 && focus == 'email' ? setCrossIcon('email') : setCrossIcon('')
                                setEmail(text)

                            }
                            }
                            onSubmitEditing={() => passwordRef.current.focus()}
                            blurOnSubmit={false}
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.lightGrey}
                            textInputStyle={{ outline: "none" }}
                            onFocus={() => {
                                setCrossIcon('email')
                                setFocus('email')
                                email.trim().length > 0 ? setCrossIcon('email') : setCrossIcon('')
                            }
                            }
                        />

                        <AppTextInput
                            reference={passwordRef}
                            placeHolder={'Password'}
                            defaultValue={password}
                            secureTextEntry={!isPassVisible}
                            textInputContainerStyle={{ marginTop: _scale(25) }}
                            leftIcon={<AntDesign name="lock1" size={24} color="white" />}
                            rightIcon={isPassVisible == true
                                ? <Ionicons name="md-eye-off" size={24} color="white" onPress={passVisible} />
                                : <Ionicons name="md-eye" size={24} color="white" onPress={passVisible} />}
                            clearIcon={crossIcon == 'password' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('password')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 && focus == 'password' ? setCrossIcon('password') : setCrossIcon('')
                                setPassword(text)

                            }
                            }
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.lightGrey}
                            onSubmitEditing={() => checkValidation()}
                            textInputStyle={{ outline: "none" }}
                            onFocus={() => {
                                setCrossIcon('password')
                                setFocus('password')
                                password.trim().length > 0 ? setCrossIcon('password') : setCrossIcon('')
                            }
                            }
                        />

                        <View key={'TermsContainer'} style={[styles.checkBoxContainerStyle, { marginTop: _scale(10) }]} >
                            <CheckBox
                                containerStyle={styles.checkBoxStyle}
                                checked={termChecked}
                                onPress={changeStatusTermCondition}
                                checkedColor={'white'}
                                uncheckedColor={'white'}
                            />
                            <Text onPress={changeStatusTermCondition} style={styles.hintTextStyle}>{AppStrings.acceptText}
                                <Text onPress={() => openWebUrl(constant.URL.EULA)} style={styles.signInTextStyle}>{AppStrings.eulaText}</Text>
                                <Text onPress={() => openWebUrl(constant.URL.TnC)} style={styles.signInTextStyle}>{AppStrings.tosText}</Text>
                                <Text onPress={() => openWebUrl(constant.URL.PrivacyPolicy)} style={styles.signInTextStyle}>{AppStrings.privacyPolicyText}</Text>



                            </Text>

                        </View>

                        <WebButton title="Sign Up" buttonStyle={styles.buttonStyle} onPress={checkValidation} />

                        <View key={'FooterContainer'} style={styles.footerContainerStyle}>
                            <Text style={styles.hintTextStyle}>Already have an account
                                <TouchableOpacity key={'SigninContainer'} style={{ paddingHorizontal: _scale(5) }} onPress={goBack}>
                                    <Text style={styles.signInTextStyle}>Sign in?</Text>
                                </TouchableOpacity>
                            </Text>

                        </View>
                        <View style={{ height: 20 }}></View>

                    </View>
                </ScrollView>
            </KeyboardAvoidingView>
            {isLoading && <AppLoader />}
            {bottomSafeArea()}
        </View>
    )
}
