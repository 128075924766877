import constant from "../../constant"
import ApiManager from "../../services/apiManager"
var apiManager = ApiManager.getInstance()

export const onDeleteAccount = async (request) => new Promise((resolve, reject) => {
    apiManager.onPostApi(constant.apiEndPoints.deleteAccount, request).then((responseData) => {
        console.log('----onDeleteAccount success Response : ', responseData.json)
        if (responseData.json.status == constant.successCode) {

            resolve(responseData.json)
        }
    }).catch((error) => {
        console.log("onDeleteAccount Error:", error);
        reject(error)
    })
})