import { StyleSheet } from 'react-native'
import { _scale } from '../../../utils'

export default StyleSheet.create({
    headerStyle: {
        marginTop: _scale(5),
        marginBottom: _scale(5)
    },
    backIconStyle: {
        width: _scale(45),
        paddingVertical: _scale(8),
        paddingLeft: _scale(5)
    },

    headerLogoStyle: {
        height: _scale(80),
        width: _scale(80),
        alignSelf: 'center',
        marginBottom: _scale(40)
    },
})