import { useEffect, useState } from "react";
import { View, Text, StyleSheet, Pressable, ScrollView, Alert, Platform } from "react-native";
import AppHeader from "../../../../components/appHeader";
import { gradientBackground, showErrorBanner, topSafeArea, _scale } from "../../../../utils";
import { commonStyles } from "../../../../utils/commonStyles";
import styles from '../../tierDetails/index.style'
// import ProgressiveImage from '@freakycoder/react-native-progressive-fast-image'
import AppButton from "../../../../components/appButton";
import AppFonts from "../../../../constant/fonts";
import IAPManager from '../../../../services/IAPManager';
import RNIap, {
    purchaseErrorListener,
    purchaseUpdatedListener,
    ProductPurchase,
    PurchaseError,
    clearTransactionIOS,
    clearProductsIOS
} from 'react-native-iap';
import { onInAppPurchasePayment } from "../../../../controllers/PaymentUrlController";
import AppLoader from "../../../../components/appLoader";
import constant from "../../../../constant";
import AppStrings from "../../../../constant/appStrings";

var iapManager = IAPManager.getInstance()


function Subscription({ navigation }) {

    let purchaseUpdateSubscription;
    let purchaseErrorSubscription;
    const items = Platform.select({
        ios: [
            constant.subscriptionProductIos.monthly,
        ],
        android: [
            constant.subscriptionProductAndroid.monthly,
        ]
    });

    const [isLoadingSubscribeButton, setLoadingSubscribeButton] = useState(true)
    const [message, setMessage] = useState("Loading...")
    const inAppConnectingMessage = Platform.OS == 'ios' ? 'Connecting with iTunes Account...' : 'Connecting with Google Play Account...';
    // const infoMessage = `This subscription allows unlimited access to all contemporary offering of an ancient practice mastering yourself change comes from within. Tap the button above to purchase a subscription for the price specified. Payment will be charged to ${Platform.OS == 'ios' ? 'iTunes Account' : 'Google Play'} at confirmation of purchase.`
    const infoMessage = `This subscription allows you to progressively access the 126 Experience. Each day you complete the Experience, you will unlock the next day, the next week, the next attunement. We have formulated this process specifically to have you, the user, move at the perfect speed allowing you to develop a skill and rewire your brain. You will attain a developed skill through your own experience, within your unconscious and conscious process, to change your state.\n\n Tap the button above to agree to purchase the monthly subscription. The Way offers an auto-renewing monthly subscription at $126.99/month. Payment will be charged to the credit card connected to your App Store account when you confirm the initial subscription purchase. Subscriptions automatically renew monthly from the initial day of subscription, unless auto-renew is turned off at least 24 hours before the end of the current subscription period. If you choose to cancel your auto-renew subscription, you will be allowed any remaining access to the app and experience until the next date of renewal. You may manage your auto-renew subscription and auto-renewal may be turned off by going to your Account Settings after the purchase. Expansions are a one-time purchase item available to you, which will be charged a one-time price and will appear in the user's "My Way" tab. The user will have unlimited access to any expansions purchased.`
    let offerToken = ""

    useEffect(() => {


        if (Platform.OS == 'android') {
            console.log('nnnn ', items);
            iapManager.getSubscriptions(items).then((product) => {
                console.log("Subscription:", product[0].subscriptionOfferDetails);
                if (product.length > 0) {
                    offerToken = product[0].subscriptionOfferDetails[0].offerToken
                }

            }).catch((error) => {
                console.log("Error:", error);

            }).finally(() => {
                resetLoader(false)
                console.log("finally:");
            });
        } else {
            iapManager.getProductIDs(items).then((product) => {

                console.log("Product:", product);
            }).catch((error) => {
                console.log("Error:", error);
            }).finally(() => {
                resetLoader(false)
            });
        }

        return (() => {
            resetSubscriptionListener()
            return RNIap.endConnection()
        })

    }, [])

    const onClickSubscribe = () => {
        setLoadingSubscribeButton(true)
        setMessage(inAppConnectingMessage)
        purchaseListener()

        let productID = Platform.OS == 'ios' ? constant.subscriptionProductIos.monthly : constant.subscriptionProductAndroid.monthly
        const subscriptionOffers = [{ sku: productID, offerToken, }]
        let productSkuInfo = Platform.OS == 'ios' ? { sku: productID } : { subscriptionOffers: subscriptionOffers }

        console.log('productSkuInfo >>>> ', subscriptionOffers);
        iapManager.requestSubscription(productSkuInfo).then((product) => {
            console.log("=========product", product);
        }).catch((error) => {
            resetLoader(false)
        });

    }

    const sendReceiptToServer = (purchase) => {
        console.log(purchase);
        setMessage('Verifying data to server, Please Wait...')
        onInAppPurchasePayment(purchase).then((res) => {
            showErrorBanner("Congratulations! You are now subscribed to our monthly plan")
            navigation.pop()
        }).catch((error) => {

            console.log('sudur', error);
            showErrorBanner(error.msg)
        }).finally(() => {
            resetLoader(false)

        })

    }

    function purchaseListener() {

        purchaseUpdateSubscription = purchaseUpdatedListener(
            async (purchase) => {
                resetSubscriptionListener()
                const receipt = purchase.transactionReceipt;
                if (receipt) {
                    sendReceiptToServer(purchase)
                    iapManager.finishTransaction(purchase)
                    if (Platform.OS == 'ios') {
                        clearTransactionIOS().then((abc) => {
                            console.log("abc", abc);
                        }).catch((error) => {
                            console.log(error);
                        })
                    }
                }
            },
        );
        purchaseErrorSubscription = purchaseErrorListener(
            (error) => {
                resetSubscriptionListener()
                resetLoader(false)
                console.log('error', error);
                let errorMessage = error.message.length > 0 ? error.message : JSON.stringify(error)
                Alert.alert('Purchase error', errorMessage);
            },
        );
    }

    function resetSubscriptionListener() {
        try {
            if (purchaseUpdateSubscription) {
                console.log('Clear purchaseUpdateSubscription');
                purchaseUpdateSubscription.remove();
                purchaseUpdateSubscription = null;
            }

            if (purchaseErrorSubscription) {
                console.log('Clear purchaseErrorSubscription');
                purchaseErrorSubscription.remove();
                purchaseErrorSubscription = null;
            }

        } catch (error) {
            console.log(error);
        }
    }

    const restorePurchase = () => {

        setLoadingSubscribeButton(true)
        setMessage('Restoring Data...')

        iapManager.getAvailablePurchases().then((products) => {

            console.log('Getting Product', products);
            if (products.length > 0) {
                var sortedProduct = products.sort(function (a, b) {
                    return b.transactionDate - a.transactionDate;
                });

                let lastProduct = sortedProduct[0]
                if (lastProduct.transactionDate < Date.now()) {
                    resetLoader(false)
                    Alert.alert(constant.AppInfo.name, 'You do not have any active subscription')
                } else {
                    sendReceiptToServer(lastProduct)
                }

            } else {
                resetLoader(false)
                Alert.alert(constant.AppInfo.name, 'You do not have any active subscription')
            }
        }).catch((error) => {
            console.log('Error');
            resetLoader(false)
        })
    }


    const resetLoader = (status) => {

        setLoadingSubscribeButton(status)
        setMessage(inAppConnectingMessage)
    }

    const onOpenUrl = (pageName) => {
        if (pageName == 'Privacy Policy') {
            navigation.navigate(AppStrings.webPage, { paymentUrl: constant.URL.PrivacyPolicy, title: pageName })
        } else if (pageName == 'Terms of Service') {
            navigation.navigate(AppStrings.webPage, { paymentUrl: constant.URL.TnC, title: pageName })
        }
    }

    return (
        <View style={commonStyles.mainContainer}>
            {gradientBackground()}
            {topSafeArea()}

            <AppHeader
                navigation={navigation}
                onBackPress={() => navigation.pop()}
                isBackButton={true}
                headerContainerStyle={styles.headerStyle}
                headerTitle={''}
                backIconStyle={styles.backIconStyle}
            />

            <View style={{ flex: 1 }}>
                <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 0, }}>
                    {/* <ProgressiveImage
                        source={require('../../../../assets/images/appicon.png')}
                        style={[styles.headerLogoStyle, { marginBottom: _scale(10) }]} /> */}
                </View>
                <ScrollView>
                    <View style={{ height: _scale(50), }}>
                        <AppButton
                            isLoading={false}
                            onPress={onClickSubscribe}
                            buttonStyle={{
                                borderRadius: _scale(20),
                                marginHorizontal: _scale(10),
                                backgroundColor: 'red'

                            }}
                            title={`Monthly Subscription $${constant.subscriptionAmount.monthly}`}
                        />
                    </View>

                    <View style={{ flex: 1, paddingHorizontal: _scale(20), marginTop: _scale(10) }}>

                        <Text style={{ color: 'white', lineHeight: 21, fontFamily: AppFonts.robotoRegular, textAlign: 'justify' }}>
                            {infoMessage}
                        </Text>

                        {/* <Text style={{ color: 'white', marginTop: _scale(20), fontFamily: AppFonts.robotoRegular, textAlign: 'justify' }}>
                            {'>'} Already purchased on this device?   <Text onPress={restorePurchase} style={localStyles.clickableBtn} >RESTORE NOW</Text>
                        </Text> */}

                        {/* <Text style={{ color: 'white', marginTop: _scale(20), lineHeight: 21, fontFamily: AppFonts.robotoRegular, textAlign: 'justify' }}>
                            Subscriptions will last for the duration specified, and renew within 24-hours prior to the end of the current period for the selected price, unless auto-renew is turned off at least 24-hours before the end of the current period.
                        </Text>

                        <Text style={{ color: 'white', marginTop: _scale(20), lineHeight: 21, fontFamily: AppFonts.robotoRegular, textAlign: 'justify' }}>
                            Subscriptions may be managed by the user and auto renewal may be turned of by going to the user's Account Settings after purchase.
                        </Text>

                        <Text style={{ color: 'white', marginTop: _scale(20), lineHeight: 21, fontFamily: AppFonts.robotoRegular, textAlign: 'justify' }}>
                            Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable.
                        </Text> */}

                    </View>
                </ScrollView>

                <View style={{ flexDirection: 'row', justifyContent: "space-around", marginBottom: _scale(10), marginTop: _scale(10) }}>
                    <Text onPress={() => onOpenUrl('Terms of Service')} style={localStyles.clickableBtn}>Terms of Service</Text>
                    <Text onPress={() => onOpenUrl('Privacy Policy')} style={localStyles.clickableBtn}>Privacy Policy</Text>

                </View>
            </View>
            {isLoadingSubscribeButton && <AppLoader message={message} />}

        </View>
    );
}

export default Subscription;

const localStyles = StyleSheet.create({

    clickableBtn: {
        color: '#DF6053',
        fontFamily: AppFonts.robotoBold,
        textDecorationLine: 'underline'

    }

})