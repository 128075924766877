import React from 'react';
import NetInfo from '@react-native-community/netinfo';
import AppConstant from '../../constant';
import MyStorage from '../storage';
import { showErrorBanner } from '../../utils';

const WSErrorType = {
    SERVER: 1,
    API: 2,
    NETWORK: 3,
};

const ErrorMsg = {
    msgNoInternet: "Internet is not connected",
    msgConnectionLost: "Connection lost. Please check your network connectivity",
    msgServerError: "Something went wrong, please try again",
    msgRequestTimeOut: "Request time out, please try again",
}

export default class ApiManager extends React.Component {

    static myInstance = null;
    static navigation;
    static getInstance(navigation) {
        this.navigation = navigation;

        if (this.myInstance === null) {
            this.myInstance = new ApiManager();
        }
        return this.myInstance;
    }

    getHeader() {

        // console.log("Authorization", MyStorage.getItem(AppConstant.appKey.kAuthToken));
        return {

            'Content-Type': 'application/json',
            'cache-control': 'no-cache',
            'Authorization': "Bearer " + MyStorage.getItem(AppConstant.appKey.kAuthToken),
            "device_token": MyStorage.getItem(AppConstant.appKey.kDeviceToken),

        }
    }

    queryString = (obj) => {

        if (Object.keys(obj).length === 0) {
            return ''
        }

        let str = '?' + Object.keys(obj).reduce(function (a, k) {
            a.push(k + '=' + encodeURIComponent(obj[k]));
            return a;
        }, []).join('&');
        return str;
    }

    getMultiPartHeader() {
        // console.log("Authorization", MyStorage.getItem(AppConstant.appKey.kAuthToken));

        return {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Authorization": "Bearer " + MyStorage.getItem(AppConstant.appKey.kAuthToken),
            "device_token": MyStorage.getItem(AppConstant.appKey.kDeviceToken),
        }
    }

    onGetApi = async (endPoint, request = {}, methodType = 'GET',) => new Promise((resolve, reject) => {

        NetInfo.fetch().then(state => {
            if (state.isConnected != null && state.isConnected != false && state.isConnected != undefined) {

                let queryString = this.queryString(request)
                let baseUrl = AppConstant.URL.BASE_URL
                let apiUrl = baseUrl + endPoint + queryString
                console.log('apiUrl', apiUrl);

                let header = this.getHeader()

                console.log('Header', header);

                fetch(apiUrl, {
                    method: methodType,
                    headers: header
                }).then((response) => {

                    response.json().then((json) => {
                        console.log('Onget Api Response json ------- ', json);
                        if (json.message === 'Invalid token') {
                            showErrorBanner(json.message)
                            MyStorage.clear()
                            ApiManager.navigation.replace('AuthStack', { screen: 'Login' })
                        } else
                            if (json.errors) {
                                // console.log('ERRORs:----', json.errors);

                                return reject(new WSError(ErrorMsg.msgServerError, json.errors.code, WSErrorType.API));
                            } else {

                                if (json.status == 1) {
                                    return resolve(new WSResult(response, json, 1))
                                } else {
                                    json.status != 404 && showErrorBanner(json.message)
                                    return reject(new WSError(json.message, json.status, WSErrorType.API));
                                }
                            }

                    }).catch((error) => {
                        console.log('onGetApi ERROR:----', error);
                        showErrorBanner(ErrorMsg.msgServerError)
                        return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.API));
                    });

                }).catch((error) => {
                    console.log('onGetApi ERROR 1:----', error);
                    showErrorBanner(ErrorMsg.msgServerError)
                    return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.SERVER));
                });

            } else {
                showErrorBanner(ErrorMsg.msgNoInternet)
                return reject(new WSError(ErrorMsg.msgNoInternet, 12004, WSErrorType.API));

            }
        });
    });

    onPostApi = async (endPoint, requestData = {}, methodType = 'POST', isMultipart = false) => new Promise((resolve, reject) => {

        NetInfo.fetch().then(state => {
            if (state.isConnected != null && state.isConnected != false && state.isConnected != undefined) {

                var baseUrl = AppConstant.URL.BASE_URL

                let newHeader = this.getMultiPartHeader()
                let jsonData = JSON.stringify(requestData)

                console.log('jsonData', jsonData);
                console.log('HEADER:----', newHeader);
                console.log('requestData:----', requestData);
                console.log('URL :----', baseUrl + endPoint);

                fetch(baseUrl + endPoint, {
                    method: methodType,
                    headers: newHeader,
                    body: jsonData,

                }).then((response) => {

                    response.json().then((json) => {

                        console.log('API End Point:----', endPoint);
                        console.log('Response Data ------- ', response);
                        console.log('Response json ------- ', json);

                        if (json.errors) {
                            // console.log('ERRORs:----', json.errors);
                            showErrorBanner(ErrorMsg.msgServerError)
                            return reject(new WSError(ErrorMsg.msgServerError, json.errors.code, WSErrorType.API));
                        } else {

                            if (json.status == 1) {
                                return resolve(new WSResult(response, json, 1))
                            } else {

                                json.status != 404 && showErrorBanner(json.message)
                                return reject(new WSError(json.message, json.status, WSErrorType.API));
                            }

                        }

                    }).catch((error) => {
                        console.log('onPostApi ERROR:----', error);
                        showErrorBanner(ErrorMsg.msgServerError)
                        return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.API));
                    });

                }).catch((error) => {
                    console.log('onPostApi ERROR 1:----', error);
                    showErrorBanner(ErrorMsg.msgServerError)
                    return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.API));
                });
            } else {
                showErrorBanner(ErrorMsg.msgNoInternet)
                return reject(new WSError(ErrorMsg.msgNoInternet, 12004, WSErrorType.API));
            }
        });
    });

}

class WSError {

    constructor(
        msg = '',
        code = 0,
        type = WSErrorType.API
    ) {
        this.msg = msg
        this.code = code
        this.type = type
    }
}

class WSResult {

    constructor(
        responce = Response,
        json = JSON,
        code = 0,
    ) {
        this.responce = responce
        this.json = json
        this.code = code
    }

}