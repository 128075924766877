import { StyleSheet, Dimensions } from 'react-native'
import AppColor from '../../../constant/colors'
import AppFonts from '../../../constant/fonts'
import { _scale } from '../../../utils'
const width = Dimensions.get('window').width - 65;

export default StyleSheet.create({
    backIconStyle: {
        paddingVertical: _scale(15),
        position: 'absolute',
        paddingHorizontal: _scale(10),
    },
    logoStyle: {
        height: _scale(120),
        width: _scale(120),
        alignSelf: 'center',
        marginTop: _scale(15)
    },
    filedContainerStyle: {
        marginTop: _scale(30),
        marginHorizontal: _scale(35),
        marginBottom: _scale(20)
    },
    messageTextStyle: {
        // textAlign: 'center',
        fontFamily: AppFonts.robototMedium,
        fontSize: _scale(16),
        color: AppColor.white,
        marginVertical: _scale(10),
        marginHorizontal: _scale(35)
    },
    buttonStyle: {
        borderRadius: _scale(25),
        marginTop: _scale(30),
        padding: _scale(15),
        backgroundColor: AppColor.red,
        width: width
    },
    buttonStyleDisable: {
        borderRadius: _scale(25),
        marginTop: _scale(30),
        padding: _scale(15),
        backgroundColor: AppColor.red,
        width: width,
        opacity: 0.5
    },
    headerStyle: {
        marginTop: _scale(20),
        marginBottom: _scale(10)
    },
    backIconStyle: {
        width: _scale(45),
        paddingVertical: _scale(8),
        paddingLeft: _scale(5)
    },
    logoStyleContact: {
        height: _scale(60),
        width: _scale(60),
        alignSelf: 'center',
        marginTop: _scale(15)
    },
    messageTextStyleDelAccount: {
        // textAlign: 'center',
        fontFamily: AppFonts.robototMedium,
        fontSize: _scale(16),
        color: AppColor.white,
        marginVertical: _scale(10),
        marginHorizontal: _scale(35)
    },
    filedContainerStyleContact: {
        marginTop: _scale(10),
        marginHorizontal: _scale(35),
        marginBottom: _scale(20)
    },
})