import { StatusBar } from 'expo-status-bar';
import { Linking, Platform, View, Image } from 'react-native';
import { useEffect } from 'react'
import { useFonts } from 'expo-font'
import AppNavigation from '../navigation'
import { commonStyles } from '../utils/commonStyles';
import FlashMessage from 'react-native-flash-message';
import MyStorage from '../services/storage';


export default function Entry() {

    useEffect(() => {

        if (Platform.OS == 'web') {
            Linking.getInitialURL().then((url) => {
                // console.log(url);
                if (url.toString().includes("=success")) {
                    window.location.reload = window.location.origin
                }
            });
        }
        MyStorage.sync()
    }, [])

    const [loaded, error] = useFonts({
        'Roboto-Bold': require('../assets/fonts/Roboto-Bold.ttf'),
        'Roboto-Black': require('../assets/fonts/Roboto-Black.ttf'),
        'Roboto-Medium': require('../assets/fonts/Roboto-Medium.ttf'),
        'Roboto-Italic': require('../assets/fonts/Roboto-Italic.ttf'),
        'Roboto-Regular': require('../assets/fonts/Roboto-Regular.ttf'),

    });

    // console.log('Font loaded status', loaded);
    // console.log('Font error', error);

    if (!loaded && error == null) {
        return (
            <View style={[commonStyles.mainContainer, { justifyContent: 'center', alignItems: 'center', backgroundColor: 'black' }]}>
                <Image
                    style={{ width: 100, height: 100 }}
                    source={require('../../assets/appicon.png')}
                />
            </View>
        );
    }

    return (
        <View style={commonStyles.mainContainer}>
            <StatusBar style='light' backgroundColor='#283C54' />
            <AppNavigation />
            <FlashMessage position={'top'} />
        </View>
    );
}


