import { StyleSheet } from 'react-native'
import AppColor from '../../../../constant/colors'
import AppFonts from '../../../../constant/fonts'
import { _scale } from '../../../../utils'

export default StyleSheet.create({
    mainContainer: {
        flex: 1,
        backgroundColor: AppColor.transparentBlack
    },
    backButtonContainer: {
        position: 'absolute',
        top: 20,
        left: 20,
        height: 50,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row'
    },
    mediaControllerViewStyle: {
        flex: 1,
        position: 'absolute',
        bottom: 20,
        right: 20,
        left: 20,
    },
    timeViewStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1
    },
    sliderStyle: {
        height: _scale(10),

    },
    thumbStyle: {
        width: _scale(15),
        height: _scale(15)
    },
    timeTextStyle: {
        color: 'white',
        fontFamily: AppFonts.robotoRegular,
    },
})