
import ApiManager from '../../services/apiManager';
import Storage from '../../services/storage';
import Constant from '../../constant';
import * as utils from '../../utils';
import TiersResponseModel from '../../models/TiersResponseModel';
var apiManager;
let getTiersEndPoint = Constant.apiEndPoints.getTiers;
export const getTiers = async (navigation) => new Promise((resolve, reject) => {
    apiManager = ApiManager.getInstance(navigation);
    apiManager.onGetApi(getTiersEndPoint).then((responseData) => {
        console.log('getTiers Response >>> ', responseData.json["data"]);
        let modelObj = new TiersResponseModel().initWithRes(responseData.json["data"])
        resolve(modelObj)
    }).catch((error) => {
        reject(error)
    })
})