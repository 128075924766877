import React from 'react'
import { View, useWindowDimensions } from 'react-native'
import Login from '../../../screens/authScreens/login/index.web'
import { gradientBackground } from '../../../utils'
import { commonStyles } from '../../../utils/commonStyles'

export default function LoginContainer({ navigation }) {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;

    return (
        <View style={commonStyles.mainContainer} key={'MainContainer'}>
            {gradientBackground()}
            <View style={commonStyles.webSubContainerStyle} key={'SubContainer'}>
                <View style={[commonStyles.webBorderViewStyle, { width: isLargeScreen ? '50%' : '90%' }]} key={'BorderView'}>
                    <View style={{ flex: 1 }}>
                        <Login props={navigation} />
                    </View>
                </View>
            </View>

        </View>
    )
}





