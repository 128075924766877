import { StyleSheet } from 'react-native'
import AppColor from '../../constant/colors'
import AppFonts from '../../constant/fonts'
import { _scale } from '../../utils'

export default StyleSheet.create({
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 10
    },
    backIconStyle: {
        position: 'absolute',
        left: 0,
    },
    menuContainerStyle: {
        position: 'absolute',
        right: 0,
    },
    menuIconStyle: {
        height: _scale(20),
        width: _scale(20),
        tintColor: AppColor.white
    },
    menuOptionContainerStyle: {
        marginTop: _scale(35),
        backgroundColor: AppColor.black,
        borderRadius: _scale(15),
        paddingVertical: _scale(10),
        paddingLeft: _scale(5)
    },
    menuTitleStyle: {
        color: AppColor.white,
        fontFamily: AppFonts.robotoRegular,
        fontSize: _scale(14)
    }
})