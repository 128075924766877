import { View, StyleSheet, ActivityIndicator, Text, TouchableOpacity } from 'react-native'
import { _scale } from '../../utils'
import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons'
import styles from './index.style';
import { localImages } from '../../assets/images';
import PagingDots from '../pagingDots';
import AppFonts from '../../constant/fonts';
import { Image } from '@rneui/base';
// import ProgressiveImage from '@freakycoder/react-native-progressive-fast-image';

const tierImages = [
    require('../../assets/images/Tier_1.png'),
    require('../../assets/images/Tier_2.png'),
    require('../../assets/images/Tier_3.png'),
    require('../../assets/images/Tier_4.png'),
    require('../../assets/images/Tier_5.png'),
    require('../../assets/images/Tier_6.png'),
    require('../../assets/images/Tier_7.png'),
    require('../../assets/images/Tier_8.png'),
    require('../../assets/images/Tier_9.png'),
];

const levelImages = [
    require('../../assets/images/Level_1.png'),
    require('../../assets/images/Level_2.png'),
    require('../../assets/images/Level_3.png'),
    require('../../assets/images/Level_4.png'),
    require('../../assets/images/Level_5.png'),
    require('../../assets/images/Level_6.png'),
    require('../../assets/images/Level_7.png'),
    require('../../assets/images/Level_8.png'),
    require('../../assets/images/Level_9.png'),
];


export default function AppVideoPreview(props) {
    //console.log("RRR >>> ", props);
    const onClickCell = () => {
        if (props.onPressCell != undefined) {
            props.onPressCell(props.item)
        }
    }

    return (
        <TouchableOpacity activeOpacity={props.disabled ? 1 : 0.6} onPress={onClickCell} key={'MainContainer' + props.indexKey} style={props.containerStyle || styles.videoPreviewContainer}>
            {/* <ProgressiveImage */}
            <Image
                source={{
                    uri: props.item.thumbImageURL == undefined || '' ? 'https://www.gstatic.com/webp/gallery/1.jpg' : props.item.thumbImageURL,
                }}
                // thumbnailSource={localImages.appLogo}
                loadingImageStyle={{
                    width: _scale(50),
                    height: _scale(50),
                    alignSelf: 'center',

                }}
                loadingSource={localImages.appGif}
                style={[styles.bgImageStyle, { ...props.bgImageStyle }]}>
                {props.cartIcon == !undefined ? <MaterialCommunityIcons name="cart-arrow-down" size={24} color="white" style={styles.cartIconStyle} /> : ''}
                <View style={[styles.titleViewConatinerStyle, { ...props.titleViewConatinerStyle }]}>

                    <View style={[styles.titleViewStyle, { ...props.titleViewStyle }]}>
                        <Text style={props.isTheWay == true ? styles.isTheWayTitleTextStyle : props.titleTextStyle || styles.titleTextStyle}>{props.item.title}</Text>
                        {props.numberOfDots != undefined &&
                            <PagingDots dotContainerStyle={{ position: 'absolute', marginRight: _scale(15), right: 0, ...props.dotContainerStyle }} numberOfDots={props.numberOfDots} selectedCount={props.selectedCount} />
                        }
                    </View>
                    {props.isExperienceCount && <Text style={styles.experienceCountTextStyle}>{props.item.experienceCount == 1 || 0 ? props.item.experienceCount + " Experience" : props.item.experienceCount + " Experience"}</Text>}
                </View>
                {/* </ProgressiveImage> */}
            </Image>

            {props.isPurchased == 1 &&
                <View style={{ position: 'absolute', top: 5, right: 5, alignItems: 'flex-end', backgroundColor: 'green', padding: 10, borderRadius: 5 }} >
                    <Text style={{ color: 'white', fontFamily: AppFonts.robototMedium }}>Purchased</Text>

                </View>
            }

            {props.disabled &&
                <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, alignItems: 'flex-end' }} >
                    {/* <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: '#cccccc', opacity: 0.6, flex: 1, borderRadius: 10, }} /> */}
                    <View style={{ padding: 10 }}>
                        {/* <FontAwesome name="lock" size={35} color="red" /> */}
                    </View>
                </View>
            }

            {(props.screenName == 'theWay' || props.screenName == 'tierDetails' || props.screenName == 'The Way 126' || props.screenName == 'level') &&
                <View style={{ position: 'absolute', height: _scale(105), right: 0, }}>
                    {props.item.sequence != undefined &&
                        < Image
                            placeholderStyle={{ backgroundColor: 'transparent' }}
                            //source={require('../../assets/images/Tier_1.png')}
                            source={(props.screenName == 'theWay' || props.screenName == 'The Way 126') ? tierImages[props.item.sequence - 1] : levelImages[props.item.sequence - 1]}
                            style={{ width: _scale(65), height: _scale(100), right: 0 }}
                        />
                    }

                </View>
            }
            {((props.screenName == 'expansion' || props.screenName == 'Expansion') && (props.isPurchased == 0 && props.item.isLive == '0')) &&
                <View style={{ position: 'absolute', height: _scale(105), right: 0, }}>
                    < Image
                        source={require('../../assets/images/comingSoon.png')}
                        style={{ width: _scale(75), height: _scale(100), right: 0 }}
                    />
                </View>
            }
            {((props.screenName == 'myWay' || props.screenName == 'My Way') && (props.item.type == 'trophy')) &&
                <View style={{ position: 'absolute', height: _scale(105), right: 0, }}>
                    < Image
                        source={require('../../assets/images/freeVideo.png')}
                        style={{ width: _scale(75), height: _scale(100), right: 0 }}
                    />
                </View>
            }

        </TouchableOpacity >
    )
}

