export default class PivotModel {
    constructor(userId = '', videoId = '') {
        this.userId = userId,
            this.videoId = videoId
    }

    initWithData(res) {
        this.userId = res['user_id'] || '',
            this.videoId = res['video_id'] || ''
        return this;
    }
}