import { View, Platform } from "react-native";
import React, { useEffect } from "react";
import { commonStyles } from "../../utils/commonStyles";
import AsyncStorage from "@react-native-async-storage/async-storage";
import constant from "../../constant";

export default function MainScreen({ navigation }) {

    useEffect(() => {
        AsyncStorage.getItem(constant.appKey.isVerifedUser).then(validUser => {
            // console.log('validUser', validUser);
            if (validUser == "true") {
                Platform.OS == 'web' ? navigation.replace('WebDrawerNavigator') : navigation.replace('SplashView')

            } else {
                Platform.OS == 'web' ? navigation.replace('WelcomeContainer') : navigation.replace('Welcome')
            }
        });
    }, [])

    return (
        <View style={commonStyles.mainContainer}>
        </View>
    )

}