const AppStrings = {
    mainScreen: 'MainScreen',
    welcome: 'Welcome',
    welcomeContainer: 'WelcomeContainer',
    authStack: 'AuthStack',
    bottomTabBar: 'BottomTabBar',
    webPage: 'WebPage',
    contactUs: 'ContactUs',
    loginScreen: 'Login',
    signUpScreen: 'SignUp',
    signUpContainer: 'SignUpContainer',
    loginContainer: 'LoginContainer',
    forgotPassword: 'ForgotPassword',
    forgotPasswordContainer: 'ForgotPasswordContainer',
    resetPassword: 'ResetPassword',
    myAccount: 'MyAccount',
    resetPasswordContainer: 'ResetPasswordContainer',
    myAccountContainer: 'MyAccountContainer',
    changePasswordContainer: 'ChangePasswordContainer',
    homeStack: 'HomeStack',
    yourJourneyStack: 'YourJourneyScreen',
    theWayStack: 'TheWayStack',
    expansionStack: 'ExpansionStack',
    sourceStack: 'SourceStack',
    homeScreen: 'Home',
    videoDetailsView: 'VideoDetailsView',
    yourJourneyScreen: 'YourJourney',
    theWayScreen: 'TheWay',
    tierDetailsScreen: 'TierDetails',
    expansionScreen: 'Expansion',
    expansionDetailScreen: 'ExpansionDetail',
    sourceScreen: 'Source',
    homeIcon: 'home',
    yourJourneyIcon: 'cart',
    theWayIcon: 'shopping-bag',
    expansionIcon: 'account',
    sourceIcon: 'sourcetree',
    nameRequired: 'Please enter full name',
    fullNameRequired: 'Please enter full name”',
    userNameRequired: 'Please enter user name',
    firstNameRequired: 'Please enter first name',
    lastNameRequired: 'Please enter last name',
    emailRequired: 'Please enter e-mail',
    validEmailAddress: 'Please enter valid e-mail',
    passwordRequired: 'Please enter password',
    oldPassRequired: 'Please enter old password',
    newPassRequired: 'Please enter new password',
    messageRequired: 'Please enter message',
    resetCodeRequired: 'Please enter reset code',
    confirmPassRequired: 'Please enter confirm password',
    confirmPassword: 'Confirm password does not match',
    sameConfirmPassword: 'Please enter same password',
    oldAndNewPasswordSame: 'Old password and new password are same',
    acceptTermsConditions: 'Please accept EULA, TOS and Privacy Policy.',
    signUpSuccess: 'Account create successfully',
    contactUsMessage: 'Welcome friends, please leave your request on this page. We are very grateful you stopped by and look forward to answering your questions.',
    myAccountPage: 'My Account',
    termsandConditions: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
    logout: 'Logout',
    paymentMessage: 'You will be redirected on payment method. it might take a few seconds.',
    refreshMessage: 'Please do not refresh the page or click the "Back" or "Close" button of your browser.',
    changePasswordSuccess: 'Password successfully changed',
    changePasswordText: 'Change Password',
    changePassword: 'ChangePassword',
    profileUpdated: 'Profile updated successfully',
    appName: 'The Way',
    acceptText: ' I have read and accept the',
    eulaText: ' EULA,',
    tosText: ' TOS',
    privacyPolicyText: ' and Privacy Policy.',
    videoPlayMessage: 'To access this video, you need to complete the prior levels first. Keep going and unlocking new levels to access this content!',
    // videoPlaySubscribeMessage: `Oops! It looks like you're currently unsubscribed.Subscribe now to receive all the latest set of videos! Click the button below to subscribe.`,
    videoPlaySubscribeMessage: `Oops. It looks like you're not currently subscribed. Subscribe now to begin your Experience. Click the button below to subscribe.`,
    deleteAccount: 'DeleteAccount',
    deleteAccountMessage: `Before deleting your account, please take these steps:\n
1. If you have an active subscription, please go to settings > cancel the subscription to avoid any future charges with respect to your subscription.\n
2. If you have any expansion videos, they will be lost upon deleting your account. `,
    deleteMessagePopup: 'Your account will be deleted and all your saved/purchased data will be lost.',

    // For Web //
    homeWebScreen: 'HomeWeb',
    homeWebStack: 'HomeWebStack',
    yourJourneyWebScreen: 'YourJourneyWeb',
    yourJourneyWebStack: 'YourJourneyWebStack',
    theWayWebScreen: 'TheWayWeb',
    theWayWebStack: 'TheWayWebStack',
    expansionWebScreen: 'ExpansionWeb',
    expansionDetailWebScreen: 'ExpansionDetailWeb',
    expansionWebStack: 'ExpansionWebStack',
    sourceWebScreen: 'SourceWeb',
    sourceWebStack: 'SourceWebStack',
    webTierDetails: 'WebTierDetails',
    contactUsWebScreen: 'ContactUsWeb',
    webRightHeaderText: 'My Account',
    videoPlaySubscribeMessageWeb: `Oops. It looks like you're not currently subscribed. Subscribe now to begin your Experience.`,
}
export default AppStrings;