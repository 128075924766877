import { TouchableOpacity, Text } from "react-native";
import DefaultValues from "../../constant/defaultValues";
import { commonStyles } from '../../utils/commonStyles'

export default function WebButton(props) {
    return (
        <TouchableOpacity
            style={[commonStyles.buttonStyle, props.buttonStyle]}
            onPress={props.onPress}>
            <Text style={[commonStyles.buttonTitleStyle, { ...props.buttonTileStyle }]}>{props.title}</Text>
        </TouchableOpacity>
    )
}

WebButton.defaultProps = {
    title: DefaultValues.buttonValue.title,
}