import UpdateProfileRequest from '../../models/UpdateProfileModel';
import Constant from '../../constant';
import ApiManager from '../../services/apiManager';
var apiManager = ApiManager.getInstance();
let updateProfileEndPoint = Constant.apiEndPoints.updateProfile;

export const onUpdateProile = async (updateProfileParam: UpdateProfileRequest) => new Promise((resolve, reject) => {

    let requestParameter = {
        'email': updateProfileParam.email,
        'first_name': updateProfileParam.firstName,
        'last_name': updateProfileParam.lastName,
    }

    apiManager.onPostApi(updateProfileEndPoint, requestParameter).then((responseData) => {
        if (responseData.json.status == Constant.successCode) {
            // console.log('----Update Profile success Response : ', responseData.json.data)
            resolve(responseData)
        }
    }).catch((error) => {
        // console.log("Update Profile Error:", error);
        reject(error)
    })
})