import ChangePasswordRequest from '../../models/ChangePasswordModel';
import ApiManager from '../../services/apiManager';
import Constant from '../../constant';
var apiManager = ApiManager.getInstance();
let changePasswordEndPoint = Constant.apiEndPoints.changePassword;

export const onChangePassword = async (changePasswordParam: ChangePasswordRequest) => new Promise((resolve, reject) => {

    let requestParameter = {
        'oldPassword': changePasswordParam.oldPassword,
        'newPassword': changePasswordParam.newPassword
    }

    apiManager.onPostApi(changePasswordEndPoint, requestParameter).then((responseData) => {
        if (responseData.json.status == Constant.successCode) {
            // console.log('----ChangePassword success Response : ', responseData.json.data)
            resolve()
        }
    }).catch((error) => {
        // console.log("ChangePassword Error:", error);
        reject()
    })
})