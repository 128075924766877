import { StyleSheet } from 'react-native'
import AppColor from '../../../constant/colors'
import AppFonts from '../../../constant/fonts'
import { _scale } from '../../../utils'

export default StyleSheet.create({
    headerStyle: {
        marginTop: _scale(5),
        marginBottom: _scale(5)
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'center'
    },
    menuContainer: {
        position: 'absolute',
        right: 0,
        padding: _scale(15)
    },
    menuIcon: {
        height: _scale(20),
        width: _scale(20)
    },
    itemSubContainerStyle: {
        marginHorizontal: _scale(15),
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: _scale(15),
    },
    itemTitleStyle: {
        flex: 1,
        fontFamily: AppFonts.robototMedium,
        color: AppColor.white,
        fontSize: _scale(14)
    },
    dividerStyle: {
        borderBottomWidth: 1,
        borderBottomColor: AppColor.white,
        marginHorizontal: _scale(15)
    },
    itemTitleStyleRed: {
        flex: 1,
        fontFamily: AppFonts.robototMedium,
        color: AppColor.red,
        fontSize: _scale(14)
    },
})