import { View, Text, FlatList, TouchableOpacity, Linking, useWindowDimensions } from "react-native";
import { gradientBackground } from "../../../utils";
import { commonStyles } from "../../../utils/commonStyles";
import { AntDesign } from '@expo/vector-icons'
import constant from "../../../constant";
import styles from './index.style'
import AppStrings from "../../../constant/appStrings";
import WebHeader from "../../../components/WebHeader.js";
import { useState } from "react";
import MyStorage from "../../../services/storage";

export default function Source({ navigation }) {


    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;

    useState(() => {
        if (MyStorage.getItem(constant.appKey.kAuthToken) == '') {
            window.location.href = window.location.origin
            return;
        }

    }, [])

    const openWebPage = async (pageName) => {
        let url = '';
        if (pageName == 'FAQ') {
            url = constant.URL.FAQ_URL
            window.location.href = url
        } else if (pageName == 'About Us') {
            url = constant.URL.ABOUT_US_URL
            window.location.href = url
        } else if (pageName == 'Terms of Service') {
            url = constant.URL.TnC
            window.location.href = url
        } else if (pageName == 'Testimonials') {
            url = constant.URL.TESTIMONIAL
            window.location.href = url
        } else if (pageName == 'EULA') {
            url = constant.URL.EULA
            window.location.href = url
        } else if (pageName == 'Privacy Policy') {
            url = constant.URL.PrivacyPolicy
            window.location.href = url
        } else {
            navigation.navigate(AppStrings.contactUsWebScreen)
        }

    };

    const renderItem = ({ item, index }) => {
        return (
            <View key={'ItemContainer'}>
                <TouchableOpacity onPress={() => openWebPage(item.title)} key={'ItemSubContainer'} style={styles.itemSubContainerStyle}>
                    <Text style={styles.itemTitleStyle}>{item.title}</Text>
                    <AntDesign name="arrowright" size={24} color="white" />
                </TouchableOpacity>
                <View style={styles.dividerStyle} />
            </View>
        )
    }
    return (
        <View style={commonStyles.mainContainer} key={'MainContainer'}>
            {gradientBackground()}
            <WebHeader navigation={navigation} titles={["Source"]} />
            <View style={[commonStyles.mainContainer, { backgroundColor: 'clear' }]}>
                <View style={commonStyles.webSubContainerStyle} key={'SubContainer'}>
                    <View style={[commonStyles.webBorderViewStyle, { width: isLargeScreen ? '50%' : '90%', }]} key={'BorderView'}>
                        <View style={{ flex: 1 }}>
                            <FlatList
                                data={constant.sourceData}
                                renderItem={renderItem}
                            />
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}