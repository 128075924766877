import { View, Image, Text, TouchableOpacity, useWindowDimensions, StyleSheet } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient';
import { useState } from 'react'
import { _scale } from '../../../utils';
import { DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer';
import AppFonts from '../../../constant/fonts';
import { MaterialIcons } from '@expo/vector-icons'
import AppColor from '../../../constant/colors';
import { onLogout } from '../../../controllers/LogoutController'
import AppStrings from '../../../constant/appStrings';
import AppLoader from '../../../components/appLoader/index.web';
import MyStorage from '../../../services/storage';
import constant from '../../../constant';

export default function CustomDrawerContent(props) {
    const [isLoading, setIsLoading] = useState(false)
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;

    //-----API CALL----//
    const callLogoutAPi = () => {


        if (confirm("Are you sure you want to log out?") == true) {

            setIsLoading(true)
            // console.log("props", props.navigation);
            onLogout().then(() => {
                MyStorage.setItem(constant.appKey.isComeFromLogin, '1')
                setIsLoading(false)
                props.navigation.replace('AuthStack', { screen: AppStrings.welcomeContainer })
                // console.log("logout success");
            }).catch((error) => {
                setIsLoading(false)
                // console.log("logout error");
            })
        }



    }

    return (
        <View style={{ flex: 1 }}>
            <DrawerContentScrollView {...props}>
                <LinearGradient colors={['#283C54', '#000000']} style={styles.logoSectionStyle}>
                    <TouchableOpacity onPress={() => window.location.href = window.location.origin} >
                        <Image source={require('../../../assets/images/appicon.png')} style={styles.logoIconStyle} />
                    </TouchableOpacity>
                    {/* <Text style={styles.appNameTextStyle}>The Way App</Text> */}
                </LinearGradient>
                <View style={{ flex: 1, paddingTop: 40 }}>
                    <DrawerItemList  {...props} />
                </View>
            </DrawerContentScrollView>
            <View style={styles.footerStyle}>
                <TouchableOpacity onPress={callLogoutAPi} style={styles.logoutContainerStyle}>
                    <MaterialIcons name="logout" size={30} color="white" />
                    {isLargeScreen &&
                        <Text style={styles.logOutTextStyle}>Logout</Text>
                    }
                </TouchableOpacity>
            </View>
            {isLoading && <AppLoader />}
        </View>
    );
}

const styles = StyleSheet.create({
    logoSectionStyle: {
        width: '100%',
        height: _scale(60),
        justifyContent: 'center',
        alignItems: 'center',
        // borderBottomWidth: 1,
        borderBottomColor: AppColor.white
    },
    logoIconStyle: {
        height: _scale(50),
        width: _scale(50)
    },
    appNameTextStyle: {
        color: AppColor.white,
        fontFamily: AppFonts.robotoBold,
        fontSize: _scale(18),
        marginTop: _scale(15),
        textAlign: 'center'
    },
    footerStyle: {
        padding: _scale(20),
        borderTopWidth: 1,
        borderTopColor: AppColor.webBg
    },
    logoutContainerStyle: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    logOutTextStyle: {
        fontFamily: AppFonts.robotoBold,
        marginLeft: _scale(10),
        color: AppColor.white
    }

})