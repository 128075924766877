import UserModel from "../UserModel";
import LevelModel from "../LevelModel";
import TierVideoModel from "../TierVideoModel";

export default class LevelResponseModel {
    userModel: UserModel;
    levelModel: LevelModel;
    tierVideoModel: TierVideoModel
    constructor() {

    }
    initWithRes(res) {
        this.userModel = new UserModel().initWithData(res['user'])
        let tierVideoArray = res['tier_videos']
        let levelArray = res['levels']
        this.tierVideoModel = []
        this.levelModel = []
        if (tierVideoArray.length > 0) {
            tierVideoArray.forEach(element => {
                this.tierVideoModel.push(new TierVideoModel().initWithRes(element))
            });
        }
        if (levelArray.length > 0) {
            levelArray.forEach(element => {
                this.levelModel.push(new LevelModel().initWithRes(element))
            });
        }
        return this;
    }
}