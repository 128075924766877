import { StyleSheet } from 'react-native'
import AppColor from '../../../constant/colors'
import AppFonts from '../../../constant/fonts'
import { _scale } from '../../../utils'

export default StyleSheet.create({
    itemContainer: {
        flex: 1,
        marginHorizontal: _scale(15),
        marginVertical: _scale(20)
    },
    headerTitleStyle: {
        color: AppColor.white,
        fontSize: _scale(20),
        fontFamily: AppFonts.robotoBold,
        textAlign: 'center',
    },
    messageTextStyle: {
        textAlign: 'center',
        fontFamily: AppFonts.robototMedium,
        fontSize: _scale(16),
        color: AppColor.white,
        marginVertical: _scale(20),
        marginHorizontal: _scale(15),
    },
    filedContainerStyle: {
        marginTop: _scale(20),
    },
    buttonStyle: {
        borderRadius: _scale(25),
        marginTop: _scale(20),
        padding: _scale(15),
        backgroundColor: AppColor.lightRed,
    },
    messageTextStyleWeb: {
        textAlign: 'center',
        fontFamily: AppFonts.robototMedium,
        fontSize: _scale(14),
        color: AppColor.white,
        marginVertical: _scale(20),
        marginHorizontal: _scale(15),
    },
})