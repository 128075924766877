
import React, { useState, useRef, useEffect } from 'react'
import { StyleSheet, View, Easing, useWindowDimensions, Animated, TouchableOpacity } from 'react-native'
import { localImages } from '../../../../assets/images';
import { _scale, _verticalScale } from '../../../../utils';


export default function ActionableCircle(props) {

    const [circleWidth, setCircleWidth] = useState(0)
    const { height, width } = useWindowDimensions();

    function getLeftValue(percentage) {
        //  640 is default width of video
        return (640 * percentage) * (circleWidth / 640)
    }


    function getTopValue(percentage) {
        //  360 is default height of video
        return (360 * percentage) * (height / 360)
    }

    let MOBILE = [
        {
            id: 19,
            row_1_1: {
                left: getLeftValue(0.14),
                top: getTopValue(0.03),
            },
            row_1_2: {
                alignSelf: 'center',
                top: -(50 / 360 * 100)

            },
            row_1_3: {
                left: getLeftValue(0.62),
                top: getTopValue(0.05),
            },

            row_2_1: {
                left: -15,
                top: getTopValue(0.30),
            },

            row_2_2: {
                left: getLeftValue(0.39),
                top: getTopValue(0.30),
            },

            row_2_3: {
                left: getLeftValue(0.79),
                top: getTopValue(0.30),
            },

            row_3_1: {
                left: getLeftValue(0.15),
                top: getTopValue(0.56),
            },

            row_3_2: {
                left: getLeftValue(0.39),
                top: getTopValue(0.65),
            },

            row_3_3: {
                left: getLeftValue(0.62),
                top: getTopValue(0.56),
            },
        },

        {
            id: 20,
            row_1_1: {
                left: getLeftValue(0.02),
                top: getTopValue(0.07)
            },
            row_1_2: {
                alignSelf: 'center',
                top: -(70 / 360 * 100),
                paddingRight: _scale(3)
            },
            row_1_3: {
                left: getLeftValue(0.56),
                top: getTopValue(0.1),
            },

            row_2_1: {
                left: getLeftValue(-0.02),
                top: getTopValue(0.35),
            },

            row_2_2: {
                left: getLeftValue(0.25),
                top: getTopValue(0.32),
            },

            row_2_3: {
                left: getLeftValue(0.63),
                top: getTopValue(0.34),
            },

            row_3_1: {
                left: getLeftValue(0.01),
                top: getTopValue(0.58),
            },

            row_3_2: {
                left: getLeftValue(0.29),
                top: getTopValue(0.72),
            },

            row_3_3: {
                left: getLeftValue(0.56),
                top: getTopValue(0.55),
            },
        },

        {
            id: 21,
            row_1_1: {
                alignSelf: 'flex-start',
                left: getLeftValue(0.09),
                top: getTopValue(0.03)
            },
            row_1_2: {
                alignSelf: 'center',
                top: -(60 / 360 * 100)

            },
            row_1_3: {
                left: getLeftValue(0.67),
                top: getTopValue(0.055),
            },

            row_2_1: {
                left: -15,
                top: getTopValue(0.3),
            },

            row_2_2: {
                left: getLeftValue(0.385),
                top: getTopValue(0.29),
            },

            row_2_3: {
                left: getLeftValue(0.79),
                top: getTopValue(0.30),
            },

            row_3_1: {
                left: getLeftValue(0.1),
                top: getTopValue(0.55),
            },

            row_3_2: {
                left: getLeftValue(0.39),
                top: getTopValue(0.64),
            },

            row_3_3: {
                left: getLeftValue(0.68),
                top: getTopValue(0.55),
            },
        },

        {
            id: 22,
            row_1_1: {
                alignSelf: 'flex-start',
                left: getLeftValue(0.175),
                top: getTopValue(0.075)
            },
            row_1_2: {
                alignSelf: 'center',
                top: -(20 / 360 * 100),
                paddingLeft: _scale(11)

            },
            row_1_3: {
                left: getLeftValue(0.667),
                top: getTopValue(0.11),
            },

            row_2_1: {
                left: getLeftValue(0.01),
                top: getTopValue(0.31),
            },

            row_2_2: {
                left: getLeftValue(0.34),
                top: getTopValue(0.15),
            },

            row_2_3: {
                left: getLeftValue(0.768),
                top: getTopValue(0.305),
            },

            row_3_1: {
                left: getLeftValue(0.149),
                top: getTopValue(0.52),
            },

            row_3_2: {
                left: getLeftValue(0.365),
                top: getTopValue(0.61),
            },

            row_3_3: {
                left: getLeftValue(0.62),
                top: getTopValue(0.53),
            },
        },

        {
            id: 23,
            row_1_1: {
                alignSelf: 'flex-start',
                left: getLeftValue(0.08),
                top: getTopValue(0.00),
            },
            row_1_2: {
                alignSelf: 'center',
                top: -(110 / 360 * 100)
            },
            row_1_3: {
                left: getLeftValue(0.67),
                top: getTopValue(0.00),
            },

            row_2_1: {
                left: getLeftValue(0.0),
                top: getTopValue(0.3),
            },

            row_2_2: {
                left: getLeftValue(0.34),
                top: getTopValue(0.22),
            },

            row_2_3: {
                left: getLeftValue(0.75),
                top: getTopValue(0.3),
            },

            row_3_1: {
                left: getLeftValue(0.08),
                top: getTopValue(0.56),
            },

            row_3_2: {
                left: getLeftValue(0.39),
                top: getTopValue(0.65),
            },

            row_3_3: {
                left: getLeftValue(0.63),
                top: getTopValue(0.58),
            },
        },

        {
            id: 24,
            row_1_1: {
                alignSelf: 'flex-start',
                left: getLeftValue(0.15),
                top: getTopValue(0.03),
            },
            row_1_2: {
                alignSelf: 'center',
                top: -(60 / 360 * 100)
            },
            row_1_3: {
                left: getLeftValue(0.6),
                top: getTopValue(0.03),
            },

            row_2_1: {
                left: getLeftValue(0.0),
                top: getTopValue(0.28),
            },

            row_2_2: {
                left: getLeftValue(0.37),
                top: getTopValue(0.28),
            },

            row_2_3: {
                left: getLeftValue(0.75),
                top: getTopValue(0.28),
            },

            row_3_1: {
                left: getLeftValue(0.15),
                top: getTopValue(0.56),
            },

            row_3_2: {
                left: getLeftValue(0.375),
                top: getTopValue(0.61),
            },

            row_3_3: {
                left: getLeftValue(0.6),
                top: getTopValue(0.53),
            },
        },
        {
            id: 29,
            row_1_1: {
                alignSelf: 'flex-start',
                left: getLeftValue(0.17),
                top: getTopValue(0.05),
            },
            row_1_2: {
                alignSelf: 'center',
                top: -(40 / 360 * 100)

            },
            row_1_3: {
                left: getLeftValue(0.61),
                top: getTopValue(0.05),
            },

            row_2_1: {
                left: 8,
                top: getTopValue(0.31),
            },

            row_2_2: {
                left: getLeftValue(0.39),
                top: getTopValue(0.31),
            },

            row_2_3: {
                left: getLeftValue(0.76),
                top: getTopValue(0.30),
            },

            row_3_1: {
                left: getLeftValue(0.17),
                top: getTopValue(0.59),
            },

            row_3_2: {
                left: getLeftValue(0.39),
                top: getTopValue(0.63),
            },

            row_3_3: {
                left: getLeftValue(0.61),
                top: getTopValue(0.56),
            },
        },
        {
            id: 30,
            row_1_1: {
                alignSelf: 'flex-start',
                left: getLeftValue(0.19),
                top: getTopValue(0.05),
            },
            row_1_2: {
                alignSelf: 'center',
                left: getLeftValue(0.41),
                top: -(45 / 360 * 100)
            },
            row_1_3: {
                left: getLeftValue(0.64),
                top: getTopValue(0.07),
            },

            row_2_1: {
                left: getLeftValue(0.03),
                top: getTopValue(0.32),
            },

            row_2_2: {
                left: getLeftValue(0.42),
                top: getTopValue(0.29),
            },

            row_2_3: {
                left: getLeftValue(0.75),
                top: getTopValue(0.32),
            },

            row_3_1: {
                left: getLeftValue(0.17),
                top: getTopValue(0.56),
            },

            row_3_2: {
                left: getLeftValue(0.42),
                top: getTopValue(0.62),
            },

            row_3_3: {
                left: getLeftValue(0.66),
                top: getTopValue(0.57),
            },
        },
        {
            id: 31,
            row_1_1: {
                left: getLeftValue(0.06),
                top: getTopValue(0.13)
            },
            row_1_2: {
                alignSelf: 'center',
                top: -(0 / 360 * 100),
                paddingRight: _scale(3),
                left: getLeftValue(0.32),
            },
            row_1_3: {
                left: getLeftValue(0.62),
                top: getTopValue(0.14),
            },

            row_2_1: {
                left: getLeftValue(-0.02),
                top: getTopValue(0.35),
            },

            row_2_2: {
                left: getLeftValue(0.30),
                top: getTopValue(0.32),
            },

            row_2_3: {
                left: getLeftValue(0.68),
                top: getTopValue(0.36),
            },

            row_3_1: {
                left: getLeftValue(0.04),
                top: getTopValue(0.59),
            },

            row_3_2: {
                left: getLeftValue(0.31),
                top: getTopValue(0.69),
            },

            row_3_3: {
                left: getLeftValue(0.61),
                top: getTopValue(0.61),
            },
        },
        {
            id: 32,
            row_1_1: {
                alignSelf: 'flex-start',
                left: getLeftValue(0.10),
                top: getTopValue(0.11)
            },
            row_1_2: {
                alignSelf: 'center',
                top: 10,
                left: getLeftValue(0.40),

            },
            row_1_3: {
                left: getLeftValue(0.65),
                top: getTopValue(0.13),
            },

            row_2_1: {
                left: 37,
                top: getTopValue(0.36),
            },

            row_2_2: {
                left: getLeftValue(0.400),
                top: getTopValue(0.35),
            },

            row_2_3: {
                left: getLeftValue(0.73),
                top: getTopValue(0.35),
            },

            row_3_1: {
                left: getLeftValue(0.12),
                top: getTopValue(0.61),
            },

            row_3_2: {
                left: getLeftValue(0.40),
                top: getTopValue(0.70),
            },

            row_3_3: {
                left: getLeftValue(0.65),
                top: getTopValue(0.60),
            },
        },
        {
            id: 33,
            row_1_1: {
                alignSelf: 'flex-start',
                left: getLeftValue(0.16),
                top: getTopValue(0.06),
            },
            row_1_2: {
                alignSelf: 'center',
                top: -(40 / 360 * 100)

            },
            row_1_3: {
                left: getLeftValue(0.61),
                top: getTopValue(0.05),
            },

            row_2_1: {
                left: 8,
                top: getTopValue(0.31),
            },

            row_2_2: {
                left: getLeftValue(0.39),
                top: getTopValue(0.31),
            },

            row_2_3: {
                left: getLeftValue(0.76),
                top: getTopValue(0.30),
            },

            row_3_1: {
                left: getLeftValue(0.16),
                top: getTopValue(0.59),
            },

            row_3_2: {
                left: getLeftValue(0.39),
                top: getTopValue(0.63),
            },

            row_3_3: {
                left: getLeftValue(0.61),
                top: getTopValue(0.56),
            },
        },
        {
            id: 34,
            row_1_1: {
                alignSelf: 'flex-start',
                left: getLeftValue(0.16),
                top: getTopValue(0.06),
            },
            row_1_2: {
                alignSelf: 'center',
                top: -(40 / 360 * 100)

            },
            row_1_3: {
                left: getLeftValue(0.61),
                top: getTopValue(0.05),
            },

            row_2_1: {
                left: 8,
                top: getTopValue(0.31),
            },

            row_2_2: {
                left: getLeftValue(0.38),
                top: getTopValue(0.31),
            },

            row_2_3: {
                left: getLeftValue(0.76),
                top: getTopValue(0.30),
            },

            row_3_1: {
                left: getLeftValue(0.16),
                top: getTopValue(0.59),
            },

            row_3_2: {
                left: getLeftValue(0.39),
                top: getTopValue(0.63),
            },

            row_3_3: {
                left: getLeftValue(0.61),
                top: getTopValue(0.56),
            },
        },
        //For Tier3 Videos
        {
            //Color
            id: 39,
            row_1_1: {
                alignSelf: 'flex-start',
                left: getLeftValue(0.16),
                top: getTopValue(0.06),
            },
            row_1_2: {
                alignSelf: 'center',
                top: -(41 / 360 * 100)

            },
            row_1_3: {
                left: getLeftValue(0.61),
                top: getTopValue(0.04),
            },

            row_2_1: {
                left: 9,
                top: getTopValue(0.31),
            },

            row_2_2: {
                left: getLeftValue(0.39),
                top: getTopValue(0.31),
            },

            row_2_3: {
                left: getLeftValue(0.76),
                top: getTopValue(0.30),
            },

            row_3_1: {
                left: getLeftValue(0.17),
                top: getTopValue(0.59),
            },

            row_3_2: {
                left: getLeftValue(0.39),
                top: getTopValue(0.63),
            },

            row_3_3: {
                left: getLeftValue(0.61),
                top: getTopValue(0.55),
            },
        },
        {
            //Characters
            id: 40,
            row_1_1: {
                left: getLeftValue(0.06),
                top: getTopValue(0.14)
            },
            row_1_2: {
                left: getLeftValue(0.36),
                top: getTopValue(0.04),
            },
            row_1_3: {
                left: getLeftValue(0.61),
                top: getTopValue(0.13),
            },

            row_2_1: {
                left: getLeftValue(-0.00),
                top: getTopValue(0.37),
            },

            row_2_2: {
                left: getLeftValue(0.36),
                top: getTopValue(0.37),
            },

            row_2_3: {
                left: getLeftValue(0.69),
                top: getTopValue(0.37),
            },

            row_3_1: {
                left: getLeftValue(0.06),
                top: getTopValue(0.60),
            },

            row_3_2: {
                left: getLeftValue(0.29),
                top: getTopValue(0.66),
            },

            row_3_3: {
                left: getLeftValue(0.62),
                top: getTopValue(0.60),
            },
        },
        {
            //numberHz
            id: 41,
            row_1_1: {
                left: getLeftValue(0.04),
                top: getTopValue(0.10)
            },
            row_1_2: {
                left: getLeftValue(0.33),
                top: getTopValue(0.02),
            },
            row_1_3: {
                left: getLeftValue(0.58),
                top: getTopValue(0.11),
            },

            row_2_1: {
                left: getLeftValue(-0.02),
                top: getTopValue(0.34),
            },

            row_2_2: {
                left: getLeftValue(0.33),
                top: getTopValue(0.35),
            },

            row_2_3: {
                left: getLeftValue(0.66),
                top: getTopValue(0.35),
            },

            row_3_1: {
                left: getLeftValue(0.04),
                top: getTopValue(0.58),
            },

            row_3_2: {
                left: getLeftValue(0.32),
                top: getTopValue(0.68),
            },

            row_3_3: {
                left: getLeftValue(0.58),
                top: getTopValue(0.57),
            },
        },
        {
            //Diamond
            id: 42,
            row_1_1: {
                alignSelf: 'flex-start',
                left: getLeftValue(0.16),
                top: getTopValue(0.06),
            },
            row_1_2: {
                alignSelf: 'center',
                top: -(41 / 360 * 100)

            },
            row_1_3: {
                left: getLeftValue(0.61),
                top: getTopValue(0.05),
            },

            row_2_1: {
                left: 9,
                top: getTopValue(0.31),
            },

            row_2_2: {
                left: getLeftValue(0.38),
                top: getTopValue(0.31),
            },

            row_2_3: {
                left: getLeftValue(0.76),
                top: getTopValue(0.29),
            },

            row_3_1: {
                left: getLeftValue(0.16),
                top: getTopValue(0.59),
            },

            row_3_2: {
                left: getLeftValue(0.39),
                top: getTopValue(0.64),
            },

            row_3_3: {
                left: getLeftValue(0.61),
                top: getTopValue(0.57),
            },
        },
        {
            //Star
            id: 43,
            row_1_1: {
                alignSelf: 'flex-start',
                left: getLeftValue(0.17),
                top: getTopValue(0.06),
            },
            row_1_2: {
                left: getLeftValue(0.42),
                top: getTopValue(-0.03),
            },
            row_1_3: {
                left: getLeftValue(0.63),
                top: getTopValue(0.07),
            },

            row_2_1: {
                left: getLeftValue(0.03),
                top: getTopValue(0.3),
            },

            row_2_2: {
                left: getLeftValue(0.41),
                top: getTopValue(0.29),
            },

            row_2_3: {
                left: getLeftValue(0.75),
                top: getTopValue(0.3),
            },

            row_3_1: {
                left: getLeftValue(0.16),
                top: getTopValue(0.55),
            },

            row_3_2: {
                left: getLeftValue(0.41),
                top: getTopValue(0.64),
            },

            row_3_3: {
                left: getLeftValue(0.67),
                top: getTopValue(0.56),
            },
        },
        {
            //Galaxy
            id: 44,
            row_1_1: {
                alignSelf: 'flex-start',
                left: getLeftValue(0.16),
                top: getTopValue(0.06),
            },
            row_1_2: {
                alignSelf: 'center',
                top: -(41 / 360 * 100)

            },
            row_1_3: {
                left: getLeftValue(0.61),
                top: getTopValue(0.06),
            },

            row_2_1: {
                left: 12,
                top: getTopValue(0.31),
            },

            row_2_2: {
                left: getLeftValue(0.39),
                top: getTopValue(0.32),
            },

            row_2_3: {
                left: getLeftValue(0.76),
                top: getTopValue(0.30),
            },

            row_3_1: {
                left: getLeftValue(0.17),
                top: getTopValue(0.60),
            },

            row_3_2: {
                left: getLeftValue(0.38),
                top: getTopValue(0.64),
            },

            row_3_3: {
                left: getLeftValue(0.61),
                top: getTopValue(0.56),
            },
        },

    ]
    const [rotateValueHolder] = useState(() => new Animated.Value(0));
    useEffect(() => {
        // startImageRotateFunction()
    }, [])

    const startImageRotateFunction = () => {
        Animated.loop(
            Animated.timing(rotateValueHolder, {
                toValue: 1,
                duration: 3000,
                easing: Easing.linear,
                useNativeDriver: false,
            })
        ).start();
    };

    const RotateData = rotateValueHolder.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg'],
    });

    const getRingSize = (ringPosition, id) => {

        let defaultRingPosition = 145

        switch (ringPosition) {
            case 'row_1_1':
                if (id == 22) {
                    defaultRingPosition = 123
                } else if (id == 23) {
                    defaultRingPosition = 160
                } else if (id == 20) {
                    defaultRingPosition = 200
                } else if (id == 31) {
                    defaultRingPosition = 150
                } else if (id == 32) {
                    defaultRingPosition = 160
                } else if (id == 41) {
                    defaultRingPosition = 180
                }
                break;
            case 'row_1_2':
                if (id == 22) {
                    defaultRingPosition = 90
                } else if (id == 23) {
                    defaultRingPosition = 160
                } else if (id == 20) {
                    defaultRingPosition = 170
                } else if (id == 31 || id == 41) {
                    defaultRingPosition = 180
                } else if (id == 32) {
                    defaultRingPosition = 150
                }
                break;
            case 'row_1_3':
                if (id == 22) {
                    defaultRingPosition = 100
                } else if (id == 23) {
                    defaultRingPosition = 150
                } else if (id == 20 || id == 41) {
                    defaultRingPosition = 180
                } else if (id == 31) {
                    defaultRingPosition = 140
                } else if (id == 32) {
                    defaultRingPosition = 140
                }
                break;
            case 'row_2_1':
                if (id == 22) {
                    defaultRingPosition = 135
                } else if (id == 20) {
                    defaultRingPosition = 170
                } else if (id == 31) {
                    defaultRingPosition = 170
                } else if (id == 32) {
                    defaultRingPosition = 155
                } else if (id == 41) {
                    defaultRingPosition = 180
                }
                break;
            case 'row_2_2':
                if (id == 22) {
                    defaultRingPosition = 210
                } else if (id == 23) {
                    defaultRingPosition = 200
                } else if (id == 20) {
                    defaultRingPosition = 190
                } else if (id == 31) {
                    defaultRingPosition = 195
                } else if (id == 32) {
                    defaultRingPosition = 150
                } else if (id == 41) {
                    defaultRingPosition = 180
                }
                break;
            case 'row_2_3':
                if (id == 22) {
                    defaultRingPosition = 140
                } else if (id == 23) {
                    defaultRingPosition = 160
                } else if (id == 20 || id == 41) {
                    defaultRingPosition = 180
                } else if (id == 31) {
                    defaultRingPosition = 150
                } else if (id == 32) {
                    defaultRingPosition = 150
                }
                break;
            case 'row_3_1':
                if (id == 22) {
                    defaultRingPosition = 160
                } else if (id == 23 || id == 41) {
                    defaultRingPosition = 180
                } else if (id == 20) {
                    defaultRingPosition = 165
                } else if (id == 31) {
                    defaultRingPosition = 165
                } else if (id == 32) {
                    defaultRingPosition = 140
                }
                break;
            case 'row_3_2':
                if (id == 22) {
                    defaultRingPosition = 170
                } else if (id == 20 || id == 41) {
                    defaultRingPosition = 180
                } else if (id == 31) {
                    defaultRingPosition = 180
                } else if (id == 32) {
                    defaultRingPosition = 150
                } else if (id == 40) {
                    defaultRingPosition = 200
                }
                break;
            case 'row_3_3':
                if (id == 22) {
                    defaultRingPosition = 150
                } else if (id == 20 || id == 41) {
                    defaultRingPosition = 180
                } else if (id == 31) {
                    defaultRingPosition = 140
                } else if (id == 32) {
                    defaultRingPosition = 150
                }
                break;
            default:
                defaultRingPosition = 130
                break;
        }


        if (id == 24) {
            defaultRingPosition = 160
        }

        return defaultRingPosition
    }

    const renderRings = (ringPosition) => {

        let defaultRingPosition = getRingSize(ringPosition, props.currentPlayingVideoInfo.id)

        let ringSizeH = defaultRingPosition * ((height) / 360);
        let ringSizeW = defaultRingPosition * ((circleWidth) / 640);

        if (props.currentPlayingVideoInfo.id == 41) {
            ringSizeW = (defaultRingPosition * 1.4) * ((circleWidth) / 640);
            ringSizeH = (defaultRingPosition / 1.5) * ((height) / 360);
        }
        if (props.currentPlayingVideoInfo.id == 20 || props.currentPlayingVideoInfo.id == 31
            || props.currentPlayingVideoInfo.id == 40) {
            ringSizeW = (defaultRingPosition * 1.5) * ((circleWidth) / 640);
            ringSizeH = (defaultRingPosition / 1.5) * ((height) / 360);
        }
        if (props.currentPlayingVideoInfo.id == 32) {
            ringSizeW = (defaultRingPosition * 1.1) * ((circleWidth) / 640);
            ringSizeH = (defaultRingPosition / 1.4) * ((height) / 360);
        }

        return (
            <TouchableOpacity onPress={() => props.onPressRing()}>
                <Animated.Image
                    source={localImages.ring}
                    resizeMode={'stretch'}
                    style={{
                        width: ringSizeW,
                        height: ringSizeH,

                        // transform: [
                        //     {
                        //         rotate: RotateData
                        //     }
                        // ]
                    }}
                />
            </TouchableOpacity>
        )
    }

    function getRingStyle(key) {

        let id = props.currentPlayingVideoInfo.id
        let styleObj = MOBILE.find(element => element.id == id)
        if (styleObj == undefined) {
            styleObj = MOBILE[0]
        }
        return styleObj[key]
    }

    return (
        < View onLayout={(event) => {
            var { x, y, width, height } = event.nativeEvent.layout;
            setCircleWidth(width)
        }} style={styles.mainView}>

            <View key={'row_1_1'} style={[styles.ringPosition, getRingStyle('row_1_1')]}>
                {props.enableRings.includes(0) &&
                    renderRings('row_1_1')
                }
            </View>

            <View key={'row_1_2'} style={[styles.ringPosition, getRingStyle('row_1_2')]}>
                {props.enableRings.includes(1) &&
                    renderRings('row_1_2')
                }
            </View>

            <View key={'row_1_3'} style={[styles.ringPosition, getRingStyle('row_1_3')]}>
                {props.enableRings.includes(2) &&
                    renderRings('row_1_3')
                }
            </View>

            <View key={'row_2_1'} style={[styles.ringPosition, getRingStyle('row_2_1')]}>
                {props.enableRings.includes(3) &&
                    renderRings('row_2_1')
                }
            </View>

            <View key={'row_2_2'} style={[styles.ringPosition, getRingStyle('row_2_2')]}>
                {props.enableRings.includes(4) &&
                    renderRings('row_2_2')
                }
            </View>

            <View key={'row_2_3'} style={[styles.ringPosition, getRingStyle('row_2_3')]}>
                {props.enableRings.includes(5) &&
                    renderRings('row_2_3')
                }
            </View>

            <View key={'row_3_1'} style={[styles.ringPosition, getRingStyle('row_3_1')]}>
                {props.enableRings.includes(7) &&
                    renderRings('row_3_1')
                }
            </View>

            <View key={'row_3_2'} style={[styles.ringPosition, getRingStyle('row_3_2')]}>
                {props.enableRings.includes(6) &&
                    renderRings('row_3_2')
                }
            </View>

            <View key={'row_3_3'} style={[styles.ringPosition, getRingStyle('row_3_3')]}>
                {props.enableRings.includes(8) &&
                    renderRings('row_3_3')
                }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    mainView: {
        flex: 1,
    },

    ringPosition: {
        position: 'absolute',
        alignSelf: 'flex-start',

    }
})

