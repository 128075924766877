import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import AppStrings from '../constant/appStrings';
import AuthStack from './authStack';
import BottomTabBar from './tabs';
import { Ionicons } from '@expo/vector-icons'
import { Platform } from 'react-native';
import VideoPlayer from '../screens/tabsScreen/common/videoPlayer'
import WebDrawerNavigator from './webDrawerNavigator';
import WebPage from '../screens/extraScreen/webPage';
import SplashView from '../entry/SplashView';

const Stack = createStackNavigator();

export default function AppNavigation() {

    if (Platform.OS == 'web') {

        const config = {
            screens: {
                AuthStack: {
                    path: 'auth',
                    screens: {
                        WelcomeContainer: 'welcome',
                        LoginContainer: 'signin',
                        SignUpContainer: 'signup',
                        ForgotPasswordContainer: 'forgot-password',
                        ResetPasswordContainer: 'reset-password',
                        MyAccountContainer: 'account'
                    },
                },

                WebDrawerNavigator: {
                    path: '',
                    screens: {
                        HomeWebStack: {
                            path: 'home',
                            screens: {
                                HomeWeb: 'free-videos',
                                VideoDetailsView: 'details',
                                MyAccountContainer: 'account'
                            },
                        },
                        YourJourneyWebStack: {
                            path: 'your-journey',
                            screens: {
                                YourJourneyWeb: 'journey',
                                VideoDetailsView: 'details',
                                MyAccountContainer: 'account',
                                WebTierDetails: {
                                    path: 'journey-details',
                                    screens: {
                                        WebTierDetails: 'journey-details/:tierId',
                                        MyAccountContainer: 'account',
                                    },
                                },
                            },
                        },
                        TheWayWebStack: {
                            path: 'the-way',
                            screens: {
                                TheWayWeb: 'tiers',
                                WebTierDetails: 'tiers-details/:tierId',
                                VideoDetailsView: 'details',
                                MyAccountContainer: 'account',

                            },
                        },
                        ExpansionWebStack: {
                            path: 'expansion',
                            screens: {
                                ExpansionWeb: 'videos',
                                ExpansionDetailWeb: 'expansion-details',
                                MyAccountContainer: 'account',

                            },
                        },
                        SourceWebStack: {
                            path: 'source',
                            screens: {
                                SourceWeb: 'list',
                                ContactUsWeb: 'contact-us',
                                MyAccountContainer: 'account',
                            },
                        },

                    },
                }
            },
        };
        const linking = {
            config,
        };

        return (
            <NavigationContainer linking={linking} >
                <Stack.Navigator initialRouteName='AuthStack'>
                    <Stack.Screen name={AppStrings.authStack} component={AuthStack} options={{ headerShown: false }} />
                    <Stack.Screen name={'WebDrawerNavigator'} component={WebDrawerNavigator} options={{ headerShown: false }} />
                    <Stack.Screen name={'VideoPlayer'} component={VideoPlayer}
                        options={{
                            headerShown: false,
                            headerTitle: '',
                            headerBackTitle: '',
                            headerTintColor: 'white',
                            headerStyle: {
                                backgroundColor: 'black',
                                shadowOpacity: 0,
                                elevation: 0,
                            }
                        }} />
                </Stack.Navigator>
            </NavigationContainer>
        )
    } else {
        return (
            <NavigationContainer>
                <Stack.Navigator>
                    <Stack.Screen name={AppStrings.authStack} component={AuthStack} options={{ headerShown: false }} />
                    <Stack.Screen name={'SplashView'} component={SplashView} options={{ headerShown: false, presentation: 'modal' }} />
                    <Stack.Screen name={AppStrings.bottomTabBar} component={BottomTabBar} options={{ headerShown: false, animationEnabled: false }} />
                    <Stack.Screen name={AppStrings.webPage} component={WebPage} options={{
                        headerShown: false,
                        headerTitle: '',
                        headerTintColor: 'black',
                        headerBackTitle: ' ',

                    }} />
                    <Stack.Screen name={'VideoPlayer'} component={VideoPlayer}
                        options={({ navigation, route }) => ({
                            headerShown: false,
                            headerTitle: '',
                            headerBackTitle: '',
                            headerBackTitleVisible: false,
                            headerTintColor: 'white',
                            headerStyle: {
                                backgroundColor: 'black',
                                shadowOpacity: 0,
                                elevation: 0,
                            },
                            headerLeft: () => <Ionicons name="arrow-back-outline" size={30} color={'white'} onPress={() => navigation.pop()} style={{ padding: 10 }} />
                        })} />
                </Stack.Navigator>
            </NavigationContainer>
        )
    }

}