const AppColor = {
    white: '#f9f9f9',
    black: '#000000',
    grey: '#808080',
    red: '#FF0000',
    transparentGrey: 'rgba(120, 120,120, 0.5)',
    appBackground: '#454545',
    lightWhite: '#FFFFF9',
    lightRed: '#9A7E50',
    lightGrey: '#74869C',
    webBg: '#283C54',
    lightBlack: '#080808',
    transparentBlack: 'rgba(0, 0, 0, 0.5)',
    editTextBg: 'rgba(0, 0, 0, 0.3)'
}
export default AppColor;   