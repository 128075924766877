import UserModel from "../UserModel"

export default class SubscriptionModel {
    userModel: UserModel;

    constructor(
        id = 0,
        userId = 0,
        videoId = '',
        gatewayTransactionId = '',
        transactionStatus = '',
        paymentType = '',
        amountProcessed = '',
        expiresDateTime = '',
        expansionVideos = '',
        deviceType = ''

    ) {
        this.id = id,
            this.userId = userId,
            this.videoId = videoId,
            this.gatewayTransactionId = gatewayTransactionId,
            this.transactionStatus = transactionStatus,
            this.paymentType = paymentType,
            this.amountProcessed = amountProcessed,
            this.expiresDateTime = expiresDateTime,
            this.expansionVideos = expansionVideos,
            this.deviceType = deviceType
    }

    initWithData(res) {
        this.id = res["id"] || 0
        this.userId = res["user_id"] || 0
        this.videoId = res["video_id"] || ''
        this.gatewayTransactionId = res["gateway_transaction_id"] || ''
        this.transactionStatus = res["transaction_status"] || ''
        this.paymentType = res["payment_type"] || ''
        this.amountProcessed = res["amount_processed"] || ''
        this.expiresDateTime = res["expires_date_time"] || ''
        this.expansionVideos = res["expansion_videos"] || ''
        this.userModel = new UserModel().initWithData(res['user'])
        this.deviceType = res['device_type'] || ''

        return this
    }

}
