import LoginRequest from '../../models/LoginModel';
import ApiManager from '../../services/apiManager';
import Storage from '../../services/storage';
import Constant from '../../constant';
import * as utils from '../../utils'
var apiManager = ApiManager.getInstance();

let loginEndPoint = Constant.apiEndPoints.login;

export const onLogin = async (loginParam: LoginRequest) => new Promise((resolve, reject) => {

    let requestParameter = {
        'email': loginParam.email,
        'password': loginParam.password
    }

    apiManager.onPostApi(loginEndPoint, requestParameter).then((responseData) => {
        if (responseData.json.status == Constant.successCode) {
            // console.log('----Login success Response : ', responseData.json.data)
            Storage.setItem(Constant.appKey.isVerifedUser, true)
            Storage.setItem(Constant.appKey.kUserPassword, loginParam.password)
            utils.setLoginData(responseData.json.data)
            resolve()
        }
    }).catch((error) => {
        // console.log("Login Error:", error);
        reject()
    })
})