import ForgotPasswordRequest from '../../models/ForgotPasswordModel';
import ApiManager from '../../services/apiManager';
import Constant from '../../constant';
var apiManager = ApiManager.getInstance();

let forgotPasswordEndPoint = Constant.apiEndPoints.forgotPassword;

export const onForgot = async (forgotParam: ForgotPasswordRequest) => new Promise((resolve, reject) => {

    let requestParameter = {
        'email': forgotParam.email,
    }

    apiManager.onPostApi(forgotPasswordEndPoint, requestParameter).then((responseData) => {
        if (responseData.json.status == Constant.successCode) {
            // console.log('----forgotPassword success Response : ', responseData.json.data)
            resolve()
        }
    }).catch((error) => {
        // console.log("forgotPassword Error:", error);
        reject()
    })
})