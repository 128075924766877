import { View, Text, KeyboardAvoidingView, Platform, Image, ScrollView, Keyboard, useWindowDimensions, TouchableOpacity } from 'react-native'
import { useState, useRef } from 'react'
import { commonStyles } from '../../../utils/commonStyles'
import { bottomSafeArea, showErrorBanner, showSuccessBanner, topSafeArea, _scale } from '../../../utils'
import { Ionicons, MaterialIcons, AntDesign, Entypo } from '@expo/vector-icons'
import AppColor from '../../../constant/colors'
import styles from './index.style.web'
import AppTextInput from '../../../components/appTextInput'
import AppStrings from '../../../constant/appStrings'
import constant from '../../../constant'
import ResetPasswordRequest from '../../../models/ResetPasswordModel'
import { onResetPassword } from '../../../controllers/ResetPassword'
import WebButton from '../../../components/webButton'
import { LinearGradient } from 'expo-linear-gradient'
import AppLoader from '../../../components/appLoader/index.web'

export default function ResetPassword(props) {

    const [email, setEmail] = useState(props.email)
    const [resetCode, setResetCode] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isPassVisible, setPassVisible] = useState(false)
    const [crossIcon, setCrossIcon] = useState('')
    const [focus, setFocus] = useState('')
    const emailRef = useRef()
    const passwordRef = useRef()
    const resetCodeRef = useRef()

    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;


    const passVisible = () => {
        isPassVisible == true ? setPassVisible(false) : setPassVisible(true)
    }

    const checkValidation = () => {
        if (email.trim().length < 1) {
            showErrorBanner(AppStrings.emailRequired)
        } else if (!email.match(constant.emailRegex)) {
            showErrorBanner(AppStrings.validEmailAddress)
        } else if (resetCode.trim().length < 1) {
            showErrorBanner(AppStrings.resetCodeRequired)
        } else if (newPassword.trim().length < 1) {
            showErrorBanner(AppStrings.passwordRequired)
        }
        else {
            resetPasswordApiCall()
        }
    }

    function clearTextFilled(filledName) {
        if (filledName == 'email') {
            emailRef.current.clear()
            setEmail('')
            setCrossIcon('')
        } else if (filledName == 'resetCode') {
            resetCodeRef.current.clear()
            setResetCode('')
            setCrossIcon('')
        }
        else {
            passwordRef.current.clear()
            setNewPassword('')
            setCrossIcon('')
        }
    }

    //-------API CALL----------//

    const resetPasswordApiCall = () => {
        setIsLoading(true)
        Keyboard.dismiss()
        let resetPasswordRequest = new ResetPasswordRequest(email, resetCode, newPassword)
        onResetPassword(resetPasswordRequest).then(() => {
            showSuccessBanner('Password change successfully')
            props.props.replace('LoginContainer')
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false)
        })
    }

    return (
        <View style={[commonStyles.mainContainer, { borderRadius: _scale(20) }]}>
            <LinearGradient colors={['#283C54', '#000000']} style={[commonStyles.gradientBackGround, { borderRadius: 20, }]} />
            {topSafeArea()}
            <KeyboardAvoidingView
                behavior={Platform.OS === "ios" ? "padding" : null}
                style={{ flex: 1 }}>

                <TouchableOpacity onPress={() => window.location.href = window.location.origin}>
                    <Image
                        source={require('../../../assets/images/appicon.png')}
                        style={styles.logoStyle} />
                </TouchableOpacity>

                <ScrollView keyboardShouldPersistTaps={'handled'} style={{ marginBottom: _scale(15) }}>

                    <Text style={styles.headingText}>Reset Password</Text>

                    <View key={'FiledContainer'} style={[styles.filedContainerStyle, { marginHorizontal: isLargeScreen ? 80 : 16 }]}>
                        <AppTextInput
                            reference={emailRef}
                            placeHolder={'E-mail'}
                            defaultValue={email}
                            autoFocus={true}
                            keyboardType={'email-address'}
                            autoCapitalize={'none'}
                            returnKeyLabel={'next'}
                            returnKeyType={'next'}
                            textInputContainerStyle={{ marginTop: _scale(20) }}
                            leftIcon={<MaterialIcons name="email" size={24} color="white" />}
                            clearIcon={crossIcon == 'email' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('email')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 && focus == 'email' ? setCrossIcon('email') : setCrossIcon('')
                                setEmail(text)

                            }
                            }
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.lightGrey}
                            onSubmitEditing={() => resetCodeRef.current.focus()}
                            textInputStyle={{ outline: "none" }}
                            onFocus={() => {
                                setCrossIcon('email')
                                setFocus('email')
                                email.trim().length > 0 ? setCrossIcon('email') : setCrossIcon('')
                            }
                            }
                        />
                        <AppTextInput
                            reference={resetCodeRef}
                            placeHolder={'Reset code'}
                            defaultValue={resetCode}
                            keyboardType={'numeric'}
                            autoCapitalize={'none'}
                            returnKeyLabel={'next'}
                            returnKeyType={'next'}
                            textInputContainerStyle={{ marginTop: _scale(20) }}
                            leftIcon={<MaterialIcons name="vpn-key" size={24} color="white" />}
                            clearIcon={crossIcon == 'resetCode' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('resetCode')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 && focus == 'resetCode' ? setCrossIcon('resetCode') : setCrossIcon('')
                                setResetCode(text)

                            }
                            }
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.lightGrey}
                            onSubmitEditing={() => passwordRef.current.focus()}
                            textInputStyle={{ outline: "none" }}
                            onFocus={() => {
                                setCrossIcon('resetCode')
                                setFocus('resetCode')
                                resetCode.trim().length > 0 ? setCrossIcon('resetCode') : setCrossIcon('')
                            }
                            }
                        />
                        <AppTextInput
                            reference={passwordRef}
                            placeHolder={'Password'}
                            defaultValue={newPassword}
                            secureTextEntry={!isPassVisible}
                            textInputContainerStyle={{ marginTop: _scale(25) }}
                            leftIcon={<AntDesign name="lock1" size={24} color="white" />}
                            rightIcon={isPassVisible == true
                                ? <Ionicons name="md-eye-off" size={24} color="white" onPress={passVisible} />
                                : <Ionicons name="md-eye" size={24} color="white" onPress={passVisible} />}
                            clearIcon={crossIcon == 'password' && <Entypo name="circle-with-cross" size={24} color="white" style={{ paddingLeft: _scale(5) }} onPress={() => clearTextFilled('password')} />}
                            setValue={(text) => {
                                text.trim().length >= 1 && focus == 'password' ? setCrossIcon('password') : setCrossIcon('')
                                setNewPassword(text)

                            }
                            }
                            textInputDivider={{ marginTop: _scale(10) }}
                            placeholderTextColor={AppColor.lightGrey}
                            onSubmitEditing={() => checkValidation()}
                            textInputStyle={{ outline: "none" }}
                            onFocus={() => {
                                setCrossIcon('password')
                                setFocus('password')
                                newPassword.trim().length > 0 ? setCrossIcon('password') : setCrossIcon('')
                            }
                            }
                        />

                        <WebButton title="Reset Password" buttonStyle={styles.buttonStyle} onPress={checkValidation} />
                    </View>

                </ScrollView>

            </KeyboardAvoidingView>
            {isLoading && <AppLoader />}
            {bottomSafeArea()}
        </View>
    )
}