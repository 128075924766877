
const imageLoader = require('./imageLoader.gif')
const theway = require('./theway.png')
const yourjourney = require('./yourjourney.png')
const ring = require('./ring.png')
const empty = require('./Empty.png')
const appLogo = require('./appicon.png')
const appGif = require('./thewaygif.gif')


export const localImages = {
    imageLoader,
    theway,
    yourjourney,
    ring,
    empty,
    appLogo,
    appGif
}