import ExpansionModel from "../ExpansionModel";

export default class ExpansionResponseModel {
    expansionModel: ExpansionModel;

    constructor() {

    }

    initWithRes(res) {
        let expansionDataArray = res['expansion_video']
        this.expansionModel = []
        if (expansionDataArray.length > 0) {
            expansionDataArray.forEach(element => {
                this.expansionModel.push(new ExpansionModel().initWithData(element))
            });
        }
        return this;
    }
}