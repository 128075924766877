import { TextInput, View } from 'react-native'
import AppColor from '../../constant/colors'
import DefaultValues from '../../constant/defaultValues'
import { _scale } from '../../utils'
import { commonStyles } from '../../utils/commonStyles'

export default function AppTextInput(props) {
    return (
        <View style={[{ backgroundColor: AppColor.editTextBg, padding: _scale(10), borderRadius: _scale(5) }, { ...props.textInputContainerStyle }]}>
            <View style={[{ flexDirection: 'row', alignItems: 'center', }, { ...props.textSubContainer }]}>
                {props.leftIcon != undefined && props.leftIcon}
                <TextInput
                    ref={props.reference}
                    defaultValue={props.defaultValue}
                    placeholder={props.placeHolder}
                    autoComplete={props.autoComplete}
                    autoCorrect={props.autoCorrect}
                    autoFocus={props.autoFocus}
                    autoCapitalize={props.autoCapitalize}
                    keyboardType={props.keyboardType}
                    secureTextEntry={props.secureTextEntry}
                    placeholderTextColor={props.placeholderTextColor}
                    returnKeyLabel={props.returnKeyLabel}
                    returnKeyType={props.returnKeyType}
                    multiline={props.multiline}
                    style={[commonStyles.textInputStyle, { ...props.textInputStyle }]}
                    onChangeText={(text) => { props.setValue(text) }}
                    onSubmitEditing={props.onSubmitEditing}
                    blurOnSubmit={props.blurOnSubmit}
                    maxLength={props.maxLength}
                    editable={props.editable}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}


                />
                {props.rightIcon != undefined && props.rightIcon}
                {props.clearIcon != undefined && props.clearIcon}
            </View>
            {/* <View style={[commonStyles.textInputDivider, { ...props.textInputDivider }]} /> */}
        </View>
    )
}

AppTextInput.defaultProps = {
    placeHolder: DefaultValues.textInputValues.placeHolder,
    keyboardType: DefaultValues.textInputValues.keyboardType,
    placeholderTextColor: DefaultValues.textInputValues.placeholderTextColor,
    returnKeyType: DefaultValues.textInputValues.returnKeyType,
    returnKeyLabel: DefaultValues.textInputValues.returnKeyLabel,
    autoCapitalize: DefaultValues.textInputValues.autoCapitalize,
    blurOnSubmit: DefaultValues.textInputValues.blurOnSubmit
}