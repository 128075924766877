import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack'
import { useWindowDimensions, Image, StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import AppFonts from '../../constant/fonts';
import { _scale } from '../../utils';
import CustomDrawerContent from './customWebDrawer';
import Home from '../../screens/tabsScreen/home/index.web';
import YourJourney from '../../screens/tabsScreen/yourJourney/index.web';
import TheWay from '../../screens/tabsScreen/theWay/index.web';
import Expansion from '../../screens/tabsScreen/expansion/index.web';
import Source from '../../screens/tabsScreen/source/index.web';
import AppStrings from '../../constant/appStrings';
import AppColor from '../../constant/colors';
import WebTierDetails from '../../screens/tabsScreen/tierDetails/index.web';
import ExpansionDetail from '../../screens/tabsScreen/expansionDetail/index.web';
import ContactUs from '../../screens/extraScreen/contactUs/index.web';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Ionicons } from '@expo/vector-icons';
import { useEffect, useState } from 'react';
import constant from '../../constant';
import MyStorage from '../../services/storage';
import VideoDetailsView from '../../screens/tabsScreen/VideoDetailsView/index.web';
import MyAccountContainer from '../../containers/Auth/myaccountContainer';
import ChangePasswordContainer from '../../containers/Auth/changePasswordContainer';
import { StackActions } from '@react-navigation/native';

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();


function redirectScreens(navigation) {

    if (MyStorage.getItem(constant.appKey.shouldPopToRoot) != '1') {
        navigation.dispatch(StackActions.popToTop());
    } else {
        MyStorage.setItem(constant.appKey.shouldPopToRoot, '0')
    }
}


function HomeStack({ navigation }) {

    useEffect(() => {

        if (MyStorage.getItem(constant.appKey.kUserId) == "") {
            navigation.replace('AuthStack')
        }

        navigation.addListener('focus', () => {
            redirectScreens(navigation)
        });

    }, [])


    return (
        <Stack.Navigator initialRouteName='Home'>
            <Stack.Screen name={AppStrings.homeWebScreen} component={Home} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'Home',

            }} />

            <Stack.Screen name={'VideoDetailsView'} component={VideoDetailsView} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'Details',
            }} />

            <Stack.Screen name={AppStrings.myAccountContainer} component={MyAccountContainer} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'My Account',
            }} />
            <Stack.Screen name={AppStrings.changePasswordContainer} component={ChangePasswordContainer} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'Change Password',
            }} />
        </Stack.Navigator>
    )

}

function YourJourneyStack({ navigation }) {

    useEffect(() => {
        if (MyStorage.getItem(constant.appKey.kUserId) == "") {
            navigation.replace('AuthStack')
        }

        navigation.addListener('focus', () => {
            redirectScreens(navigation)
        });

    }, [])
    return (
        <Stack.Navigator>
            <Stack.Screen name={AppStrings.yourJourneyWebScreen} component={YourJourney} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'My-Way',
            }} />
            <Stack.Screen name={AppStrings.webTierDetails} component={WebTierDetails} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'My-Way-details',
            }} />
            <Stack.Screen name={'VideoDetailsView'} component={VideoDetailsView} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'Details',
            }} />
            <Stack.Screen name={AppStrings.myAccountContainer} component={MyAccountContainer} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'My Account',
            }} />
            <Stack.Screen name={AppStrings.changePasswordContainer} component={ChangePasswordContainer} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'Change Password',
            }} />

        </Stack.Navigator>
    )
}

function TheWayStack({ navigation }) {

    useEffect(() => {
        if (MyStorage.getItem(constant.appKey.kUserId) == "") {
            navigation.replace('AuthStack')
        }

        navigation.addListener('focus', () => {
            redirectScreens(navigation)
        });

    }, [])

    return (
        <Stack.Navigator>
            <Stack.Screen name={AppStrings.theWayWebScreen} component={TheWay} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'the-way',
            }} />
            <Stack.Screen name={AppStrings.webTierDetails} component={WebTierDetails} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'tier-details',
            }} />
            <Stack.Screen name={'VideoDetailsView'} component={VideoDetailsView} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'Details',
            }} />
            <Stack.Screen name={AppStrings.myAccountContainer} component={MyAccountContainer} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'My Account',
            }} />
            <Stack.Screen name={AppStrings.changePasswordContainer} component={ChangePasswordContainer} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'Change Password',
            }} />
        </Stack.Navigator>
    )
}
function ExpansionStack({ navigation }) {

    useEffect(() => {
        if (MyStorage.getItem(constant.appKey.kUserId) == "") {
            navigation.replace('AuthStack')
        }

        navigation.addListener('focus', () => {
            redirectScreens(navigation)
        });

    }, [])

    return (
        <Stack.Navigator>
            <Stack.Screen name={AppStrings.expansionWebScreen} component={Expansion} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'expansion',
            }} />
            <Stack.Screen name={AppStrings.expansionDetailWebScreen} component={ExpansionDetail} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'expansion-details',
            }} />
            <Stack.Screen name={AppStrings.myAccountContainer} component={MyAccountContainer} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'My Account',
            }} />
            <Stack.Screen name={AppStrings.changePasswordContainer} component={ChangePasswordContainer} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'Change Password',
            }} />
        </Stack.Navigator>
    )
}
function SourceStack({ navigation }) {

    useEffect(() => {
        if (MyStorage.getItem(constant.appKey.kUserId) == "") {
            navigation.replace('AuthStack')
        }

        navigation.addListener('focus', () => {
            redirectScreens(navigation)
        });

    }, [])

    return (
        <Stack.Navigator>
            <Stack.Screen name={AppStrings.sourceWebScreen} component={Source} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'source',
            }} />
            <Stack.Screen name={AppStrings.contactUsWebScreen} component={ContactUs} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'contact-us',
            }} />
            <Stack.Screen name={AppStrings.myAccountContainer} component={MyAccountContainer} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'My Account',
            }} />
            <Stack.Screen name={AppStrings.changePasswordContainer} component={ChangePasswordContainer} options={{
                headerShown: false,
                headerTitle: '',
                title: constant.AppInfo.name + ' | ' + 'Change Password',
            }} />
        </Stack.Navigator>
    )
}

export default function WebDrawerNavigator({ navigation }) {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;
    const [userName, setUserName] = useState('')
    const [userEmail, setEmail] = useState('')

    useEffect(() => {
        AsyncStorage.getItem(constant.appKey.kUserName).then(response => {
            // console.log("response", response);
            setUserName("Welcome " + response)
        })
        AsyncStorage.getItem(constant.appKey.kUserEmail).then(response => {
            // console.log("response", response);
            setEmail(response)
        })
    }, [])



    return (
        <Drawer.Navigator
            defaultStatus="open"
            drawerContent={props => <CustomDrawerContent {...props} />}
            initialRouteName={AppStrings.homeWebStack}
            screenOptions={{
                drawerType: 'permanent',
                drawerStyle: { width: isLargeScreen ? _scale(240) : _scale(70), backgroundColor: AppColor.lightBlack },
                overlayColor: 'transparent',
                drawerLabelStyle: { marginLeft: -_scale(20), fontFamily: AppFonts.robotoBold },
                drawerActiveBackgroundColor: AppColor.webBg,
                drawerActiveTintColor: AppColor.white,
                drawerInactiveTintColor: AppColor.grey,
                headerStyle: { backgroundColor: AppColor.webBg },
                drawerHideStatusBarOnOpen: false,
                headerTitleStyle: { color: AppColor.white },
                headerTintColor: AppColor.white
            }}

        >
            <Drawer.Screen name={AppStrings.homeWebStack} component={HomeStack} options={{
                drawerIcon: ({ color }) => (
                    <Image source={require('../../assets/images/side_1.png')} style={styles.menuIconStyle} />
                ),
                title: 'Home',
                headerShown: false
            }} />

            <Drawer.Screen name={AppStrings.yourJourneyWebStack} component={YourJourneyStack} options={{
                drawerIcon: ({ color }) => (
                    <Image source={require('../../assets/images/side_2.png')} style={styles.menuIconStyle} />
                ),
                title: constant.ScreensName.journey,
                headerShown: false
            }} />
            <Drawer.Screen name={AppStrings.theWayWebStack} component={TheWayStack} options={{
                drawerIcon: ({ color }) => (
                    <Image source={require('../../assets/images/side_3.png')} style={styles.menuIconStyle} />
                ),
                title: 'The Way',
                headerShown: false

            }} />
            <Drawer.Screen name={AppStrings.expansionWebStack} component={ExpansionStack} options={{
                drawerIcon: ({ color }) => (
                    <Image source={require('../../assets/images/side_4.png')} style={styles.menuIconStyle} />
                ),
                title: 'Expansion',
                headerShown: false
            }} />
            <Drawer.Screen name={AppStrings.sourceWebStack} component={SourceStack} options={{
                drawerIcon: ({ color }) => (
                    <Image source={require('../../assets/images/side_5.png')} style={styles.menuIconStyle} />
                ),
                title: 'Source',
                headerShown: false
            }} />
        </Drawer.Navigator>
    );
}

const styles = StyleSheet.create({
    menuIconStyle: {
        height: _scale(30),
        width: _scale(30)
    },
    headerRightContainer: {
        alignItems: 'center',
        marginLeft: _scale(10)
    },
    nameTextStyle: {
        color: AppColor.white,
        fontFamily: AppFonts.robototMedium,
        fontSize: _scale(18),
        textAlign: 'center',
        textDecorationLine: 'underline'
    }
})