import React from 'react'
import { View, useWindowDimensions } from 'react-native'
import ResetPassword from '../../../screens/authScreens/resetPassword/index.web'
import { gradientBackground } from '../../../utils'
import { commonStyles } from '../../../utils/commonStyles'

export default function SignUpContainer({ navigation, route }) {

    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;


    return (
        <View style={commonStyles.mainContainer} key={'MainContainer'}>
            {gradientBackground()}
            <View style={commonStyles.webSubContainerStyle} key={'SubContainerView'}>
                <View style={[commonStyles.webBorderViewStyle, { width: isLargeScreen ? '50%' : '90%' }]} key={'BorderView'}>
                    <View style={{ flex: 1 }}>
                        <ResetPassword props={navigation} email={route.params.q || ''} />
                    </View>
                </View>
            </View>
        </View>
    )
}





