import UserModel from "../UserModel";
import SubscriptionModel from "../SubscriptionModel";

export default class GetProfileModel {
    userModel: UserModel;
    subscriptionModel: SubscriptionModel;
    constructor() {

    }
    initWithRes(res) {
        this.userModel = new UserModel().initWithData(res['user'])
        let subscriptionDataArray = res['subscriptionData']
        if (subscriptionDataArray.length > 0) {
            let first = subscriptionDataArray[0]
            this.subscriptionModel = (new SubscriptionModel().initWithData(first))
        }
        return this;
    }
}